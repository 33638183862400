import { useCallback, useContext, useEffect, useState } from "react";
import {
  DialogTitleBar,
  useFormDialog,
  useMessageDialog,
  VerticalSpacer,
} from "@intouchhealth/mfe-library";
import { RootStoreContext } from "../../app/stores/rootStore";
import TemplateFormError, { useTemplateError } from "./TemplateFormError";
import { useDeleteDynamicTemplateMutation } from "./TemplateFormMutations";
import { TemplateFormStrings } from "./TemplateFormStrings";
import styled from "styled-components";
import {
  ServiceLineForDynamicTemplate,
  useGetServiceLinesQuery,
} from "./TemplateFormQueries";
import { v4 as uuidv4 } from "uuid";

export const useDeleteTemplateProvider = () => {
  const { commonStore } = useContext(RootStoreContext);
  const [confirmed, setConfirmed] = useState(false);
  const confirmDialog = useFormDialog(
    TemplateFormStrings.DeleteConfirmationTitle,
    TemplateFormStrings.DeleteButtonLabel,
    TemplateFormStrings.DeleteCancelButtonLabel,
    "delete",
  );
  const { ...messageDialog } = useMessageDialog();
  const { ...formError } = useTemplateError();

  const [templateId, setTemplateId] = useState<string>();
  const [templateName, setTemplateName] = useState<string>();
  const [onDeleted, setOnDeleted] = useState<Function | null>(null);
  const [serviceLines, setServiceLines] = useState<
    ServiceLineForDynamicTemplate[]
  >([]);
  const [dynamicTemplateIds, setDynamicTemplateIds] = useState<string[]>([]);
  const [
    getServiceLines,
    { loading: loadingServiceLines, data: serviceLineData },
  ] = useGetServiceLinesQuery({ dynamicTemplateIds });

  const [
    deleteDynamicTemplateMutation,
    { data, loading, error, called, reset },
  ] = useDeleteDynamicTemplateMutation();

  useEffect(() => {
    if (confirmed && !called) {
      // Make call delete the service line

      deleteDynamicTemplateMutation({
        variables: { input: { templateId } },
      });

      setConfirmed(false);
    }
  }, [confirmed, called, templateId, deleteDynamicTemplateMutation]);

  useEffect(() => {
    if (called && (!loading || error)) {
      if (formError.hasErrors(error, data?.updateServiceLine?.errors)) {
        reset();
      } else {
        reset();
        commonStore.history.push(`${commonStore.rootPath}?notify=deleted`);

        if (typeof onDeleted === "function") {
          onDeleted();
        }
      }

      messageDialog.setIsOpen(false);
      confirmDialog.setIsOpen(false);
    }
    // eslint-disable-next-line
  }, [
    data,
    loading,
    error,
    called,
    reset,
    commonStore.history,
    commonStore.rootPath,
    formError,
    onDeleted,
  ]);

  useEffect(() => {
    if (!loadingServiceLines && serviceLineData) {
      // @ts-ignore
      const queryData = serviceLineData?.serviceLinesByDynamicTemplateIds;

      if (queryData?.length === 0) {
        confirmDialog.setIsOpen(true);
        setServiceLines([]);
      } else {
        messageDialog.setIsOpen(true);
        setServiceLines(queryData);
      }
    }
    // eslint-disable-next-line
  }, [loadingServiceLines, serviceLineData]);

  const handleDelete = useCallback(
    (
      templateId: string,
      templateName?: string,
      onDeleted: Function | null = null,
    ) => {
      setTemplateId(templateId);
      setTemplateName(templateName);
      setOnDeleted(() => onDeleted);

      if (templateId) {
        setDynamicTemplateIds([]);
        setTimeout(() => {
          setDynamicTemplateIds([templateId]);
          getServiceLines();
        });
      }
    },
    // eslint-disable-next-line
    [],
  );

  const DeleteErrorForm: React.FC = () => {
    return (
      <TemplateFormError
        isErrorAlertOpen={formError.isErrorAlertOpen}
        setIsErrorAlertOpen={formError.setIsErrorAlertOpen}
        alertMessages={formError.alertMessages}
      ></TemplateFormError>
    );
  };

  const DeleteTemplateModalContents: React.FC = () => {
    const hasServiceLines = serviceLines.length > 0;
    return (
      <>
        {hasServiceLines && (
          <>
            <DialogTitleBar
              title={TemplateFormStrings.DeleteErrorTitle}
              iconType={"error"}
              largeFont={true}
              onCancelClick={() => messageDialog.setIsOpen(false)}
            />
            <p>{TemplateFormStrings.DeleteErrorDescription}</p>
          </>
        )}
        <VerticalSpacer />
        <Wrapper>
          {templateName && (
            <>
              <div>
                <TemplateNameHeader>
                  {TemplateFormStrings.DeleteErrorFormNameLabel}
                </TemplateNameHeader>
                <TemplateName>{templateName}</TemplateName>
              </div>
              <VerticalSpacer />
            </>
          )}
          <div>
            <ServiceLineListHeader>
              {TemplateFormStrings.DeleteErrorServiceLinesInUse}
            </ServiceLineListHeader>
            {hasServiceLines ? (
              <ServiceLineTable>
                {(serviceLines || []).map((serviceLine) => {
                  return (
                    <StylelessButton
                      key={uuidv4()}
                      onClick={() => {
                        window.open(
                          `${commonStore.hostPath}/service-line/update/${serviceLine.serviceLineId}`,
                          "_blank",
                        );
                      }}
                    >
                      <p>{serviceLine.displayName}</p>
                    </StylelessButton>
                  );
                })}
              </ServiceLineTable>
            ) : (
              <TemplateName>
                {TemplateFormStrings.DeleteNoServiceLinesLabel}
              </TemplateName>
            )}
          </div>
        </Wrapper>
        {!hasServiceLines && (
          <>
            <p>{TemplateFormStrings.DeleteConfirmationDescription}</p>
            <VerticalSpacer />
          </>
        )}
      </>
    );
  };

  const DeleteTemplateProvider: React.FC = () => {
    return (
      <>
        <confirmDialog.FormDialog
          position="appcontainer"
          top={"25%"}
          onAccepted={() => setConfirmed(true)}
          onCancelled={() => confirmDialog.setIsOpen(false)}
          acceptDisabled={false}
        >
          <DeleteTemplateModalContents />
        </confirmDialog.FormDialog>
        <messageDialog.ModalDialog
          position="appcontainer"
          top={"25%"}
          title={undefined}
          closeButtonText={TemplateFormStrings.DeleteCloseButtonLabel}
        >
          <DeleteTemplateModalContents />
        </messageDialog.ModalDialog>
        <DeleteErrorForm />
      </>
    );
  };

  return {
    DeleteTemplateProvider,
    handleDelete,
  };
};

const StylelessButton = styled.button`
  all: unset;

  :is(:hover, :focus) {
    color: #1986e2;
  }
`;

const Wrapper = styled.div`
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 16px;
  min-width: min(calc(100vw - 700px), 700px);

  & p {
    margin: 0;
  }
`;

const TemplateNameHeader = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #67605b;
`;

const TemplateName = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #302e2c;
`;

const ServiceLineListHeader = styled.p`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 14px;
  color: #67605b;
`;

const ServiceLineTable = styled.div`
  background: #ffffff;
  border: 2px solid #dddbd5;
  border-radius: 8px;
  display: grid;
  grid-template-columns: auto;
  grid-gap: 32px;
  gap: 32px;
  padding: 16px 19px;
  margin-top: 8px;

  & p {
    position: relative;
    cursor: pointer;
    text-decoration: underline;
  }

  & button:not(:last-child) > p:after {
    position: absolute;
    left: -19px;
    bottom: -16px;
    content: "";
    width: calc(100% + (19px * 2));
    height: 1px;
    background-color: #edeeed;
  }
`;
