import React, { useEffect, useState, useContext } from "react";
import {
  useConfirmDialog,
  useMessageDialog,
  uniqueName,
} from "@intouchhealth/mfe-library";
import {
  getElement,
  getPage,
  IElementDesign,
  pageHasBranching,
  translationChangedNotification,
} from "../../app/stores/templateDesignStore";
import { FlowNavigatorContext } from "./FlowNavigator";
import { FlowNavigatorStrings } from "./FlowNavigatorStrings";

export const useDeleteElementHandler = () => {
  const context = useContext(FlowNavigatorContext);
  const [refreshKey, setRefreshKey] = useState(uniqueName());
  const [deleteParms, setDeleteParms] = useState<{
    pageId: string;
    elementId: string;
  }>();
  const [deletingLastElement, setDeletingLastElement] = useState(false);
  const { ...confirmation } = useConfirmDialog();
  const { ...deleteNotAllowedDialog } = useMessageDialog();

  const DeleteElementDialog: React.VFC = () => {
    return (
      <div key={refreshKey}>
        <confirmation.ModalDialog
          type="warning"
          isDelete={true}
          position="appcontainer"
          top={"25%"}
          title={FlowNavigatorStrings.DeleteElementConfirmDialogTile}
          confirmButtonText={FlowNavigatorStrings.DeleteConfirmationButton}
          cancelButtonText={FlowNavigatorStrings.CancelConfirmationButton}
        >
          <div hidden={deletingLastElement}>
            {FlowNavigatorStrings.DeleteElementConfrimDialogDeleteMessage}
          </div>
          <div hidden={!deletingLastElement}>
            <div>
              {FlowNavigatorStrings.DeleteElementConfirmDialogResetMsgLine1}
            </div>
            <div>
              {FlowNavigatorStrings.DeleteElementConfirmDialogResetMsgLine2}
            </div>
          </div>
        </confirmation.ModalDialog>
        <deleteNotAllowedDialog.ModalDialog
          type="error"
          position="appcontainer"
          top={"25%"}
          title={FlowNavigatorStrings.DeleteElementNotAllowedTitle}
          closeButtonText={
            FlowNavigatorStrings.DeleteElementNotAllowedCloseButton
          }
        >
          <div style={{ maxWidth: "400px" }}>
            {FlowNavigatorStrings.DeleteElementNotAllowedMessage}
          </div>
        </deleteNotAllowedDialog.ModalDialog>
      </div>
    );
  };

  context.deleteElementHandler = (pageId: string, elementId: string) => {
    var page = getPage(context.section(), pageId);
    var element = getElement(page, elementId);
    if (page) {
      var noElementsExist = (page?.subpages?.length ?? 0) < 1 && !page?.answers;
      if (noElementsExist) {
        deleteNotAllowedDialog.setIsOpen(true);
      } else if (pageHasBranching(page)) {
        setDeletingLastElement(true);
        confirmation.setUserConfirmed(false);
        setDeleteParms({ pageId, elementId });
        confirmation.setIsOpen(true);
      } else if (!element) {
        setRefreshKey(uniqueName());
      } else {
        setDeletingLastElement((page?.subpages?.length ?? 0) < 2);
        confirmation.setUserConfirmed(false);
        setDeleteParms({ pageId, elementId });
        confirmation.setIsOpen(true);
      }
    }
  };

  useEffect(() => {
    if (confirmation.userConfirmed && deleteParms) {
      var page = getPage(context.section(), deleteParms?.pageId);
      if (page && pageHasBranching(page)) {
        page.answers = undefined;
        page.subpages = [] as IElementDesign[];
        setRefreshKey(uniqueName());
      } else {
        if (page && page.subpages?.length > 0) {
          page.subpages = page.subpages.filter(
            (e) => e.id !== deleteParms?.elementId,
          );
        }
        if (page && page.subpages?.length < 1) {
          setRefreshKey(uniqueName());
        }
      }
      confirmation.setUserConfirmed(false);
      setDeleteParms(undefined);
      translationChangedNotification.publish(null);
    }
    // eslint-disable-next-line
  }, [confirmation.userConfirmed, deleteParms]);

  return {
    DeleteElementDialog,
  };
};
