export const ChatIcon = () => {
  return (
    <div
      style={{
        marginLeft: "1px",
        marginRight: "-6px",
        width: "27px",
        height: "24px",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 15 14"
        fill="white"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M14.1599 1.66665C14.1599 0.92998 13.5699 0.333313 12.8333 0.333313H2.16659C1.42992 0.333313 0.833252 0.92998 0.833252 1.66665V9.66665C0.833252 10.4033 1.42992 11 2.16659 11H11.4999L14.1666 13.6666L14.1599 1.66665ZM11.4999 8.33331H3.49992V6.99998H11.4999V8.33331ZM11.4999 6.33331H3.49992V4.99998H11.4999V6.33331ZM11.4999 4.33331H3.49992V2.99998H11.4999V4.33331Z" />
      </svg>
    </div>
  );
};
