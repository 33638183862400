import {
  Key,
  SyntheticEvent,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  mui,
  Button,
  RadioGroup,
  PureInput,
  Dimmer,
  LoaderIcon,
} from "@intouchhealth/cig-components";
import {
  TabPageContainer,
  TabPagesContainer,
  TabsContainer,
  VerticalSpacer,
  useFormDialog,
  useOnClickOutside,
  isProperlyFormattedUrl,
  formatBytes,
} from "@intouchhealth/mfe-library";
import { ImageManagerDialogStrings } from "./ImageManagerDialogStrings";
import styled from "styled-components";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import {
  Delete as DeleteIcon,
  FileUpload,
  Image as ImageIcon,
  Check as CheckIcon,
  Info as InfoIcon,
  Dangerous,
} from "@mui/icons-material";
import { AddS3ImageProvider } from "./AddS3ImageProvider";
import { LinearProgress } from "@mui/material";
import reactStringReplace from "react-string-replace";
import { useDeleteImageMutation } from "./TemplateFormMutations";
import { RootStoreContext } from "../../app/stores/rootStore";
import { useS3ImageList } from "./S3ImageList";

const ACCEPTED_FILE_TYPES = ".png,.jpeg,.jpg,.tif,.tiff,.gif";

export interface IImageInfo {
  name?: string;
  key: string;
  index?: number;
  url: string;
  isUsedInTemplate?: boolean;
}

enum ImageUploadTabStatus {
  UPLOAD_FILE,
  UPLOADING,
  LOADING,
  SUCCESS,
  UPLOAD_FAILED,
  DELETE_FAILED,
}

enum ImageUrlTabStatus {
  SET_URL,
  TYPING_URL,
  INVALID_URL,
  NO_IMAGE,
  FETCHING,
  SUCCESS,
}

export const useImageManagerDialog = (
  sourceUrl: string,
  s3ImageData: IImageInfo | null,
) => {
  const { ...imageManagerDialog } = useFormDialog(
    ImageManagerDialogStrings.AddImageModalHeader,
    ImageManagerDialogStrings.AddImageButtonLabel,
    ImageManagerDialogStrings.CancelButtonLabel,
  );
  const { Tab, Tabs } = mui;
  const { commonStore } = useContext(RootStoreContext);

  // Selected image data to return
  const [selectedImageInfo, setSelectedImageInfo] = useState<IImageInfo | null>(
    null,
  );

  const ImageManagerDialog: React.FC = () => {
    // Fetches the Internal image list
    const {
      s3ImageList,
      loading: loadingInternalImages,
      refetch: refetchInternalS3ImageList,
    } = useS3ImageList(commonStore.practiceId);
    // Boolean indicating if we have the URL for the image provided stored on our server. This is used to set the tab start index
    const hasS3ImageData = !!s3ImageData && s3ImageData.name;
    // Defines the start tab index to use based on if we have the provided URL stored on the server or not.
    const startTabIndex = hasS3ImageData || !sourceUrl ? 0 : 2;
    // Picks the selected image index if we found the URL in the list
    const startImageIndex = s3ImageData?.index ?? null;

    const [tabIndex, setTabIndex] = useState(startTabIndex);
    const [isUpdated, setIsUpdated] = useState<boolean>(false);

    // Gallery Tab
    const useModalGalleryTab = (): [any, IImageInfo | null, boolean] => {
      const [selectedIndex, setSelectedIndex] = useState<number | null>(
        startImageIndex ?? null,
      );
      const [deleteImageMutation, { loading, error, called, reset }] =
        useDeleteImageMutation();

      // The controls for an image such as select or delete
      const GalleryControls = (props: any) => {
        const { index, key, isUsedInTemplate } = props.data;
        const { clickImage } = props;

        const [showDeleteWarning, setShowDeleteWarning] =
          useState<boolean>(false);

        const ref = useRef(null);
        const imageSelect = useRef<HTMLDivElement>(null);

        // Remove focus from radio button elements on each image.
        useEffect(() => {
          if (imageSelect?.current) {
            (
              imageSelect?.current?.querySelector(
                "[name=radio-buttons-group]",
              ) as HTMLElement
            ).tabIndex = -1;
          }
          // eslint-disable-next-line
        }, [imageSelect.current]);

        useEffect(() => {
          // Refetch the image list once the delete call has completed.
          if (!loading && called) {
            if (!error) {
              refetchInternalS3ImageList();
            }

            reset();
          }
          // eslint-disable-next-line
        }, [loading, called]);

        const onImageDelete = () => {
          const input = { key, practiceId: commonStore.practiceId };

          // Make call to delete the image
          deleteImageMutation({
            variables: { input },
          });
        };

        const deleteButtonPressed = (e: SyntheticEvent) => {
          e.stopPropagation();
          if (isUsedInTemplate) {
            setShowDeleteWarning(true);
          } else {
            onImageDelete();
          }
        };

        useOnClickOutside(ref, () => {
          setShowDeleteWarning(false);
        });

        return (
          <>
            {!showDeleteWarning && (
              <ImageControlOverlay
                isHidden={selectedIndex !== index}
                onClick={clickImage}
              >
                <ImageControlSelect
                  ref={imageSelect}
                  checked={selectedIndex === index}
                >
                  <RadioGroup
                    onChange={clickImage}
                    value={selectedIndex === index ? "selected" : ""}
                    content={[{ label: "", value: "selected" }]}
                    direction="horizontal"
                  />
                </ImageControlSelect>
                <ImageControlDelete className="trash-icon">
                  <DeleteIcon
                    onClick={(e: SyntheticEvent) => deleteButtonPressed(e)}
                  />
                </ImageControlDelete>
              </ImageControlOverlay>
            )}
            {showDeleteWarning && (
              <ImageControlDeleteWarning ref={ref}>
                <span style={{ color: "#CB220E" }}>
                  {ImageManagerDialogStrings.UsedInTemplateLabel}
                </span>
                <Button
                  size="xs"
                  themeName="whitelabel"
                  appearance="outline"
                  onClick={() => setShowDeleteWarning(false)}
                >
                  {ImageManagerDialogStrings.KeepImageLabel}
                </Button>
                <Button
                  size="xs"
                  themeName="danger"
                  appearance="subtle"
                  onClick={onImageDelete}
                >
                  {ImageManagerDialogStrings.DeleteImageLabel}
                </Button>
              </ImageControlDeleteWarning>
            )}
          </>
        );
      };

      // The gallery image component. Wrap around a button to gain accessability interaction.
      const GalleryImage = (props: any) => {
        const { clickImage, src } = props;

        return (
          <button className="gallery-image" onClick={clickImage}>
            <img src={src} alt="Gallery Item" />
          </button>
        );
      };

      const images = s3ImageList.map((data: IImageInfo, index: number) => {
        const { url } = data;

        const clickImage = () => {
          setSelectedIndex(selectedIndex === index ? null : index);
        };

        // Render each image with its controls
        return (
          <ImageContainer selected={selectedIndex === index} key={index}>
            <GalleryImage src={url} index={index} clickImage={clickImage} />
            <GalleryControls data={data} clickImage={clickImage} />
          </ImageContainer>
        );
      });

      const selectedImage =
        selectedIndex !== null ? s3ImageList[selectedIndex] || null : null;
      return [images, selectedImage, loading];
    };
    // End Gallery Tab

    // Upload Tab
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const [uploadedImageInfo, setUploadedImageInfo] =
      useState<IImageInfo | null>(null);
    const [uploadStatus, setUploadStatus] = useState<ImageUploadTabStatus>(
      ImageUploadTabStatus.UPLOAD_FILE,
    );
    const useModalUploadTab = () => {
      const [draggingFile, setDraggingFile] = useState<boolean>(false);
      const [deleteImageMutation, { data, loading, error, called, reset }] =
        useDeleteImageMutation();
      const fileUploadRef = useRef<HTMLInputElement>(null);

      const UploadContainer = () => {
        let dragCount = 0; // Naturally increments each frame the image is dragged over the dropzone, decrements each frame it is dragged outside of drop zone. Resets to 0 when image is dropped.
        let fileName = uploadedFile?.name || "";

        // Compute fields for the uploaded file name
        const lastPeriodLocation = fileName.includes(".")
          ? fileName.lastIndexOf(".")
          : fileName.length;
        const fileExt = fileName.includes(".")
          ? fileName.substring(lastPeriodLocation + 1).toUpperCase()
          : "";
        fileName = fileName.substring(0, lastPeriodLocation);
        const fileSize = formatBytes(uploadedFile?.size || 0);

        // Handle responses from the delete calls
        useEffect(() => {
          if (!loading && called) {
            if (error) {
              setUploadStatus(ImageUploadTabStatus.DELETE_FAILED);
            } else {
              setUploadStatus(ImageUploadTabStatus.UPLOAD_FILE);
              refetchInternalS3ImageList();
            }
            reset();
          }
          // eslint-disable-next-line
        }, [data, called]);

        const openFileBrowser = (e: SyntheticEvent) => {
          e.preventDefault();

          if (fileUploadRef.current) {
            fileUploadRef.current.click();
          }
        };

        const onFileUpload = (e: any) => {
          const {
            target: {
              validity,
              files: [file],
            },
          } = e;

          if (validity.valid) {
            setUploadedFile(file);
          }
        };

        // Drag and drop work around to prevent the drag event from firing more than once.
        const onFileDragStart = (e: any) => {
          e.stopPropagation();

          if (uploadStatus === ImageUploadTabStatus.UPLOAD_FILE) {
            if (dragCount === 0) {
              setDraggingFile(true);
            }

            dragCount++;
          }
        };

        // Drag and drop work around to prevent the drag event from firing more than once.
        const onFileDragStop = (e: any) => {
          e.stopPropagation();

          if (uploadStatus === ImageUploadTabStatus.UPLOAD_FILE) {
            dragCount--;
            if (dragCount === -0) {
              setDraggingFile(false);
            }
          }
        };

        const onFileDrop = (e: any) => {
          e.preventDefault();

          dragCount = 0;
          setDraggingFile(false);

          if (
            uploadStatus === ImageUploadTabStatus.UPLOAD_FILE &&
            e.dataTransfer?.files?.length > 0
          ) {
            setUploadedFile(e.dataTransfer.files[0]);
          }
        };

        const deleteUploadedImage = () => {
          setUploadStatus(ImageUploadTabStatus.LOADING);

          const input = {
            key: uploadedImageInfo?.key,
            practiceId: commonStore.practiceId,
          };

          // Make call to delete the image
          deleteImageMutation({
            variables: { input },
          });
        };

        const FileName = () => <p>{fileName}</p>;
        const FileInformation = () => (
          <FileInformationStyles>
            {fileExt}
            {fileExt ? " - " : ""}
            {fileSize}
          </FileInformationStyles>
        );

        const dragDropLabel = ImageManagerDialogStrings.DragAndDropLabel;
        const dragDropLinkLabel = dragDropLabel.substring(
          dragDropLabel.indexOf("%"),
          dragDropLabel.lastIndexOf("%") + 1,
        );

        return (
          <>
            <ImageUploadContainer
              draggingFile={draggingFile}
              showOutline={uploadStatus === ImageUploadTabStatus.UPLOAD_FILE}
              onDrop={onFileDrop}
              onDragOver={(e) => e.preventDefault()}
              onDragEnter={onFileDragStart}
              onDragLeave={onFileDragStop}
            >
              <ImageUploadText>
                {uploadStatus === ImageUploadTabStatus.UPLOAD_FILE && (
                  <>
                    <FileUpload fontSize="large" />
                    <p>
                      {reactStringReplace(
                        dragDropLabel,
                        dragDropLinkLabel,
                        (_: any, i: Key | null | undefined) => (
                          <a href="/#" key={i} onClick={openFileBrowser}>
                            {dragDropLinkLabel.replace(/%/g, "")}
                          </a>
                        ),
                      )}
                    </p>
                    <p style={{ opacity: "0.8", fontSize: "0.8rem" }}>
                      {ImageManagerDialogStrings.SupportedFileTypesLabel}
                    </p>
                  </>
                )}
                {uploadStatus === ImageUploadTabStatus.UPLOADING && (
                  <>
                    <ImageIcon
                      fontSize="large"
                      style={{ transform: "scale(150%)" }}
                    />
                    <FileName />
                    <FileInformation />
                    <div style={{ width: "400px" }}>
                      <LinearProgress />
                    </div>
                  </>
                )}
                {uploadStatus === ImageUploadTabStatus.LOADING && (
                  <>
                    <div style={{ width: "400px" }}>
                      <LinearProgress />
                    </div>
                  </>
                )}
                {uploadStatus === ImageUploadTabStatus.SUCCESS && (
                  <>
                    <img
                      src={uploadedImageInfo?.url}
                      className="image-preview"
                      alt="Preview"
                    />
                    <FileName />
                    <FileInformation />
                    <span style={{ color: "#2e7d32" }}>
                      <CheckIcon className="result-icon" color="success" />
                      {ImageManagerDialogStrings.UploadedLabel}
                    </span>
                    <VerticalSpacer />
                    <Button
                      size="xs"
                      themeName="whitelabel"
                      appearance="outline"
                      onClick={deleteUploadedImage}
                    >
                      {ImageManagerDialogStrings.DeleteImageLabel}
                    </Button>
                  </>
                )}
                {uploadStatus === ImageUploadTabStatus.UPLOAD_FAILED && (
                  <>
                    <ImageIcon
                      fontSize="large"
                      style={{ transform: "scale(150%)" }}
                    />
                    <FileName />
                    <FileInformation />
                    <ErrorColor>
                      <Dangerous className="result-icon" />
                      {ImageManagerDialogStrings.UploadFailedLabel}
                    </ErrorColor>
                    <VerticalSpacer />
                    <Button
                      size="xs"
                      themeName="whitelabel"
                      appearance="outline"
                      onClick={() =>
                        setUploadStatus(ImageUploadTabStatus.UPLOAD_FILE)
                      }
                    >
                      {ImageManagerDialogStrings.ReplaceImageLabel}
                    </Button>
                  </>
                )}
                {uploadStatus === ImageUploadTabStatus.DELETE_FAILED && (
                  <>
                    <ErrorColor>
                      <Dangerous className="result-icon" />
                      {ImageManagerDialogStrings.DeleteFailedLabel}
                    </ErrorColor>
                    <VerticalSpacer />
                    <Button
                      size="xs"
                      themeName="whitelabel"
                      appearance="outline"
                      onClick={() =>
                        setUploadStatus(ImageUploadTabStatus.SUCCESS)
                      }
                    >
                      {ImageManagerDialogStrings.DeleteFailedButtonLabel}
                    </Button>
                  </>
                )}
              </ImageUploadText>
            </ImageUploadContainer>
            <HiddenFileInput>
              <input
                type="file"
                ref={fileUploadRef}
                onChange={onFileUpload}
                accept={ACCEPTED_FILE_TYPES}
              />
            </HiddenFileInput>
          </>
        );
      };

      return UploadContainer;
    };
    // End Upload Tab

    // URL Tab
    const [urlStatus, setUrlStatus] = useState<ImageUrlTabStatus>(
      ImageUrlTabStatus.SET_URL,
    );
    const [imageUrl, setImageUrl] = useState<string | undefined>(
      !hasS3ImageData ? sourceUrl : "",
    );
    const useModalUrlTab = () => {
      // Reference to the loading timeout so we can clear it each time a new character is inserted into the text field.
      const [timeoutRef, setTimeoutRef] = useState<any>(null);
      // Determine if this tab started with a URL prefilled. This is used to skip the loading state if it is.
      const [initialFetch, setInitialFetch] = useState<boolean>(false);

      const UrlContainer = () => {
        const textRef = useRef<any>(null);

        // This method gets fetches and updates the provided URL. Since it is called inside a timeout function, a set state workaround had to be used to obtain the current value of imageUrl.
        const fetchUrlResult = () => {
          setImageUrl((imageUrl: string | undefined) => {
            setTimeoutRef(null);

            if (!imageUrl) {
              return;
            }

            setUrlStatus(ImageUrlTabStatus.FETCHING);
            return imageUrl;
          });
        };

        // This method is called everytime the URL text box is updated.
        // There is a timeout that gets called every time the text box is updated to give the user time to stop typing the URL before it attempts to update.
        const updateTextInput = (value: string) => {
          if (!initialFetch) {
            setInitialFetch(true);
          }

          if (timeoutRef !== null) {
            clearTimeout(timeoutRef);
          }

          setImageUrl(value);

          setUrlStatus(ImageUrlTabStatus.SET_URL);
          // URL Validation
          if (!value) {
            return;
          } else if (!isProperlyFormattedUrl(value, true)) {
            setUrlStatus(ImageUrlTabStatus.INVALID_URL);
            return;
          } else if (
            !ACCEPTED_FILE_TYPES.split(",").some((type) =>
              value.toLowerCase().endsWith(type),
            )
          ) {
            setUrlStatus(ImageUrlTabStatus.NO_IMAGE);
            return;
          } else if (urlStatus !== ImageUrlTabStatus.TYPING_URL) {
            setUrlStatus(ImageUrlTabStatus.TYPING_URL);
          }

          setTimeoutRef(
            setTimeout(() => {
              fetchUrlResult();
            }, 1500),
          );
        };

        // Mini Component for the URL text input
        const UrlInput = () => (
          <PureInput
            label={ImageManagerDialogStrings.UrlUploadLabel}
            value={imageUrl || ""}
            className="sls-input"
            onChange={(value) => updateTextInput(value)}
            invalid={false}
            key={"url-input"}
            inputRef={textRef}
          />
        );

        // Mini Component for the preview of the URL image
        const UrlImagePreview = (props: any) => (
          <img
            src={imageUrl || ""}
            className="image-preview"
            style={{ display: props.hidden ? "none" : "block" }}
            alt="Preview"
            onLoad={() => {
              if (urlStatus !== ImageUrlTabStatus.SUCCESS) {
                setUrlStatus(ImageUrlTabStatus.SUCCESS);
              }
            }}
            onError={() => setUrlStatus(ImageUrlTabStatus.INVALID_URL)}
          />
        );

        // Method to load the image automatically if this tab starts with an image URL
        useEffect(() => {
          if (imageUrl && !initialFetch) {
            fetchUrlResult();
            setInitialFetch(true);
          }
          // eslint-disable-next-line
        }, []);

        // Automatically focus the URL text input when switching to this tab
        useEffect(() => {
          if (textRef?.current) {
            textRef.current.focus();
          }
        }, [textRef]);

        // Switch case to render different views depending on the state of the upload
        return (
          <>
            <ImageUrlContainer>
              <ImageUrlText>
                <UrlInput />
                {urlStatus === ImageUrlTabStatus.SET_URL && (
                  <>
                    <p>{ImageManagerDialogStrings.UrlUploadHelpTextLine1}</p>
                    <p>{ImageManagerDialogStrings.UrlUploadHelpTextLine2}</p>
                  </>
                )}
                {(urlStatus === ImageUrlTabStatus.TYPING_URL ||
                  urlStatus === ImageUrlTabStatus.FETCHING) && (
                  <>
                    <ImageIcon
                      fontSize="large"
                      style={{ transform: "scale(150%)" }}
                    />
                    <VerticalSpacer />
                    <div style={{ width: "400px" }}>
                      <LinearProgress />
                    </div>
                    <VerticalSpacer />
                    <Button
                      size="xs"
                      themeName="whitelabel"
                      appearance="outline"
                      onClick={() => updateTextInput("")}
                    >
                      {ImageManagerDialogStrings.CancelButtonLabel}
                    </Button>
                    {urlStatus === ImageUrlTabStatus.FETCHING && (
                      <UrlImagePreview hidden />
                    )}
                  </>
                )}
                {urlStatus === ImageUrlTabStatus.SUCCESS && (
                  <>
                    <UrlImagePreview />
                    <VerticalSpacer />
                    <span style={{ color: "#2e7d32" }}>
                      <CheckIcon className="result-icon" color="success" />
                      {ImageManagerDialogStrings.UrlUploadedLabel}
                    </span>
                  </>
                )}
                {urlStatus === ImageUrlTabStatus.INVALID_URL && (
                  <>
                    <ImageIcon
                      fontSize="large"
                      style={{ transform: "scale(150%)" }}
                    />
                    <ErrorColor>
                      <Dangerous className="result-icon" />
                      {ImageManagerDialogStrings.UrlInvalidLabel}
                    </ErrorColor>
                  </>
                )}
                {urlStatus === ImageUrlTabStatus.NO_IMAGE && (
                  <>
                    <ImageIcon
                      fontSize="large"
                      style={{ transform: "scale(150%)" }}
                    />
                    <ErrorColor>
                      <Dangerous className="result-icon" />
                      {ImageManagerDialogStrings.UrlNoImageLabel}
                    </ErrorColor>
                  </>
                )}
              </ImageUrlText>
            </ImageUrlContainer>
            {urlStatus === ImageUrlTabStatus.SET_URL && (
              <ImageUrlInfoText>
                <InfoIcon />
                <p>{ImageManagerDialogStrings.UrlUploadInfoTextLabel}</p>
              </ImageUrlInfoText>
            )}
          </>
        );
      };

      return UrlContainer;
    };
    // End URL Tab

    // The collection of tabs
    const [images, selectedImage, galleryLoading] = useModalGalleryTab();
    const UploadContainer = useModalUploadTab();
    const UrlContainer = useModalUrlTab();

    const acceptDisabled = (): boolean => {
      switch (tabIndex) {
        case 0:
          return selectedImage === null;
        case 1:
          return uploadStatus !== ImageUploadTabStatus.SUCCESS;
        case 2:
          return urlStatus !== ImageUrlTabStatus.SUCCESS;
      }

      return true;
    };

    const handleTabChange = (_: any, newValue: number) => {
      setTabIndex(newValue);
    };

    const handleAccepted = () => {
      let data: IImageInfo | null = null;

      switch (tabIndex) {
        case 0:
          data = {
            name: selectedImage?.name || "",
            key: selectedImage?.key || "",
            url: selectedImage?.url || "",
          };
          break;
        case 1:
          data = {
            name: uploadedFile?.name || "",
            key: uploadedImageInfo?.key || "",
            url: uploadedImageInfo?.url || "",
          };
          break;
        case 2:
          data = {
            name: "",
            key: "",
            url: imageUrl || "",
          };
          break;
      }

      setSelectedImageInfo({ ...data, isUsedInTemplate: true } as IImageInfo);
      imageManagerDialog.setIsOpen(false);
    };

    const handleCancelled = () => {
      imageManagerDialog.setIsOpen(false);
    };

    useEffect(() => {
      if (uploadedFile) {
        setIsUpdated(true);
        setUploadStatus(ImageUploadTabStatus.UPLOADING);
      }
    }, [uploadedFile]);

    function a11yProps(index: number) {
      return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
      };
    }

    const LoadingComponent = () => (
      <Dimmer
        header="Loading..."
        icon={<LoaderIcon iconSize="xxl" sizing="relative" />}
      />
    );

    return (
      <imageManagerDialog.FormDialog
        position="appcontainer"
        top={40}
        width={"75%"}
        onAccepted={handleAccepted}
        onCancelled={handleCancelled}
        acceptDisabled={acceptDisabled()}
      >
        <ModalDialogStyle>
          <Container>
            <TabsContainer>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab
                  label={ImageManagerDialogStrings.GalleryTabLabel}
                  {...a11yProps(0)}
                />
                <Tab
                  label={ImageManagerDialogStrings.UploadTabLabel}
                  {...a11yProps(1)}
                />
                <Tab
                  label={ImageManagerDialogStrings.UrlTabLabel}
                  {...a11yProps(2)}
                />
              </Tabs>
            </TabsContainer>
            <TabPagesContainer>
              <TabPageContainer isHidden={tabIndex !== 0}>
                {loadingInternalImages || galleryLoading ? (
                  <LoadingComponent />
                ) : (
                  <SimpleBar
                    autoHide={false}
                    style={{
                      width: "100%",
                      height: "min(450px,38vh)",
                      padding: "0",
                    }}
                  >
                    <ImageGalleyContainer>{images}</ImageGalleyContainer>
                  </SimpleBar>
                )}
              </TabPageContainer>
              <UploadTabWrapper>
                <TabPageContainer isHidden={tabIndex !== 1}>
                  <UploadContainer />
                </TabPageContainer>
              </UploadTabWrapper>
              <TabPageContainer isHidden={tabIndex !== 2}>
                <UrlContainer />
              </TabPageContainer>
            </TabPagesContainer>
          </Container>
        </ModalDialogStyle>
        <AddS3ImageProvider
          file={uploadedFile as File}
          updated={isUpdated}
          setUpdated={setIsUpdated}
          onAdded={({ key, url }) => {
            setUploadStatus(ImageUploadTabStatus.SUCCESS);
            setUploadedImageInfo({ key, url });
            refetchInternalS3ImageList();
          }}
          onFailed={() => {
            setUploadStatus(ImageUploadTabStatus.UPLOAD_FAILED);
          }}
        />
      </imageManagerDialog.FormDialog>
    );
  };

  return {
    ImageManagerDialog,
    setIsOpen: imageManagerDialog.setIsOpen,
    isOpen: imageManagerDialog.isOpen,
    selectedImageInfo,
  };
};

const ErrorColor = styled.span`
  color: #cb220e;
`;

const HiddenFileInput = styled.div`
  display: none !important;
`;

const FileInformationStyles = styled.div`
  opacity: 0.8;
  fontsize: 0.8rem;
`;

const ImageContainerCommon = `
  height: min(400px,34vh);
  padding: 15px;
  display: grid;
`;

const UploadTabWrapper = styled.div`
  border-radius: 10px;
  overflow: hidden;
`;

const ImageUploadContainer = styled.div<{
  draggingFile: boolean;
  showOutline: boolean;
}>`
  outline: ${({
    draggingFile,
    showOutline,
  }: {
    draggingFile: boolean;
    showOutline: boolean;
  }) =>
    showOutline ? `1px dashed ${draggingFile ? "#1976d2" : "black"}` : "none"};
  outline-offset: -1px;
  border-radius: 10px;
  place-content: center;
  align-items: center;
  ${ImageContainerCommon}
`;

const ImageUrlContainer = styled.div`
  ${ImageContainerCommon}
  align-items: start;
`;

const ImageUrlInfoText = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.7rem;
  bottom: 20px;
  @media (min-height: 800px) {
    bottom: 80px;
  }

  & p,
  & svg {
    display: inline;
  }

  & svg {
    transform: scale(70%) translateY(8px);
  }
`;

const ImageTextCommon = `
  display: grid;
  place-items: center;
  & .result-icon {
    transform: translateY(6px);
  }
`;

const ImageUploadText = styled.div`
  ${ImageTextCommon}
`;

const ImageUrlText = styled.div`
  ${ImageTextCommon}
  & p {
    margin: 0;
    line-height: 40px;
  }
  & .sls-input {
    margin-bottom: 40px;
  }
`;

const ModalDialogStyle = styled.div`
  padding: 7px;
  margin-right: 13px;
  min-height: fit-content;
`;

const Container = styled.div`
  width: 100%;
  min-height: min(600px, 55vh);

  & > div {
    position: relative;
  }

  & > div > div {
    margin-left: 0;
  }

  & img {
    vertical-align: middle;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  & img.image-preview {
    width: auto;
    height: min(250px, 16vh);
    max-width: 400px;
    max-height: 250px;
  }
`;

const ImageGalleyContainer = styled.div`
  width: 100%;
  height: auto;
  display: grid;
  gap: 20px;
  padding: 10px 0 10px 0;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  place-items: center;
`;

const ImageContainer = styled.div<{ selected: boolean }>`
  position: relative;
  user-select: none;
  border: 3px solid
    ${({ selected }: { selected: boolean }) =>
      selected ? "#1976d2" : "transparent"};

  & .gallery-image {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    width: 250px;
    height: 170px;
  }

  & .gallery-image:focus {
    outline: #1976d2 5px auto;
  }
`;

const ImageControlOverlay = styled.div<{ isHidden: boolean }>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: ${({ isHidden }: { isHidden: boolean }) => (isHidden ? "0" : "1")};
  cursor: pointer;

  & .trash-icon {
    display: none;
  }

  &:hover {
    opacity: 1;
    background: rgba(255, 255, 255, 0.5);

    & .trash-icon {
      display: block;
    }
  }
`;

interface ImageControlSelectProps {
  checked: boolean;
}

const ImageControlSelect = styled.div<ImageControlSelectProps>`
  .MuiRadio-colorDefault {
    span {
      background-color: ${({ checked }: { checked: boolean }) =>
        checked ? "#1976d2" : "white"};
    }
  }
  position: absolute;
  top: 2px;
  left: 10px;
`;

const ImageControlDelete = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

const ImageControlDeleteWarning = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  text-align: center;
  backdrop-filter: blur(5px);

  display: grid;
  grid-template-columns: auto;
  align-items: center;
`;
