export const ImageIcon = () => {
  return (
    <div
      style={{
        marginLeft: "1px",
        marginRight: "-7px",
        width: "28px",
        height: "24px",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 21 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M3.01371 16.803H17.2778C19.1533 16.803 20.1458 15.8022 20.1458 13.9519V3.74165C20.1458 1.89136 19.1533 0.898926 17.2778 0.898926H3.01371C1.14659 0.898926 0.145752 1.89136 0.145752 3.74165V13.9519C0.145752 15.8106 1.14659 16.803 3.01371 16.803ZM2.07174 3.95191C2.07174 3.18656 2.45862 2.82491 3.19033 2.82491H17.1012C17.8245 2.82491 18.2198 3.18656 18.2198 3.95191V12.4633L14.2921 8.78791C13.922 8.45149 13.4847 8.28328 13.0389 8.28328C12.5764 8.28328 12.1643 8.43467 11.7774 8.7795L7.98427 12.1689L6.43675 10.7728C6.08351 10.4616 5.69663 10.2934 5.30134 10.2934C4.90605 10.2934 4.56964 10.4532 4.22481 10.7644L2.07174 12.6735V3.95191ZM6.89933 9.06545C8.01791 9.06545 8.94306 8.14872 8.94306 7.01331C8.94306 5.89472 8.01791 4.96116 6.89933 4.96116C5.77233 4.96116 4.84718 5.89472 4.84718 7.01331C4.84718 8.14872 5.77233 9.06545 6.89933 9.06545Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
