import { useEffect, useState } from "react";
import {
  FixedSizeContainer,
  RequiredInputIndicator,
  SelectInput,
} from "@intouchhealth/mfe-library";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { TranslationOptions } from "../../app/stores/translationOptionsStore";
import styled from "styled-components";
import { Translate as TranslateIcon } from "@mui/icons-material";

interface ILanguageOption {
  name: string;
  languageCode: string;
}

export const useDefaultLanguageInput = (
  initialLanguageCode: string,
  disabled: boolean = false,
) => {
  const [selectedLanguage, setSelectedLanguage] =
    useState<ILanguageOption | null>(null);

  const [languageList, setLanguageList] = useState<ILanguageOption[]>([]);

  useEffect(() => {
    const languageList = TranslationOptions.filter(
      (translation) => translation.status !== "ACTIVE",
    ).map((translation) => {
      return {
        name: translation.languageName,
        languageCode: translation.languageCode,
      } as ILanguageOption;
    });

    setLanguageList(languageList);

    const initialLanguageOption = languageList.find(
      (translation) => translation.languageCode === initialLanguageCode,
    );
    setSelectedLanguage(initialLanguageOption || null);
    // eslint-disable-next-line
  }, []);

  const DefaultLanguageInput = () => {
    return (
      <>
        <FixedSizeContainer width="calc(50% - 45px)">
          <GridWrapper>
            <RequiredInputIndicator hidden={disabled}>
              <SelectInput
                accessor="name"
                selectedValue={selectedLanguage}
                setSelectedValue={(language) =>
                  setSelectedLanguage(language as ILanguageOption)
                }
                optionList={languageList}
                label={TemplateFormStrings.DefaultLanguageLabel}
                helpText={TemplateFormStrings.DefaultLanguageHelpText}
                disabled={disabled}
              />
            </RequiredInputIndicator>
            <HelpText>
              <div>
                <TranslateIcon />
                <p>{TemplateFormStrings.DefaultLanguageHelpText2}</p>
              </div>
            </HelpText>
          </GridWrapper>
        </FixedSizeContainer>
      </>
    );
  };

  return {
    DefaultLanguageInput,
    selectedLanguage,
  };
};

const GridWrapper = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 50% min(275px, 50%);
`;

const HelpText = styled.div`
  display: grid;
  place-items: center;
  justify-content: center;
  & > div {
    display: flex;
    flex-direction: row;
  }
  & > * > svg {
    transform: scale(65%) translateY(10px);
    fill: #ada8a4;
  }
  & > * > p {
    color: #ada8a4;
    font-size: 0.8rem;
    font-family: "Medium", sans-serif !important;
  }
`;
