import {
  createContext,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  VFC,
} from "react";
import styled from "styled-components";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { FlowNavSelectionTypes } from "../flownavigator/FlowNavigator";
import {
  IPageDesign,
  ISectionDesign,
} from "../../app/stores/templateDesignStore";
import { PageWorkspaceContent } from "./FlowPageWorkspace";

export interface FlowWorkspaceProps {
  selectedWorkspace: FlowNavSelectionTypes;
  section: ISectionDesign;
  page: IPageDesign;
}

export const FlowWorkspaceContext = createContext({
  setScrollElementId: (_: string | undefined) => {},
  setWorkspaceScrollY: (_: number) => {},
  getWorkspaceScrollRef: (): HTMLDivElement | null => {
    return null;
  },
  focusElementId: undefined as string | undefined,
  scrollElementId: undefined as string | undefined,
});

export const useFlowWorkspace = (scrollY: MutableRefObject<number>) => {
  const context = useContext(FlowWorkspaceContext);
  const [selectedWorkspace, setSelectedWorkspace] =
    useState<FlowNavSelectionTypes>("none");
  const [page, setPage] = useState<IPageDesign>({} as IPageDesign);

  const containerRef = useRef<HTMLDivElement>(null);

  context.setScrollElementId = (id: string | undefined) => {
    context.focusElementId = id;
    context.scrollElementId = id;
  };

  context.setWorkspaceScrollY = (yPosition: any) => {
    if (containerRef.current) {
      containerRef.current.scrollTop = yPosition;
    }
  };

  context.getWorkspaceScrollRef = () => {
    return containerRef.current;
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollY.current;
    }
  });

  const onScroll = useCallback(() => {
    if (containerRef.current) {
      scrollY.current = containerRef.current.scrollTop;
      context.scrollElementId = undefined;
    }
  }, [context, scrollY]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.addEventListener("scroll", onScroll);
    }

    return () => {
      if (containerRef.current) {
        // eslint-disable-next-line
        containerRef.current.removeEventListener("scroll", onScroll);
      }
    };

    // eslint-disable-next-line
  }, [containerRef.current]);

  const WorkspacePanel: VFC = () => {
    return (
      <StyledContainer>
        <ScrollArea>
          <SimpleBar
            autoHide={false}
            style={{ height: "100%" }}
            scrollableNodeProps={{ ref: containerRef }}
          >
            <PageWorkspaceContent
              active={selectedWorkspace === "page"}
              page={page}
            />
          </SimpleBar>
        </ScrollArea>
      </StyledContainer>
    );
  };

  return { WorkspacePanel, setSelectedWorkspace, setPage };
};

export const FlowWorkspaceHeaderContainer = styled.div`
  min-height: 30px;
  max-height: 30px;
  font-size: large;
  font-weight: bold;
  color: #717171;
  width: 100%;
  margin-bottom: 10px;
`;

export const FlowWorkspaceContentContainer = styled.div`
  min-width: 300px;
  margin-top: 20px;
  margin-left: 15px;
  margin-right: 15px;
`;

const StyledContainer = styled.div`
  min-width: 575px;
  margin-right: -15px;
  width: 100%;
  height: calc(100vh - 130px);
`;

const ScrollArea = styled.div`
  width: 100%;
  height: 100%;
  .simplebar-track {
    opacity: 0.3;
  }
`;
