export const OpenEndedFlowElementStrings = {
  InputTypeNameStandard: "Free text",
  InputTypeNameEMail: "Email",
  InputTypeNamePhone: "Phone number",
  InputTypeNameZipCode: "ZIP code",
  InputTypeNameNumber: "Number",
  InputTypeNamePassword: "Masked Input",
  InputTypeLabel: "Answer type",
  InputTypeHelpText: "Only this type of answer will be allowed",
};
