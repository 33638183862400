import { useEffect, useState, useContext, VFC } from "react";
import { FlowWorkspaceStrings } from "./FlowWorkspaceStrings";
import { AddNewIcon, Button } from "@intouchhealth/cig-components";
import {
  uniqueName,
  useMessageDialog,
  useConfirmDialog,
} from "@intouchhealth/mfe-library";
import styled from "styled-components";
import {
  IElementDesign,
  insertPage,
  IPageDesign,
  newComplexPage,
  newTemplatePage,
  pageHasBranching,
  pageIsComplex,
} from "../../app/stores/templateDesignStore";
import { v4 as uuidv4 } from "uuid";
import { TemplateItemTypes } from "../../app/stores/TemplateItemTypes";
import { PharmacyComplexPageElement } from "../complexpageelements/pharmacy/PharmacyComplexPageElement";
import { HtmlFlowElement } from "../flowelements/html/HtmlFlowElement";
import { OpenEndedFlowElement } from "../flowelements/openended/OpenEndedFlowElement";
import { OpenEndedMultiLineElement } from "../flowelements/openendedmulti/OpenEndedMultiLineElement";
import { BranchingElement } from "../flowelements/branching/BranchingElement";
import { DatePickerFlowElement } from "../flowelements/datepicker/DatePickerFlowElement";
import { MultiSelectElement } from "../flowelements/multiselect/MultiSelectElement";
import { SingleSelectElement } from "../flowelements/singleselect/SingleSelectElement";
import { ScaleFlowElement } from "../flowelements/scale/ScaleFlowElement";
import { ImageFlowElement } from "../flowelements/image/ImageFlowElement";
import { TextElement } from "../flowelements/text/TextElement";
import { AcknowledgeElement } from "../flowelements/acknowledge/AcknowledgeElement";
import { PaymentComplexPageElement } from "../complexpageelements/payment/PaymentComplexPageElement";
import { ITemplateElementTypeInfo } from "../../app/stores/elementTypesStore";
import { useFlowAddElementMenu } from "../flowmenus/FlowAddElementMenu";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import { FileUploadElement } from "../flowelements/fileupload/FileUploadElement";
import { FlowWorkspaceContext } from "./FlowWorkspace";

export const FlowElementSection: VFC<{
  page: IPageDesign;
}> = ({ page }) => {
  const context = useContext(FlowNavigatorContext);
  const workspace = useContext(FlowWorkspaceContext);
  const [elementType, setElementType] = useState<string>();
  const elementIdBasePrefix = "flow-elmt";
  const [elementIdPrefix, setElementIdPrefix] = useState(
    uniqueName(elementIdBasePrefix),
  );
  const [addedComplexPage, setAddedComplexPage] = useState<
    IPageDesign | undefined
  >(undefined);
  const { ...noAddDialog } = useMessageDialog();
  const { ...addComplexPageConfirmation } = useConfirmDialog();
  const { ...addQuestionTreeConfirmation } = useConfirmDialog();
  const { ...noSecondFileUploadDialog } = useMessageDialog();
  const { ...addElementDialog } = useFlowAddElementMenu();

  useEffect(() => {
    setElementIdPrefix(uniqueName(elementIdBasePrefix));
    // eslint-disable-next-line
  }, [page, elementType]);

  useEffect(() => {
    const elementSelection = addElementDialog.elementSelection;

    if (elementSelection?.type) {
      addElementDialog.setIsOpen(false);

      if (
        page.subpages?.length > 0 &&
        (elementSelection as ITemplateElementTypeInfo).type ===
          "CLOSE-ENDED:BUTTON"
      ) {
        addQuestionTreeConfirmation.setIsOpen(true);
        return;
      }

      if (
        page.subpages?.find((sp) => sp.type === "FILE-UPLOAD") &&
        (elementSelection as ITemplateElementTypeInfo).type === "FILE-UPLOAD"
      ) {
        noSecondFileUploadDialog.setIsOpen(true);
        return;
      }

      if (elementSelection.category === "COMPLEX_PAGE") {
        setAddedComplexPage(
          newComplexPage(elementSelection.type as TemplateItemTypes),
        );

        return;
      }

      const newElement = {
        id: uuidv4(),
        type: (elementSelection as ITemplateElementTypeInfo).type,
      } as IElementDesign;
      if (!page.subpages) {
        page.subpages = [] as IElementDesign[];
      }
      page.subpages?.push(newElement);
      workspace.setScrollElementId(newElement.id);
      context.refreshNavElements();
    }
    // eslint-disable-next-line
  }, [addElementDialog.elementSelection]);

  useEffect(() => {
    var section = context.section();
    if (section && addComplexPageConfirmation.userConfirmed) {
      insertPage(section, page.id, addedComplexPage as IPageDesign);

      context.selectPageHandler(addedComplexPage?.id || "");
    }
    // eslint-disable-next-line
  }, [addComplexPageConfirmation.userConfirmed]);

  useEffect(() => {
    var section = context.section();
    if (section && addedComplexPage) {
      if (page.subpages.length > 0) {
        addComplexPageConfirmation.setUserConfirmed(false);
        addComplexPageConfirmation.setIsOpen(true);
      } else {
        insertPage(section, page.id, addedComplexPage as IPageDesign);

        context.selectPageHandler(addedComplexPage?.id);
      }
    }
    // eslint-disable-next-line
  }, [addedComplexPage]);

  useEffect(() => {
    var section = context.section();
    if (section && addQuestionTreeConfirmation.userConfirmed) {
      var newBranchingPage = newTemplatePage();
      newBranchingPage.subpages = [
        {
          id: uuidv4(),
          type: "CLOSE-ENDED:BUTTON",
        } as IElementDesign,
      ];
      insertPage(section, page.id, newBranchingPage);
      context.selectPageHandler(newBranchingPage?.id || "");
    }
    // eslint-disable-next-line
  }, [addQuestionTreeConfirmation.userConfirmed]);

  const complexPage = pageIsComplex(page);

  const FlowElement: VFC<{
    page: IPageDesign;
    elemenType: TemplateItemTypes | undefined;
    elementId: string;
  }> = ({ page, elemenType, elementId }) => {
    switch (elemenType) {
      case "ACKNOWLEDGE":
        return (
          <AcknowledgeElement
            key={uniqueName("ACK-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "CLOSE-ENDED:BUTTON":
        return (
          <BranchingElement
            key={uniqueName("BRCH-ELMNT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "CLOSE-ENDED:MULTI_SELECT":
        return (
          <MultiSelectElement
            key={uniqueName("MULTSEL-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "CLOSE-ENDED:RADIO_BUTTON":
      case "CLOSE-ENDED:DROPDOWN":
      case "CLOSE-ENDED:CHIPS":
        return (
          <SingleSelectElement
            key={uniqueName("SNGLSEL-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
            elementType={elemenType}
          />
        );
      case "OPEN-ENDED":
        return (
          <OpenEndedFlowElement
            key={uniqueName("OE-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "OPEN-ENDED:MULTI_LINE":
        return (
          <OpenEndedMultiLineElement
            key={uniqueName("OEML-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "DATE-PICKER":
        return (
          <DatePickerFlowElement
            key={uniqueName("DTPICK-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "HTML":
        return (
          <HtmlFlowElement
            key={uniqueName("HTML-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "IMAGE":
        return (
          <ImageFlowElement
            key={uniqueName("IMG-ELMT")}
            page={page}
            elementType={elemenType}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "SCALE":
        return (
          <ScaleFlowElement
            key={uniqueName("SCALE-ELMT")}
            page={page}
            elementType="SCALE"
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "TEXT":
        return (
          <TextElement
            key={uniqueName("TEXT-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      case "FILE-UPLOAD":
        return (
          <FileUploadElement
            key={uniqueName("UPLOAD-ELMT")}
            page={page}
            setElementType={setElementType}
            elementId={elementId}
          />
        );
      default:
        return <></>;
    }
  };

  const ComplexElement: VFC<{
    page: IPageDesign;
  }> = ({ page }) => {
    switch (page.type) {
      case "PHARMACY":
        return <PharmacyComplexPageElement pageId={page.id} />;
      case "PAYMENT":
        return <PaymentComplexPageElement pageId={page.id} />;
      default:
        return <></>;
    }
  };

  return (
    <>
      <>
        {complexPage ? (
          <ComplexElement page={page} />
        ) : (
          page.subpages?.map((e, index) => {
            return (
              <FlowElement
                key={elementIdPrefix + index}
                elemenType={e.type}
                page={page}
                elementId={e.id}
              />
            );
          })
        )}
      </>
      <AddElementButtonStyle
        hidden={
          complexPage ||
          page?.subpages?.find((sp) => sp.type === "CLOSE-ENDED:BUTTON") !==
            undefined
        }
      >
        <Button
          size="xs"
          textAlign="left"
          iconPosition="right"
          icon={<AddNewIcon />}
          appearance="subtle"
          themeName="whitelabel"
          onClick={() => {
            if (pageHasBranching(page)) {
              noAddDialog.setIsOpen(true);
              return;
            }

            addElementDialog.setIsOpen(true);
          }}
        >
          {FlowWorkspaceStrings.AddElementButton}
        </Button>
      </AddElementButtonStyle>
      <noAddDialog.ModalDialog
        type="info"
        position="appcontainer"
        top={"25%"}
        title={FlowWorkspaceStrings.NoAddElementToBranchingDialogTitle}
        closeButtonText={
          FlowWorkspaceStrings.NoAddElementToBranchingCloseButtonText
        }
      >
        <div>{FlowWorkspaceStrings.NoAddElementToBranchingDialogText}</div>
      </noAddDialog.ModalDialog>
      <addComplexPageConfirmation.ModalDialog
        type="info"
        position="appcontainer"
        top={"25%"}
        title={FlowWorkspaceStrings.ElementNeedsOwnPageTitle}
        confirmButtonText={FlowWorkspaceStrings.ElementNeedsOwnPageConfirmText}
        cancelButtonText={FlowWorkspaceStrings.ElementNeedsOwnPageCancelText}
      >
        <div>{FlowWorkspaceStrings.ElementNeedsOwnPageText}</div>
      </addComplexPageConfirmation.ModalDialog>
      <addQuestionTreeConfirmation.ModalDialog
        type="info"
        position="appcontainer"
        top={"25%"}
        title={FlowWorkspaceStrings.QuestionTreeNeedsOwnPageTitle}
        confirmButtonText={
          FlowWorkspaceStrings.QuestionTreeNeedsOwnPageConfirmText
        }
        cancelButtonText={
          FlowWorkspaceStrings.QuestionTreeNeedsOwnPageCancelText
        }
      >
        <div>{FlowWorkspaceStrings.QuestionTreeNeedsOwnPageText}</div>
      </addQuestionTreeConfirmation.ModalDialog>
      <noSecondFileUploadDialog.ModalDialog
        type="info"
        position="appcontainer"
        top={"25%"}
        title={FlowWorkspaceStrings.NoSecondFileUploadDialogTitle}
        closeButtonText={
          FlowWorkspaceStrings.NoSecondFileUploadDialogCloseButtonText
        }
      >
        <div>{FlowWorkspaceStrings.NoSecondFileUploadDialogText}</div>
      </noSecondFileUploadDialog.ModalDialog>
      <addElementDialog.PopupMenu />
    </>
  );
};

const AddElementButtonStyle = styled.div`
  margin-top: 5px;
  margin-left: 15px;
  margin-bottom: 5px;
  & span {
    margin-left: -6px;
    margin-right: 3px !important;
  }
  & button {
    white-space: nowrap;
    padding-right: 0px;
    margin-right: -18px !important;
    border: solid 1px transparent;
    :hover {
      color: #1986e2;
      border: dotted 1px gray;
    }
  }
`;
