import { FC, useContext } from "react";
import { getBranchPageInfo } from "../../app/stores/templateDesignStore";
import {
  FlexRowStyle,
  FlexRowCenteredStyle,
  LinkStyleButton,
  AlignLeftStyle,
} from "@intouchhealth/mfe-library";
import { FlowWorkspaceStrings } from "./FlowWorkspaceStrings";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import styled from "styled-components";

export const BranchPageHeading: FC<{ pageId: string }> = ({ pageId }) => {
  const context = useContext(FlowNavigatorContext);
  const branchedFrom = getBranchPageInfo(context.section(), pageId);

  return (
    <>
      {branchedFrom ? (
        <FlexRowStyle>
          <FlexRowCenteredStyle>
            <BranchingFromStyle>
              <AlignLeftStyle>
                <span style={{ marginRight: "10px" }}>
                  {FlowWorkspaceStrings.PageBranchingFromLabel}
                </span>
              </AlignLeftStyle>
              <AlignLeftStyle>
                <LinkStyleButton
                  label={branchedFrom.parentTitle}
                  onButtonClick={() => {
                    context.selectPageHandler(branchedFrom.parent.id);
                  }}
                />
              </AlignLeftStyle>
              <AlignLeftStyle>
                <span style={{ marginLeft: "5px" }}>
                  ({branchedFrom.parentAnswerText})
                </span>
              </AlignLeftStyle>
            </BranchingFromStyle>
          </FlexRowCenteredStyle>
        </FlexRowStyle>
      ) : (
        <></>
      )}
    </>
  );
};

const BranchingFromStyle = styled.div`
  color: fieldtext;
  margin-bottom: 10px;
`;
