import { FC, useEffect } from "react";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  initializeTemplateDesignElement,
  IPageDesign,
  getAttribute,
  initializeElementAnswers,
  tryAttribute,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { useAnswersComponent } from "../../flowelementcomponents/answerscomponent/AnswersComponent";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";
import { VerticalSpacer } from "@intouchhealth/mfe-library";

export const BranchingElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    return isPromptValid() && answersValidated();
  });
  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);
  const { MultiSelectAnswers, answersValidated } = useAnswersComponent(
    initializeElementAnswers(element),
    2,
    true,
  );
  const isRequired = () => tryAttribute(element, "allowSkip")?.value !== "true";

  useEffect(() => {
    getAttribute(element, "displayFormat", "BUTTON");
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <FlowElementPanel
        page={page}
        elementType="CLOSE-ENDED:BUTTON"
        setElementType={setElementType}
        elementId={elementId}
        requiredDefault={true}
      >
        <PromptInput />
        <MultiSelectAnswers elementId={elementId} />
      </FlowElementPanel>
      {isRequired() ? <></> : <VerticalSpacer />}
    </>
  );
};
