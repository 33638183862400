import React, { useRef } from "react";
import styled from "styled-components";
import { Button } from "@intouchhealth/cig-components";
import { MoreVert } from "@mui/icons-material";

export const FlowContextMenuButton: React.VFC<{
  menuClickHandler: (event: React.MouseEvent) => void;
}> = ({ menuClickHandler }) => {
  var buttonRef = useRef<HTMLDivElement>(null);
  return (
    <FlowContextMenuButtonStyle ref={buttonRef}>
      <Button
        onClick={(event) => {
          if (event.clientX === 0 && event.clientY === 0) {
            event.clientX = buttonRef.current?.getBoundingClientRect().x ?? 0;
            event.clientY = buttonRef.current?.getBoundingClientRect().y ?? 0;
          }
          menuClickHandler(event);
        }}
        wide={false}
        themeName="whitelabel"
        appearance="subtle"
      >
        <MoreVert />
      </Button>
    </FlowContextMenuButtonStyle>
  );
};

const FlowContextMenuButtonStyle = styled.div`
  margin-top: -2px;
  margin-left: 5px;
  margin-right: -10px;
  & button {
    border-sytle: none;
    padding-top: 6px;
    padding-bottom: 1px;
    margin: 0px;
    width: 11px;
    height: 32px;
  }
  & svg {
    margin-left: -25px;
    height: 25px;
    width: 25px;
    color: #ada8a4;
    :hover {
      color: #1986e2;
    }
  }
`;
