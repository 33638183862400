export const ChipsIcon = () => {
  return (
    <div
      style={{
        marginLeft: "0px",
        marginRight: "-12px",
        width: "29px",
        height: "24px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 32 32"
        fill="none"
      >
        <rect x="0.5" y="0.5" width="31" height="31" rx="7.5" fill="white" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.6183 7.14258H5.25717C4.49975 7.14258 3.88574 7.7566 3.88574 8.51401V13.6283C3.88574 14.3857 4.49975 14.9997 5.25717 14.9997H10.6183H14.8572C15.6146 14.9997 16.2286 14.3857 16.2286 13.6283V8.51401C16.2286 7.7566 15.6146 7.14258 14.8572 7.14258H10.6183ZM10.6183 7.14258V9.07117H14V13.0712H10.6091H6.00003V9.07117H10.6091L10.6183 7.14258ZM24.3326 7.14294H18.9715C18.214 7.14294 17.6 7.75696 17.6 8.51437V13.6287C17.6 14.3861 18.214 15.0001 18.9715 15.0001H24.3326H26.5143C27.2717 15.0001 27.8857 14.3861 27.8857 13.6287V8.51437C27.8857 7.75696 27.2717 7.14294 26.5143 7.14294H24.3326ZM24.3326 7.14294L24.3417 9.07116H26V13.0712H24.3326V15.0001L24.324 13.0712H19.5V9.07116H24.3326V7.14294ZM14.2572 17.0001H14.6182L15.3611 19.0712H15.0092L15 23.0712H15.3526L14.6182 24.8572H14.2572C13.4998 24.8572 12.8857 24.2432 12.8857 23.4858V18.3715C12.8857 17.6141 13.4998 17.0001 14.2572 17.0001ZM14.6182 24.8572L15.3611 23.0712H26V19.0712H15.3703L14.6182 17.0001H26.7429C27.5003 17.0001 28.1143 17.6141 28.1143 18.3715V23.4858C28.1143 24.2432 27.5003 24.8572 26.7429 24.8572H14.6182ZM7.73252 17H5.37144C4.61403 17 4.00002 17.614 4.00002 18.3714V23.4857C4.00002 24.2431 4.61403 24.8571 5.37144 24.8571H7.73252H10.1286C10.886 24.8571 11.5 24.2431 11.5 23.4857V18.3714C11.5 17.614 10.886 17 10.1286 17H7.73252ZM7.73252 17V18.5H9.50002V23H7.50852L7.73252 24.8571L7.50002 23H6.00002V18.5H7.72334L7.73252 17Z"
          fill="#201E1D"
        />
        <rect
          x="0.5"
          y="0.5"
          width="31"
          height="31"
          rx="7.5"
          stroke="white"
          stroke-opacity="0.72"
        />
      </svg>
    </div>
  );
};
