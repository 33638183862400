import { FC } from "react";
import styled from "styled-components";

import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  initializeTemplateDesignElement,
  IPageDesign,
  getAttribute,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";

export const OpenEndedMultiLineElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    getAttribute(element, "displayFormat", "MULTI_LINE");
    var isValid = isPromptValid();
    return isValid;
  });

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);

  return (
    <FlowElementPanel
      page={page}
      elementType="OPEN-ENDED:MULTI_LINE"
      setElementType={setElementType}
      elementId={elementId}
    >
      <RowLayoutContainer>
        <PromptInput />
      </RowLayoutContainer>
    </FlowElementPanel>
  );
};

const RowLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`;
