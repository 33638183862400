import { FC, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  getAttribute,
  initializeTemplateDesignElement,
  IPageDesign,
  setAttributeValue,
} from "../../../app/stores/templateDesignStore";
import { ImageFlowElementStrings } from "./ImageFlowElementStrings";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";
import {
  FixedSizeContainer,
  HorizontalSpacer,
  isProperlyFormattedUrl,
  PanelContainer,
  RequiredInputIndicator,
  useRadioColumnElement,
  useTranslatableTextInput,
} from "@intouchhealth/mfe-library";
import { AddNewIcon, Button, PureInput } from "@intouchhealth/cig-components";
import {
  IImageInfo,
  useImageManagerDialog,
} from "../../templatesform/ImageManagerDialog";
import { Image as ImageIcon } from "@mui/icons-material";
import { CircularProgress } from "@mui/material";
import { RootStoreContext } from "../../../app/stores/rootStore";
import { useS3ImageList } from "../../templatesform/S3ImageList";

enum ImageDisplayTypes {
  DEFAULT = "DEFAULT",
  CUSTOM = "CUSTOM",
}

const RECOMMENDED_IMAGE_VALUES = {
  width: "1000",
  height: "800",
};

export const ImageFlowElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
  elementType: string;
}> = ({ page, setElementType, elementId, elementType }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    const isValid =
      isPromptValid() &&
      isUrlAttributeValid() &&
      isWidthAttributeValid() &&
      isHeightAttributeValid() &&
      isAccessibilityLabelAttributeValid();

    return isValid;
  });

  const urlAttribute = getAttribute(element, "url", "");
  const widthAttribute = getAttribute(
    element,
    "width",
    RECOMMENDED_IMAGE_VALUES.width,
  );
  const heightAttribute = getAttribute(
    element,
    "height",
    RECOMMENDED_IMAGE_VALUES.height,
  );
  const accessibilityLabelAttribute = getAttribute(
    element,
    "accessibilityLabel",
    undefined,
    "",
  );

  const defaultRadioOption =
    widthAttribute.value === RECOMMENDED_IMAGE_VALUES.width &&
    heightAttribute.value === RECOMMENDED_IMAGE_VALUES.height
      ? ImageDisplayTypes.DEFAULT
      : ImageDisplayTypes.CUSTOM;
  const { ...Radio } = useRadioColumnElement();
  const [selectedRadioOption, setSelectedRadioOption] =
    useState<ImageDisplayTypes>(defaultRadioOption);
  const updateSelectedRadioOption = (id: string) => {
    if ((id as ImageDisplayTypes) === ImageDisplayTypes.DEFAULT) {
      setAttributeValue(element, "width", RECOMMENDED_IMAGE_VALUES.width);
      setAttributeValue(element, "height", RECOMMENDED_IMAGE_VALUES.height);
      setWidth(widthAttribute.value);
      setHeight(heightAttribute.value);
    }
    setSelectedRadioOption(id as ImageDisplayTypes);
  };
  const isUrlAttributeValid = () => {
    return (
      urlAttribute.value.length > 0 &&
      isProperlyFormattedUrl(urlAttribute.value, true)
    );
  };

  const isWidthAttributeValid = () => {
    return (
      widthAttribute.value.length > 0 && /^\d+$/.test(widthAttribute.value)
    );
  };

  const isHeightAttributeValid = () => {
    return (
      heightAttribute.value.length > 0 && /^\d+$/.test(heightAttribute.value)
    );
  };

  const isAccessibilityLabelAttributeValid = () => {
    return accessibilityLabelAttribute.defaultText().length > 0;
  };

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);
  const [url, setUrl] = useState<string>(urlAttribute.value);
  const [width, setWidth] = useState<string>(widthAttribute.value);
  const [height, setHeight] = useState<string>(heightAttribute.value);
  const { TranslatableTextInput } = useTranslatableTextInput(
    "Accessibility-" + element.id,
  );

  // State to track if the image name is ready to be displayed. This avoids flickering when the async tasks havent finished yet.
  const [nameReady, setNameReady] = useState<boolean>(false);
  // State to track the selected image details
  const [selectedImageInfo, setSelectedImageInfo] = useState<IImageInfo | null>(
    null,
  );

  const { commonStore } = useContext(RootStoreContext);
  const imageManager = useImageManagerDialog(url, selectedImageInfo);
  const { loading, s3ImageList, refetch, findS3ImageFromUrl } = useS3ImageList(
    commonStore.practiceId,
  );

  // Monitor the changes after refreshing the S3 image list.
  useEffect(() => {
    if (!loading) {
      if (s3ImageList?.length) {
        const selectedImageInfo = findS3ImageFromUrl(url);

        setSelectedImageInfo(selectedImageInfo ?? null);

        if (selectedImageInfo) {
          setUrl(selectedImageInfo?.url);
          urlAttribute.value = selectedImageInfo?.url;
        }
      }
      setNameReady(true);
    }
    // eslint-disable-next-line
  }, [loading, s3ImageList, url]);

  // Monitor the changes after selecting an image from the modal
  useEffect(() => {
    const { selectedImageInfo } = imageManager;

    setSelectedImageInfo(selectedImageInfo);
    if (selectedImageInfo) {
      setUrl(selectedImageInfo?.url || "");
      urlAttribute.value = selectedImageInfo?.url || "";

      refetch();
    }
    // eslint-disable-next-line
  }, [imageManager.selectedImageInfo]);

  // Monitor deletion of an image from the S3 bucket. We will clear the URL if it points to an image which previously contained the practice ID.
  useEffect(() => {
    if (!imageManager.isOpen && !loading && nameReady) {
      (async () => {
        const { data } = await refetch();
        if (
          !findS3ImageFromUrl(url, data.dynamicTemplateImageFiles) &&
          url.includes(commonStore.practiceId)
        ) {
          setUrl("");
          urlAttribute.value = "";
        }
      })();
    }
    // eslint-disable-next-line
  }, [imageManager.isOpen, loading, nameReady]);

  const hasImage = !!url;

  return (
    <FlowElementPanel
      page={page}
      elementType={elementType as TemplateItemTypes}
      setElementType={setElementType}
      elementId={elementId}
      hideRequired={true}
    >
      <RowLayoutContainer>
        <ImageThumbStyles isHidden={!hasImage}>
          {!loading && nameReady && (
            <span>
              <ImageIcon className="thumb-icon" />
              <span>{selectedImageInfo?.name || url}</span>
            </span>
          )}
          {(!nameReady || loading) && <CircularProgress />}
        </ImageThumbStyles>
        {hasImage ? <HorizontalSpacer /> : <></>}
        <ImageButtonStyles hasImage={hasImage}>
          <Button
            size="xs"
            themeName="whitelabel"
            appearance="subtle"
            icon={!hasImage ? <AddNewIcon /> : undefined}
            onClick={() => imageManager.setIsOpen(true)}
          >
            {!hasImage
              ? ImageFlowElementStrings.AddImageLabel
              : ImageFlowElementStrings.ChangeImageLabel}
          </Button>
        </ImageButtonStyles>
      </RowLayoutContainer>
      <HorizontalLine />
      <RowLayoutContainer>
        <PromptInput />
      </RowLayoutContainer>
      <HorizontalLine />
      <PanelWrapper>
        <PanelContainer
          title={ImageFlowElementStrings.DisplaySettingsMobileText}
          sectionRequired={true}
        >
          <SelectionFlex>
            <span>
              <Radio.RadioColumnElement
                id={ImageDisplayTypes.DEFAULT}
                checked={selectedRadioOption === ImageDisplayTypes.DEFAULT}
                selected={updateSelectedRadioOption}
              />
              {ImageFlowElementStrings.DisplaySettingsMobileDefaultText}
            </span>
            <span>
              <Radio.RadioColumnElement
                id={ImageDisplayTypes.CUSTOM}
                checked={selectedRadioOption === ImageDisplayTypes.CUSTOM}
                selected={updateSelectedRadioOption}
              />
              {ImageFlowElementStrings.DisplaySettingsMobileCustomText}
            </span>
          </SelectionFlex>
        </PanelContainer>
      </PanelWrapper>
      <ConditionalContainer
        isHidden={selectedRadioOption === ImageDisplayTypes.DEFAULT}
      >
        <RowLayoutContainer>
          <FixedSizeContainer width="33.33%" marginRight="25px">
            <RequiredInputIndicator>
              <PureInput
                label={ImageFlowElementStrings.WidthLabel}
                value={width}
                onChange={(value) => {
                  setWidth(value);
                  widthAttribute.value = value;
                }}
                className="sls-input"
                invalid={!isWidthAttributeValid()}
                infoMessage={
                  isWidthAttributeValid()
                    ? ImageFlowElementStrings.WidthHelpText
                    : ImageFlowElementStrings.WidthRequiredText
                }
                type="number"
              />
            </RequiredInputIndicator>
          </FixedSizeContainer>
          <FixedSizeContainer width="33.33%" marginRight="25px">
            <RequiredInputIndicator>
              <PureInput
                label={ImageFlowElementStrings.HeightLabel}
                value={height}
                onChange={(value) => {
                  setHeight(value);
                  heightAttribute.value = value;
                }}
                className="sls-input"
                invalid={!isHeightAttributeValid()}
                infoMessage={
                  isHeightAttributeValid()
                    ? ImageFlowElementStrings.HeightHelpText
                    : ImageFlowElementStrings.HeightRequiredText
                }
                type="number"
              />
            </RequiredInputIndicator>
          </FixedSizeContainer>
        </RowLayoutContainer>
      </ConditionalContainer>
      <HorizontalLine />
      <RequiredInputIndicator>
        <TranslatableTextInput
          label={ImageFlowElementStrings.AccessibilityLabel}
          initializeDefaultValue={() => {
            return accessibilityLabelAttribute.defaultText();
          }}
          onDefaultChanged={(value) => {
            accessibilityLabelAttribute.setDefaultText(value);
          }}
          initializeTranslatedValue={(languageCode) => {
            return accessibilityLabelAttribute.getTranslationText(languageCode);
          }}
          onTranslatedChanged={(value, languageCode) => {
            accessibilityLabelAttribute.setTranslationText(languageCode, value);
          }}
          isDefaultValid={isAccessibilityLabelAttributeValid}
          isDefaultRequired={() => true}
          isTranslatedValid={() => true}
          isTranslatedRequired={() => false}
          helpText={ImageFlowElementStrings.AccessibilityHelpText}
          requiredHelpText={ImageFlowElementStrings.AccessibilityRequiredText}
        />
      </RequiredInputIndicator>
      <imageManager.ImageManagerDialog />
    </FlowElementPanel>
  );
};

const RowLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  margin-bottom: 20px;
  flex: 2 1 auto;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  margin: 10px 0;
  background: #ded9d6;
`;

const PanelWrapper = styled.div`
  display: flex;

  & * {
    font-size: 1rem;
    padding: 0;
  }
`;

const SelectionFlex = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-bottom: 18px;

  & > span {
    display: flex;
    flex-direction: row;
  }
`;

const ConditionalContainer = styled.div<{ isHidden: boolean }>`
  display: ${({ isHidden }: { isHidden: boolean }) =>
    isHidden ? "none" : "block"};
`;

const ImageThumbStyles = styled.div<{ isHidden: boolean }>`
  position: relative;
  display: ${({ isHidden }: { isHidden: boolean }) =>
    isHidden ? "none" : "block"};
  & .thumb-icon {
    transform: translateY(6px);
  }
  max-width: calc(100% - 200px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ImageButtonStyles = styled.div<{ hasImage: boolean }>`
  ${({ hasImage }: { hasImage: boolean }) =>
    !hasImage
      ? "transform: translate(-15px,5px);"
      : "transform: translate(0,5px);"}
`;
