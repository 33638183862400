export const AnswerComponentStrings = {
  AnswersListTitle: "Answers",
  AddAnswerButton: "Add answer",
  MoveAnswerNotAllowedDialogTile: "Answer can't be moved in that direction",
  MoveAnswerNotAllowedDialogMessage:
    "It's already as far up or down as it can go.",
  MoveAnswerNotAllowedCloseButton: "OK",
  MinimumAnswersCountValidationErrorMessage: (numberOfAnswers: number) =>
    `At least ${numberOfAnswers} answers are required.`,
  AnswerRequiredText: "Answer can't be blank",
  DeleteAnswerConfirmDialogTile: "Delete answer and branch?",
  DeleteAnswerConfirmDialogDeleteMessage:
    "All pages branching from this answer will be deleted, and you won't be able to recover them.",
  DeleteAnswerConfirmationButton: "Delete",
  DeleteAnswerCancelConfirmationButton: "Keep",
  RemoveBranchConfirmDialogTile: "Delete branch?",
  RemoveBranchConfirmDialogDeleteMessage:
    "All pages on this branch will be deleted, and you won't be able to recover them.",
  RemoveBranchConfirmationButton: "Delete",
  RemoveBranchCancelConfirmationButton: "Keep",
  AnswerHelpText: "Keep it short and clear",
  AnswerRequiredHelpText: "Question is required",
};
