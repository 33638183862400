import {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  clearAsyncFirstMessageElement,
  findAsyncFirstMessageElement,
  getPage,
  IPageDesign,
  isAsyncMessageTemplateType,
  ISectionDesign,
  ITemplateDesign,
  prepareInboundTemplate,
  prepareOutboundTemplate,
  setAsyncFirstMessageElement,
  validateTemplateDesign,
} from "../../app/stores/templateDesignStore";
import {
  ConvertToDynamicTemplateType,
  DynamicTemplateType,
  useGetGeneratedFlowQuery,
} from "./TemplateFormQueries";

import { useFlowWorkspace } from "../flowworkspace/FlowWorkspace";
import {
  FlowNavigatorContext,
  useFlowNavigator,
} from "../flownavigator/FlowNavigator";
import {
  PageContainer,
  uniqueName,
  useMessageDialog,
  useNavigateAwayWarning,
  INavigateAwayDialogStrings,
  TranslationManagerContext,
} from "@intouchhealth/mfe-library";
import { TemplateFormButtons } from "./TemplateFormButtons";
import { IUpdatedTemplateDetails } from "./TemplateDetailsDialog";
import styled from "styled-components";
import { DetailsSectionContext } from "./DetailsSection";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { cloneDeep } from "@apollo/client/utilities";
import { useDynamicTemplateHasChanges } from "./DynamicTemplateCheckChanges";
import { DesignerHeadingBar } from "./DesignerHeadingBar";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
  TemplatePreviewContainer,
  TemplatePreviewContext,
} from "../templatepreview/TemplatePreviewContainer";
import { ConvertToAdminTemplateType } from "./TemplateFormMutations";
import { TranslationOptions } from "../../app/stores/translationOptionsStore";

export const DesignerTodoContext = createContext({
  setMessageContent: (_content: ReactNode) => {},
  setIsOpen: (_isOpen: boolean) => {},
  refreshDesigner: () => {},
});

export const DesignTemplateFormContext = createContext({
  validateTemplate: (): Boolean => false,
  updateGeneratedFlow: () => {},
  resetToFirstPage: () => {},
});

export type DesignTemplateModeTypes = "CREATE" | "UPDATE" | "COPY";
interface DesignTemplateProviderProps {
  dynamicTemplate: DynamicTemplateType;
  mode: DesignTemplateModeTypes;
  setUpdatedTemplate: Dispatch<SetStateAction<ITemplateDesign | undefined>>;
}

export const DesignTemplateForm: FC<DesignTemplateProviderProps> = ({
  dynamicTemplate,
  mode,
  setUpdatedTemplate,
}) => {
  const formContext = useContext(DesignTemplateFormContext);
  const translationContext = useContext(TranslationManagerContext);
  const previewContext = useContext(TemplatePreviewContext);

  const templateId = dynamicTemplate.templateId;
  const [template, setTemplate] = useState<ITemplateDesign>();
  const [validationErrorMessage, setValidationErrorMessage] = useState("");
  const [updated, setUpdated] = useState(false);
  const [openFirstMessage, setOpenFirstMessage] = useState(false);
  const [workspaceKey, setWorkspaceKey] = useState(uniqueName);
  const [previewTemplateId, setPreviewTemplateId] = useState<
    string | undefined
  >();
  const workspaceScrollY = useRef<number>(0);

  const [, updateState] = useState<Object>();
  const forceUpdate = useCallback(() => updateState({}), []);

  const { hasChanges, initializeOriginalTemplate } =
    useDynamicTemplateHasChanges(template);
  const navigator = useFlowNavigator();
  const workspace = useFlowWorkspace(workspaceScrollY);
  const navigatorContext = useContext(FlowNavigatorContext);
  const detailsContext = useContext(DetailsSectionContext);
  const { ...validationErrorDialog } = useMessageDialog();
  const { ...todo } = useMessageDialog();
  const todoContext = useContext(DesignerTodoContext);
  const { commonStore } = useContext(RootStoreContext);

  const [activeLanguage, setActiveLanguage] = useState(
    translationContext.defaultLanguageCode,
  );
  const [activeLanguageCode, setActiveLanguageCode] = useState("default");
  const [generateFlowInput, setGenerateFlowInput] = useState<Object>();
  const [generatedFlow, setGeneratedFlow] = useState("");
  const [formInitialized, setFormInitialized] = useState(false);
  const { loading, data, error } = useGetGeneratedFlowQuery(
    generateFlowInput,
    activeLanguageCode,
  );
  translationContext.languageChangeNotification.subscribe(
    `designTemplateFormLanguage-${templateId}`,
    setActiveLanguage,
  );

  useEffect(() => {
    setActiveLanguageCode(
      TranslationOptions.find(
        (option) => option.languageName === activeLanguage,
      )?.languageCode || "default",
    );

    if (previewTemplateId) {
      previewContext.restartPreview();
    }
    //eslint-disable-next-line
  }, [activeLanguage]);

  const dialogStrings = {
    title: TemplateFormStrings.AbandonChangesConfirmDialogTile,
    confirmButtonText: TemplateFormStrings.AbandonChangesConfirmButton,
    cancelButtonText: TemplateFormStrings.AbandonChangesCancelButton,
    message: TemplateFormStrings.AbandonChangesConfirmDialogResetMessage,
  } as INavigateAwayDialogStrings;
  const NavigateAwayModal = useNavigateAwayWarning(
    (navData: Location) => {
      // webSDK navigation contains pathname and hash in window location. Avoid detecting webSDK navigation as a navigation away.
      if (
        navData?.hash &&
        window.location.href.includes(navData?.pathname + navData?.hash)
      ) {
        return false;
      }

      return hasChanges();
    },
    dialogStrings,
    commonStore.history,
  );
  todoContext.setMessageContent = (content: ReactNode) => {
    todo.setMessageContent(content);
  };
  todoContext.setIsOpen = (isOpen: boolean) => [todo.setIsOpen(isOpen)];
  todoContext.refreshDesigner = () => forceUpdate();

  formContext.validateTemplate = () => {
    const errors = validateTemplateDesign(template);

    if (errors.length > 0) {
      navigator.selectPage(errors[0].pageId);
      setValidationErrorMessage(errors[0].errorMessage);
      validationErrorDialog.setIsOpen(true);
      setUpdated(false);

      return false;
    }

    return true;
  };

  formContext.updateGeneratedFlow = () => {
    if (template) {
      var templateConfig = ConvertToDynamicTemplateType(template);
      if (detailsContext.initialized) {
        detailsContext.updateFields(templateConfig);
      }

      var outboundTemplate = cloneDeep(template);
      prepareOutboundTemplate(outboundTemplate);

      outboundTemplate = ConvertToAdminTemplateType(
        templateConfig,
        outboundTemplate.flow,
      );

      if (outboundTemplate.templateId) {
        delete (outboundTemplate as any).templateId;
      }

      setGenerateFlowInput(outboundTemplate);
    }
  };

  formContext.resetToFirstPage = () => {
    const firstPage = template?.flow?.sections[0]?.pages[0]?.id;
    if (firstPage) {
      navigator.selectPage(firstPage);
    }
  };

  navigatorContext.previewNotification?.subscribe(
    `previewNotificationDesigner-${templateId}`,
    setPreviewTemplateId,
  );

  const refreshWorkspace = () => {
    setWorkspaceKey(uniqueName());
  };

  const handleDetailsUpdated = (templateDetails: IUpdatedTemplateDetails) => {
    if (mode === "COPY" && !isAsyncMessageTemplateType(templateDetails.type)) {
      clearAsyncFirstMessageElement(navigatorContext.sections);
      refreshWorkspace();
    }
  };

  const handleFirstMessageSet = (id: string | undefined) => {
    setAsyncFirstMessageElement(navigatorContext.sections, id);
    refreshWorkspace();
  };

  useEffect(
    () => {
      detailsContext.initialized = false;
      // create an editable copy of the template for editing with designer
      var templateDesign = cloneDeep({
        templateId: templateId,
        organizationId: dynamicTemplate.organizationId,
        practiceId: dynamicTemplate.practiceId,
        name: dynamicTemplate.name,
        type: dynamicTemplate.type,
        endAction: dynamicTemplate.endAction,
        tag: dynamicTemplate.tag,
        defaultLanguageCode: dynamicTemplate.defaultLanguageCode,
        translations: dynamicTemplate.translations,
        flow: {
          sections: dynamicTemplate.flow.sections as ISectionDesign[],
        },
      } as ITemplateDesign);
      prepareInboundTemplate(templateDesign);
      setTemplate(templateDesign);
      initializeOriginalTemplate(templateDesign);
      navigator.setSections(templateDesign.flow.sections);
    }, // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    if (template?.flow) {
      template.flow.sections = navigator.sections;
    }
  }, [navigator.sections, template]);

  useEffect(() => {
    if (updated) {
      if (formContext.validateTemplate()) {
        var pendingDetailFieldChanges = {
          type: template?.type,
        } as DynamicTemplateType;
        detailsContext.updateFields(pendingDetailFieldChanges);
        if (
          template &&
          isAsyncMessageTemplateType(pendingDetailFieldChanges.type) &&
          !findAsyncFirstMessageElement(template.flow.sections)
        ) {
          setOpenFirstMessage(true);
          setUpdated(false);
        } else {
          setUpdatedTemplate(template);
        }
      }
    }
    // eslint-disable-next-line
  }, [updated, setUpdated]);

  useEffect(() => {
    if (data && !loading && !error) {
      setGeneratedFlow(data.generateFlow?.flow?.sections);
      setFormInitialized(true);
    }
  }, [data, loading, error]);

  // Handle changes from navigator to update selected workspace content
  useEffect(() => {
    var section = template?.flow.sections[0] ?? ({} as ISectionDesign);
    var page =
      getPage(section, navigator.selectedPageId) ?? ({} as IPageDesign);
    workspace.setPage(page);
    workspace.setSelectedWorkspace(navigator.selectedType);
    // eslint-disable-next-line
  }, [navigator.selectedType, navigator.selectedPageId]);

  return (
    <>
      <PageLayout>
        <DesignerHeadingBar
          isCreate={mode === "CREATE" || mode === "COPY"}
          template={template}
          dynamicTemplate={dynamicTemplate}
          onDetailsUpdated={handleDetailsUpdated}
          onFirstMessageSet={handleFirstMessageSet}
          openFirstMessage={openFirstMessage}
          setOpenFirstMessage={setOpenFirstMessage}
          formInitialized={formInitialized}
        >
          <TemplateFormButtons
            templateId={dynamicTemplate.templateId}
            templateName={dynamicTemplate.name}
            mode={mode}
            setIsUpdated={setUpdated}
          />
        </DesignerHeadingBar>
        {previewTemplateId ? (
          <PageContainer>
            {!loading && (
              <TemplatePreviewContainer templateFlowJson={generatedFlow} />
            )}
          </PageContainer>
        ) : (
          <PageContainer>
            <FlowAreaLayout>
              <navigator.NavigatorPanel />
              <workspace.WorkspacePanel key={workspaceKey} />
            </FlowAreaLayout>
          </PageContainer>
        )}
      </PageLayout>
      <validationErrorDialog.ModalDialog
        type="error"
        position="appcontainer"
        top={"25%"}
        title={TemplateFormStrings.TemplateValidationErrorTitle}
        closeButtonText={"Ok"}
      >
        {validationErrorMessage}
      </validationErrorDialog.ModalDialog>
      <todo.ModalDialog
        type="warning"
        position="appcontainer"
        top={"25%"}
        title="Feature Not Available"
        closeButtonText={"Ok"}
      >
        {todo.messageContent}
      </todo.ModalDialog>
      <NavigateAwayModal />
    </>
  );
};

const PageLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const FlowAreaLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-between;
  margin-bottom: -16px;
`;
