import { useContext } from "react";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import { FlowMenuControlChoices, useFlowMenuControl } from "./FlowMenuControl";
import { TemplateItemTypes } from "../../app/stores/TemplateItemTypes";

export const useFlowElementMenu = (
  elementType: TemplateItemTypes | undefined,
) => {
  const includedMenuItems = () => {
    var result: FlowMenuControlChoices[] = [
      "moveItemUp",
      "moveItemDown",
      "deleteItem",
    ];
    switch (elementType) {
      case "ACKNOWLEDGE":
      case "CLOSE-ENDED:MULTI_SELECT":
      case "CLOSE-ENDED:RADIO_BUTTON":
      case "CLOSE-ENDED:DROPDOWN":
      case "CLOSE-ENDED:CHIPS":
      case "DATE-PICKER":
      case "OPEN-ENDED":
      case "OPEN-ENDED:MULTI_LINE":
      case "SCALE":
        result.push("duplicateItem");
        break;
      default:
        break;
    }
    return result;
  };

  const {
    PopupMenu,
    isOpen,
    setIsOpen,
    menuConfig,
    setMenuConfig,
    todoMenuItem,
  } = useFlowMenuControl(includedMenuItems());

  const context = useContext(FlowNavigatorContext);
  context.elementOptionMenuHandler = (
    pageId: string,
    elementId: string,
    menuChoice: FlowMenuControlChoices,
  ) => {
    switch (menuChoice) {
      case "moveItemUp":
        context.moveElementHandler(pageId, elementId, "up");
        break;
      case "moveItemDown":
        context.moveElementHandler(pageId, elementId, "down");
        break;
      case "refCategory":
        todoMenuItem(menuChoice, pageId, elementId);
        break;
      case "duplicateItem":
        context.duplicateElementHandler(pageId, elementId);
        break;
      case "deleteItem":
        context.deleteElementHandler(pageId, elementId);
        break;
      default:
        break;
    }
  };

  return { PopupMenu, isOpen, setIsOpen, menuConfig, setMenuConfig };
};
