import { useContext, useEffect, VFC } from "react";
import {
  deleteAttribute,
  getPageSiblings,
  getAttribute,
  IPageDesign,
  newTemplatePage,
  pageAllowsContinueText,
  parentHasBranching,
  pageHasBranching,
  getRootPage,
  pageIsComplex,
} from "../../app/stores/templateDesignStore";
import { AddNewIcon, Button } from "@intouchhealth/cig-components";
import {
  PanelContainer,
  FlexRowStyle,
  FlexRowCenteredStyle,
  MouseEventPosition,
  useTranslatableTextInput,
  VerticalSpacer,
} from "@intouchhealth/mfe-library";
import { BranchPageHeading } from "./BranchPageHeading";
import { FlowWorkspaceStrings } from "./FlowWorkspaceStrings";
import { FlowWorkspaceContentContainer } from "./FlowWorkspace";
import { FlowContextMenuButton } from "./FlowContextMenuButton";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import { FlowElementSection } from "./FlowElementSection";
import { useFlowPageMenu } from "../flowmenus/FlowPageMenu";
import styled from "styled-components";

const WorkspaceContent: VFC<{ page: IPageDesign }> = ({ page }) => {
  const context = useContext(FlowNavigatorContext);
  const pageTitleAttribute = getAttribute(page, "title", undefined, "");
  const continueTextAttribute = getAttribute(
    page,
    "continueText",
    undefined,
    FlowWorkspaceStrings.ContinueTextDefault,
  );
  const pageTitleInput = useTranslatableTextInput("PageTitle-" + page.id);
  const continueTextInput = useTranslatableTextInput("Continue-" + page.id);
  const rootPage = getRootPage(context.section(), page.id);
  const useMenuForBranch =
    rootPage && rootPage.id !== page.id && pageHasBranching(rootPage);
  const optionMenu = useFlowPageMenu(
    useMenuForBranch ? "branchWorkspace" : "workspace",
  );
  const hasBranching = parentHasBranching(context.section(), page.id);
  const hasContinueText = pageAllowsContinueText(page);

  const isDefaultPageTitleValid = () => {
    if (pageIsComplex(page)) {
      return true;
    }
    return pageTitleAttribute.defaultText().length > 0;
  };

  useEffect(() => {
    pageTitleInput.defaultValueRef.current?.focus();
    context.scrollToNavTitle(page.id);
    if (pageIsComplex(page)) {
      deleteAttribute(page, "title");
    }
    if (!hasContinueText) {
      deleteAttribute(page, "continueText");
    }
    page.isValid = () => {
      return (
        isDefaultPageTitleValid() &&
        (!hasContinueText || continueTextAttribute?.defaultText().length > 0)
      );
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div>
        {pageIsComplex(page) ? (
          <></>
        ) : (
          <>
            <PanelContainer>
              <BranchPageHeading pageId={page.id} />
              <FlexRowStyle>
                <FlexRowCenteredStyle>
                  <pageTitleInput.TranslatableTextInput
                    label={FlowWorkspaceStrings.PageTitleLabel}
                    initializeDefaultValue={() => {
                      return pageTitleAttribute.defaultText();
                    }}
                    onDefaultChanged={(value) => {
                      pageTitleAttribute.setDefaultText(value);
                      context.updateNavTitle(page.id, value);
                    }}
                    initializeTranslatedValue={(languageCode) => {
                      return pageTitleAttribute.getTranslationText(
                        languageCode,
                      );
                    }}
                    onTranslatedChanged={(value, languageCode) => {
                      pageTitleAttribute.setTranslationText(
                        languageCode,
                        value,
                      );
                    }}
                    isDefaultValid={() => isDefaultPageTitleValid()}
                    isDefaultRequired={() => true}
                    isTranslatedValid={() => true}
                    isTranslatedRequired={() => false}
                    helpText={FlowWorkspaceStrings.PageTitleHelpText}
                    requiredHelpText={
                      FlowWorkspaceStrings.PageTitleRequiredText
                    }
                  />
                  <FlowContextMenuLayout>
                    <FlowContextMenuButton
                      menuClickHandler={(event) => {
                        optionMenu.setMenuConfig({
                          optionMenuHandler: (menuChoice) =>
                            context.pageOptionMenuHandler(page.id, menuChoice),
                          position: MouseEventPosition(event),
                        });
                        optionMenu.setIsOpen(true);
                      }}
                    />
                  </FlowContextMenuLayout>
                </FlexRowCenteredStyle>
              </FlexRowStyle>
            </PanelContainer>
          </>
        )}
        <FlowElementSection page={page} />
        {!pageAllowsContinueText(page) ? null : (
          <PanelContainer>
            <FlexRowStyle>
              <continueTextInput.TranslatableTextInput
                label={FlowWorkspaceStrings.ContinueTextLabel}
                initializeDefaultValue={() => {
                  return continueTextAttribute.defaultText();
                }}
                onDefaultChanged={(value) => {
                  continueTextAttribute.setDefaultText(value);
                }}
                initializeTranslatedValue={(languageCode) => {
                  return continueTextAttribute.getTranslationText(languageCode);
                }}
                onTranslatedChanged={(value, languageCode) => {
                  continueTextAttribute.setTranslationText(languageCode, value);
                }}
                isDefaultValid={() =>
                  continueTextAttribute.defaultText()?.length > 0
                }
                isDefaultRequired={() => true}
                isTranslatedValid={() => true}
                isTranslatedRequired={() => false}
                helpText={FlowWorkspaceStrings.ContinueTextHelpText}
                requiredHelpText={FlowWorkspaceStrings.ContinueTextRequiredText}
              />
            </FlexRowStyle>
          </PanelContainer>
        )}
        <AddBranchPageButtonStyle hidden={!hasBranching}>
          <Button
            size="xs"
            textAlign="left"
            iconPosition="right"
            icon={<AddNewIcon />}
            appearance="subtle"
            themeName="whitelabel"
            onClick={() => {
              var siblings = getPageSiblings(context.section(), page.id);
              if (siblings) {
                var newBranchPage = newTemplatePage();
                siblings.push(newBranchPage);
                context.selectPageHandler(newBranchPage.id);
              }
            }}
          >
            {FlowWorkspaceStrings.AddBranchPageButton}
          </Button>
        </AddBranchPageButtonStyle>
        <VerticalSpacer hidden={hasBranching || !hasContinueText} />
      </div>
      <optionMenu.PopupMenu />
    </>
  );
};

export const PageWorkspaceContent: VFC<{
  active: boolean;
  page: IPageDesign;
}> = ({ active, page }) => {
  return (
    <>
      <FlowWorkspaceContentContainer hidden={!active}>
        <WorkspaceContent page={page} />
      </FlowWorkspaceContentContainer>
    </>
  );
};

const FlowContextMenuLayout = styled.div`
  height: 100%;
  margin-top: 40px;
`;

const AddBranchPageButtonStyle = styled.div`
  margin-top: 5px;
  margin-left: 15px;
  margin-bottom: 5px;
  & span {
    margin-left: -6px;
    margin-right: 3px !important;
  }
  & button {
    white-space: nowrap;
    padding-right: 0px;
    margin-right: -18px !important;
    border: solid 1px transparent;
    :hover {
      color: #1986e2;
      border: dotted 1px gray;
    }
  }
`;
