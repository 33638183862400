function stripIdFields(obj: any) {
  for (const property in obj) {
    // Temporary code to remove subpages
    if (property === "subpages" && obj[property].length === 0) {
      delete obj[property];
    } else {
      if (
        typeof obj[property] === "object" &&
        !(obj[property] instanceof File)
      ) {
        delete obj.property;
        const newData = stripIdFields(obj[property]);
        obj[property] = newData;
      } else {
        if (property === "id") {
          delete obj[property];
        }
      }
    }
  }
  return obj;
}

export default stripIdFields;
