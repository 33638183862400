import React, { useContext } from "react";
import { useMessageDialog } from "@intouchhealth/mfe-library";
import {
  getElement,
  getPage,
  pageHasBranching,
} from "../../app/stores/templateDesignStore";
import { FlowNavigatorContext } from "./FlowNavigator";
import { FlowNavigatorStrings } from "./FlowNavigatorStrings";
import { FlowWorkspaceContext } from "../flowworkspace/FlowWorkspace";

export const useMoveElementHandler = () => {
  const context = useContext(FlowNavigatorContext);
  const workspace = useContext(FlowWorkspaceContext);
  const { ...moveNotAllowed } = useMessageDialog();

  const MoveElementDialog: React.VFC = () => {
    return (
      <moveNotAllowed.ModalDialog
        type="warning"
        position="appcontainer"
        top={"25%"}
        title={FlowNavigatorStrings.MoveElementNotAllowedDialogTile}
        closeButtonText={FlowNavigatorStrings.MoveElementNotAllowedCloseButton}
      >
        {FlowNavigatorStrings.MoveElementNotAllowedDialogMessage}
      </moveNotAllowed.ModalDialog>
    );
  };

  context.moveElementHandler = (
    pageId: string,
    elementId: string,
    direction: "up" | "down",
  ) => {
    var page = getPage(context.section(), pageId);
    var element = getElement(page, elementId);

    if (page && pageHasBranching(page)) {
      moveNotAllowed.setIsOpen(true);
      return;
    }

    if (page && element) {
      var curElementIndex = page.subpages.indexOf(element);
      var newElementIndex =
        direction === "down" ? curElementIndex + 1 : curElementIndex - 1;
      var noMove =
        page.subpages.length < 2 ||
        newElementIndex < 0 ||
        newElementIndex > page.subpages.length - 1;
      if (noMove) {
        moveNotAllowed.setIsOpen(true);
      } else {
        var swapElement = page.subpages[newElementIndex];
        page.subpages[newElementIndex] = page.subpages[curElementIndex];
        page.subpages[curElementIndex] = swapElement;
        workspace.setScrollElementId(page.subpages[newElementIndex].id);
        context.refreshNavElements();
      }
    }
  };

  return {
    MoveElementDialog,
  };
};
