export const ElementTypeStoreStrings = {
  AcknowledgeLabel: "Consent",
  CloseEndedButtonLabel: "Question Tree",
  CloseEndedMultiSelectLabel: "Checkboxes",
  CloseEndedRadioButtonLabel: "Multiple Choice",
  CloseEndedDropwdownLabel: "Dropdown",
  CloseEndedChipsLabel: "Multiple Choice (buttons)",
  OpenEndedLabel: "Short Answer",
  OpenEndedMultiLineLabel: "Long Answer",
  DatePickerLabel: "Date",
  FileUploadLabel: "File Upload",
  HtmlLabel: "Import from URL",
  ImageLabel: "Image",
  ScaleLabel: "Rating Scale",
  TextLabel: "Text",
  PharmacyLabel: "Pharmacy Finder",
  PaymentLabel: "Payment Info",
};
