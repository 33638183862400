import React, { useEffect, useState, useContext } from "react";
import { useConfirmDialog } from "@intouchhealth/mfe-library";
import {
  getPageSiblings,
  getParent,
  newTemplatePage,
  parentHasBranching,
} from "../../app/stores/templateDesignStore";
import { FlowNavigatorContext } from "./FlowNavigator";
import { FlowNavigatorStrings } from "./FlowNavigatorStrings";

export const useDeletePageHandler = () => {
  const context = useContext(FlowNavigatorContext);
  const [deletedPageId, setDeletedPageId] = useState<string>();
  const [deletingLastPage, setDeletingLastPage] = useState(false);
  const { ...confirmation } = useConfirmDialog();

  const DeletePageDialog: React.VFC = () => {
    return (
      <confirmation.ModalDialog
        type="warning"
        isDelete={true}
        position="appcontainer"
        top={"25%"}
        title={FlowNavigatorStrings.DeletePageConfirmDialogTile}
        confirmButtonText={FlowNavigatorStrings.DeleteConfirmationButton}
        cancelButtonText={FlowNavigatorStrings.CancelConfirmationButton}
      >
        <div hidden={deletingLastPage}>
          {FlowNavigatorStrings.DeletePageConfirmDialogDeleteMessage}
        </div>
        <div hidden={!deletingLastPage}>
          <div>{FlowNavigatorStrings.DeletePageConfirmDialogResetMsgLine1}</div>
          <div>{FlowNavigatorStrings.DeletePageConfirmDialogResetMsgLine2}</div>
        </div>
      </confirmation.ModalDialog>
    );
  };

  context.deletePageHandler = (pageId: string) => {
    var pages = getPageSiblings(context.section(), pageId);
    if (pages) {
      setDeletingLastPage(
        pages.length < 2 && !parentHasBranching(context.section(), pageId),
      );
      context.selectPageHandler(pageId);
      confirmation.setUserConfirmed(false);
      setDeletedPageId(pageId);
      confirmation.setIsOpen(true);
    }
  };

  useEffect(() => {
    if (confirmation.userConfirmed && deletedPageId) {
      var pages = getPageSiblings(context.section(), deletedPageId);
      if (pages) {
        if (parentHasBranching(context.section(), deletedPageId)) {
          var parentPage = getParent(context.section(), deletedPageId);
          if (parentPage) {
            pages.splice(0, 1);
            context.selectPageHandler(parentPage.id);
          }
        } else {
          if (pages.length < 2 && pages[0].id === deletedPageId) {
            pages[0] = newTemplatePage();
          } else {
            var pageNdx = pages.findIndex((p) => p.id === deletedPageId);
            if (pageNdx > -1) {
              pages.splice(pageNdx, 1);
            }
          }
          context.selectPageHandler(pages[0].id);
        }
      }
      confirmation.setUserConfirmed(false);
      setDeletedPageId(undefined);
    }
    // eslint-disable-next-line
  }, [confirmation.userConfirmed, deletedPageId]);

  return {
    DeletePageDialog,
  };
};
