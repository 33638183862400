import { ApolloClient, InMemoryCache } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "./rootStore";
import { createUploadLink } from "apollo-upload-client";

export const useFileUploadClient = (uri: string | undefined) => {
  const inMemoryCache = new InMemoryCache();
  const createClient = (
    address: string | undefined = undefined,
    token: string = "",
  ) => {
    return new ApolloClient({
      link: createUploadLink({
        uri: address,
        headers: {
          authorization: "Bearer " + token,
          "GraphQL-preflight": "1",
        },
      }),
      cache: inMemoryCache,
    });
  };

  const { commonStore } = useContext(RootStoreContext);
  const [client, setClient] = useState<ApolloClient<any>>(createClient());

  const initializeClient = async () => {
    var token = await commonStore.idpToken();
    setClient(createClient(uri, token));
  };

  useEffect(() => {
    initializeClient();
    // eslint-disable-next-line
  }, []);

  return client;
};
