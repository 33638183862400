export const FlowNavigatorStrings = {
  DefaultSectionName: "Default Section",
  DefaultPageName: "Untitled page",
  DefaultPharmacyPageName: "Pharmacy",
  DefaultPaymentPageName: "Payment",
  DefaultAnswer: "Answer",
  TemplateSettingsLabel: "Form settings",
  AddPageButton: "Add page",
  EmptyElementName: "Element",
  DeleteConfirmationButton: "Delete",
  CancelConfirmationButton: "Keep",
  DeleteSectionConfirmDialogTile: "Delete Section?",
  DeleteSectionConfirmDialogDeleteMessage:
    "Section will be deleted, along with all previously added content.",
  DeleteSectionConfirmDialogResetMsgLine1:
    "The form requires at least one section.",
  DeleteSectionConfirmDialogResetMsgLine2:
    "The last section will not be deleted, but all previously added section content will be removed.",
  DeletePageConfirmDialogTile: "Delete Page?",
  DeletePageConfirmDialogDeleteMessage: "You won't be able to recover it.",
  DeletePageConfirmDialogResetMsgLine1:
    "This page can't be deleted, because a form needs at least one page.",
  DeletePageConfirmDialogResetMsgLine2:
    "But you can delete everything on the page and start fresh.",
  DeleteElementConfirmDialogTile: "Delete Element?",
  DeleteElementConfrimDialogDeleteMessage:
    "Element will be deleted from the page.",
  DeleteElementConfirmDialogResetMsgLine1:
    "Form pages require at least one element.",
  DeleteElementConfirmDialogResetMsgLine2:
    "The last element will be cleared and then a new element type will need to be selected for it.",
  DeleteElementNotAllowedTitle: "That can't be deleted",
  DeleteElementNotAllowedCloseButton: "OK",
  DeleteElementNotAllowedMessage:
    "It's the only question or content in this form, and forms need at least one question or other content.",
  DuplicateElementConfirmDialogTile: "Duplicate Element?",
  DuplicateElementConfrimDialogMessage: "Element will be duplicated.",
  DuplicateElementCopyAppendText: " (copy)",
  DuplicateElementConfirmButton: "Duplicate",
  DuplicateElementCancelButton: "Cancel",
  MovePageNotAllowedDialogTile: "Page can't be moved in that direction",
  MovePageNotAllowedDialogMessage:
    "It's already as far up or down as it can go.",
  MovePageNotAllowedCloseButton: "OK",
  MoveElementNotAllowedDialogTile: "Question can't be moved in that direction",
  MoveElementNotAllowedDialogMessage:
    "It's already as far up or down as it can go.",
  MoveElementNotAllowedCloseButton: "OK",
};
