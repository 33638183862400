import React, { useEffect, useState, useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { cloneDeep } from "@apollo/client/utilities";
import { useConfirmDialog, uniqueName } from "@intouchhealth/mfe-library";
import {
  getAttribute,
  getElement,
  getPage,
  translationChangedNotification,
} from "../../app/stores/templateDesignStore";
import { FlowNavigatorContext } from "./FlowNavigator";
import { FlowNavigatorStrings } from "./FlowNavigatorStrings";
import { FlowWorkspaceContext } from "../flowworkspace/FlowWorkspace";

export const useDuplicateElementHandler = () => {
  const context = useContext(FlowNavigatorContext);
  const workspace = useContext(FlowWorkspaceContext);
  const [refreshKey, setRefreshKey] = useState(uniqueName());
  const [duplicateParms, setDuplicateParms] = useState<{
    pageId: string;
    elementId: string;
  }>();
  const { ...confirmation } = useConfirmDialog();

  const DuplicateElementDialog: React.VFC = () => {
    return (
      <div key={refreshKey}>
        <confirmation.ModalDialog
          type="warning"
          position="appcontainer"
          top={"25%"}
          title={FlowNavigatorStrings.DuplicateElementConfirmDialogTile}
          confirmButtonText={FlowNavigatorStrings.DuplicateElementConfirmButton}
          cancelButtonText={FlowNavigatorStrings.DuplicateElementCancelButton}
        >
          {FlowNavigatorStrings.DuplicateElementConfrimDialogMessage}
        </confirmation.ModalDialog>
      </div>
    );
  };

  context.duplicateElementHandler = (pageId: string, elementId: string) => {
    var page = getPage(context.section(), pageId);
    var element = getElement(page, elementId);
    if (page) {
      if (!element) {
        setRefreshKey(uniqueName());
      } else {
        confirmation.setUserConfirmed(false);
        setDuplicateParms({ pageId, elementId });
        confirmation.setIsOpen(true);
      }
    }
  };

  useEffect(() => {
    if (confirmation.userConfirmed && duplicateParms) {
      var page = getPage(context.section(), duplicateParms?.pageId);
      var element = getElement(page, duplicateParms?.elementId);
      if (page && element) {
        var newElement = cloneDeep(element);

        // Don't copy reference category
        newElement.referenceCategory = undefined;

        // Update the IDs
        newElement.id = uuidv4();
        var prompt = getAttribute(newElement, "prompt", undefined, "");
        if (newElement.answers) {
          newElement.answers.forEach((a) => {
            a.id = uuidv4();
          });
        }

        // Append Copy to end prompt
        var updatedText =
          prompt.defaultText() +
          FlowNavigatorStrings.DuplicateElementCopyAppendText;
        prompt.setDefaultText(updatedText);

        // Append Copy to end translated prompt
        var updatedTranslations = prompt.getAllTranslations();
        updatedTranslations
          .filter((t) => t.translation.trim().length > 0)
          .forEach((t) => {
            t.translation =
              t.translation +
              FlowNavigatorStrings.DuplicateElementCopyAppendText;
          });

        // Add the new copied element and refresh
        page.subpages.push(newElement);
        setRefreshKey(uniqueName());
        workspace.setScrollElementId(newElement.id);
        context.refreshNavElements();
      }

      confirmation.setUserConfirmed(false);
      setDuplicateParms(undefined);
      translationChangedNotification.publish(null);
    }
    // eslint-disable-next-line
  }, [confirmation.userConfirmed, duplicateParms]);

  return {
    DuplicateElementDialog,
  };
};
