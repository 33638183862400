import { ApolloClient, InMemoryCache } from "@apollo/client";
import { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "./rootStore";

export const useStorageClient = (uri: string | undefined) => {
  const inMemoryCache = new InMemoryCache();
  const createClient = (
    address: string | undefined = undefined,
    token: string = "",
  ) => {
    return new ApolloClient({
      uri: address,
      headers: {
        authorization: "Bearer " + token,
      },
      cache: inMemoryCache,
    });
  };

  const { commonStore } = useContext(RootStoreContext);
  const [client, setClient] = useState<ApolloClient<any>>(createClient());

  const initializeClient = async () => {
    var token = await commonStore.idpToken();
    setClient(createClient(uri, token));
  };

  useEffect(() => {
    initializeClient();
    // eslint-disable-next-line
  }, []);

  return client;
};
