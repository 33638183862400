import { useState } from "react";
import { cloneDeep } from "@apollo/client/utilities";
import { ITemplateDesign } from "../../app/stores/templateDesignStore";
import hash from "object-hash";

export const useDynamicTemplateHasChanges = (
  currentDynamicTemplate: ITemplateDesign | undefined,
) => {
  const [originalDynamicTemplate, setOriginalDynamicTemplate] = useState<
    ITemplateDesign | undefined
  >(undefined);

  const initializeOriginalTemplate = (
    template: ITemplateDesign | undefined,
  ): void => {
    setOriginalDynamicTemplate(cloneDeep(template));
  };

  const hasChanges = (): boolean => {
    if (originalDynamicTemplate && currentDynamicTemplate) {
      const originalHash = hash(
        JSON.parse(JSON.stringify(originalDynamicTemplate)),
      );
      const updatedHash = hash(
        JSON.parse(JSON.stringify(currentDynamicTemplate)),
      );

      return originalHash !== updatedHash;
    }

    return false;
  };

  return { hasChanges, initializeOriginalTemplate };
};
