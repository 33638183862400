import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { SelectInput, uniqueName } from "@intouchhealth/mfe-library";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  initializeTemplateDesignElement,
  IPageDesign,
  getAttribute,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { OpenEndedFlowElementStrings } from "./OpenEndedFlowElementStrings";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";

export const OpenEndedFlowElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  interface IInputType {
    name: string;
    code: string;
  }

  const openEndedInputTypes = [
    {
      name: OpenEndedFlowElementStrings.InputTypeNameStandard,
      code: "STANDARD",
    },
    {
      name: OpenEndedFlowElementStrings.InputTypeNameEMail,
      code: "EMAIL",
    },
    { name: OpenEndedFlowElementStrings.InputTypeNamePhone, code: "PHONE" },
    { name: OpenEndedFlowElementStrings.InputTypeNameZipCode, code: "ZIP" },
    { name: OpenEndedFlowElementStrings.InputTypeNameNumber, code: "NUMBER" },
    {
      name: OpenEndedFlowElementStrings.InputTypeNamePassword,
      code: "PASSWORD",
    },
  ] as IInputType[];

  const element = initializeTemplateDesignElement(page, elementId, () => {
    var isValid = isPromptValid();
    return isValid;
  });

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);
  const inputTypeAttribute = getAttribute(element, "displayFormat", "STANDARD");
  const [refreshKey, setRefreshKey] = useState<string>();
  const [inputType, setInputType] = useState<Object | null>(
    (openEndedInputTypes.find(
      (i) => i.code === inputTypeAttribute.value,
    ) as Object) ?? null,
  );

  useEffect(() => {
    var inputTypeCode = (inputType as IInputType)?.code;
    if (inputTypeCode) {
      inputTypeAttribute.value = inputTypeCode;
    }
    if (!refreshKey) {
      setRefreshKey(uniqueName());
    }
    // eslint-disable-next-line
  }, [inputType]);

  return (
    <FlowElementPanel
      page={page}
      elementType="OPEN-ENDED"
      setElementType={setElementType}
      elementId={elementId}
    >
      <RowLayoutContainer>
        <PromptInput>
          <InputLayoutContainer key={refreshKey}>
            <SelectInput
              accessor="name"
              selectedValue={inputType}
              setSelectedValue={setInputType}
              optionList={openEndedInputTypes}
              label={OpenEndedFlowElementStrings.InputTypeLabel}
              helpText={OpenEndedFlowElementStrings.InputTypeHelpText}
              disabled={false}
            />
          </InputLayoutContainer>
        </PromptInput>
      </RowLayoutContainer>
    </FlowElementPanel>
  );
};

const RowLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`;

const InputLayoutContainer = styled.div`
  width: 100%;
  max-width: 275px;
  min-width: 175px;
  padding-left: 20px;
  padding-bottom: 12px;
`;
