import { ITranslationOption } from "@intouchhealth/mfe-library";

// The types currently supported for translations
export const TranslatableTypes = [
  "prompt",
  "title",
  "accessibilityLabel",
  "acknowledgeText",
  "url",
  "continueText",
  "CLOSE-ENDED:MULTI_SELECT",
  "CLOSE-ENDED:RADIO_BUTTON",
  "CLOSE-ENDED:CHIPS",
  "CLOSE-ENDED:BUTTON",
  "CLOSE-ENDED:DROPDOWN",
  "SCALE",
];

// The initial default language value
export const InitialDefaultLanguage = "en";

// Available translations options supported in the form designer.
export const TranslationOptions = [
  {
    languageName: "English",
    languageCode: "en",
    status: "DEFAULT",
    percentComplete: 0,
  },
  {
    languageName: "Spanish",
    languageCode: "es",
    status: "INACTIVE",
    percentComplete: 0,
  },
  {
    languageName: "French",
    languageCode: "fr",
    status: "INACTIVE",
    percentComplete: 0,
  },
] as ITranslationOption[];
