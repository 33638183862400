export const TemplateFormStrings = {
  DynamicTemplatesBreadCrumb: "Forms",
  PageHeaderCreate: "Creating form",
  PageHeaderUpdate: "Editing form",
  PageHeaderCopy: "Copy Form",
  PageHeaderImportJson: "Creating form",
  ButtonStartFromScratchLabel: "Build from scratch",
  ButtonImportFromJsonLabel: "Import from JSON",
  OkButtonLabel: "Ok",
  CreateButtonLabel: "Save",
  SaveButtonLabel: "Save",
  CopyButtonLabel: "Copy",
  CancelButtonLabel: "Cancel",
  DetailsSectionTitle: "Details",
  DetailsNameLabel: "Title",
  DetailsNameHelpText: "For administrative use--patients don't see it",
  DetailsTypeLabel: "Type",
  DetailsTypeHelpText: "How form can be used",
  DetailsEndActionLabel: "End screen",
  DetailsEndActionHelpText: "Appears after form's last page",
  DefaultLanguageLabel: "Default language",
  DefaultLanguageHelpText: "The language the form is built in",
  DefaultLanguageHelpText2: "You can add other languages later",
  FirstMessageDialogTitle: "Select First Message",
  FirstMessageLabel: "First message of messaging visit",
  FirstMessageHelpText:
    "The patient's answer to this question will be sent to the clinician as the initial message of the messaging visit.",
  FirstMessageErroText: "First message must be set before saving the template.",
  FlowJsonCodeLabel: "JSON code",
  FlowJsonCodeHelpText: "JSON for the template you want to import",
  FlowJsonInvalidJsonText: "Enter a valid JSON",
  ErrorAlertTitle: "Saving didn't work",
  ErrorAlertUnknownMessage:
    "It might help to check your connection and try again.",
  FetchingErrorMessage:
    "We're having trouble opening this form. Check your connection and try again.",
  SeeConsoleLogMessage:
    "If the problem persists, contact your tech support team or administrator. Tell them to look for details in the console log.",
  DeleteButtonLabel: "Delete",
  DeleteCancelButtonLabel: "Cancel",
  DeleteCloseButtonLabel: "Close",
  DeleteNoServiceLinesLabel: "None",
  DeleteConfirmationTitle: "Delete form",
  DeleteConfirmationDescription: "Deleting a form cannot be undone",
  DeleteErrorTitle: "Form can't be deleted",
  DeleteErrorDescription:
    "To delete a form, it must be removed directly from the service line/s using it.",
  DeleteErrorFormNameLabel: "Name",
  DeleteErrorServiceLinesInUse: "Service lines using this form",
  TemplateValidationErrorTitle: "Something went wrong",
  TemplateCopyOfPostfix: "(Copy)",
  TemplateValidationErrorUnexpected:
    "It might help to check your connection and try again.",
  TemplateValidationErrorSectionMinPages: "Form needs at least one page.",
  TemplateValidationErrorSectionSettings:
    "Failed to validate section settings.",
  TemplateValidationErrorPageMinElements:
    "Page needs at least one question or other content.",
  TemplateValidationErrorPageSettings:
    "It might help to check your connection and try again.",
  TemplateValidationErrorElements:
    "Make sure you've completed all required fields for each question on this page.",
  AbandonChangesConfirmDialogTile: "Leave without saving?",
  AbandonChangesConfirmDialogResetMessage: "You'll lose all unsaved changes.",
  AbandonChangesCancelButton: "Keep editing",
  AbandonChangesConfirmButton: "Leave",
  ReferenceCategoryDialogTitle: "Edit reference tag",
  MetaDataDialogTitle: "Edit Meta Data",
  DeleteButtonTooltip: "Delete form",
  PreviewButtonTooltip: "Preview form",
  PreviewCloseButtonTooltip: "Close preview",
  PreviewRestartLabel: "Restart",
  TemplateTagLabel: "Template TAG",
  TemplateTagHelpText:
    "An optional label used by developers to locate the template.",
  TemplateTagErrorLabel: "Template TAG is already in use",
};
