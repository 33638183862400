import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { DetailsSectionContext } from "./DetailsSection";
import TemplateFormError, { useTemplateError } from "./TemplateFormError";
import {
  ITemplateDesign,
  prepareOutboundTemplate,
} from "../../app/stores/templateDesignStore";
import { ConvertToDynamicTemplateType } from "./TemplateFormQueries";
import {
  ConvertToAdminTemplateType,
  useAddDynamicTemplateMutation,
} from "./TemplateFormMutations";
import { cloneDeep } from "@apollo/client/utilities";

interface AddTemplateProviderProps {
  dynamicTemplate: ITemplateDesign | undefined;
  updated: boolean;
  setUpdated: Dispatch<SetStateAction<boolean>>;
  onCreated: (templateId: string) => void;
}

export const AddTemplateProvider: React.FC<AddTemplateProviderProps> = ({
  dynamicTemplate,
  updated,
  setUpdated,
  onCreated,
}) => {
  const [addDynamicTemplateMutation, { data, loading, error, called, reset }] =
    useAddDynamicTemplateMutation();
  const { ...formError } = useTemplateError();
  const detailsContext = useContext(DetailsSectionContext);

  useEffect(() => {
    if (updated && !called) {
      // Make sure all cient side validations passed before proceeding with save otherwise open the alert and return.
      if (detailsContext.initialized && !detailsContext.isValidated) {
        setUpdated(false);
        return;
      }

      //Build the updated dynamic template and populate with changes from form sections
      if (!dynamicTemplate) {
        return;
      }

      var templateConfig = ConvertToDynamicTemplateType(dynamicTemplate);
      if (detailsContext.initialized) {
        detailsContext.updateFields(templateConfig);
      }

      var outboundTemplate = cloneDeep(dynamicTemplate);
      prepareOutboundTemplate(outboundTemplate);

      // Make call to update the dynamic template
      addDynamicTemplateMutation({
        variables: {
          input: ConvertToAdminTemplateType(
            templateConfig,
            outboundTemplate.flow,
          ),
        },
      });

      setUpdated(false);
    }
    // eslint-disable-next-line
  }, [updated, called]);

  useEffect(() => {
    if (called && (!loading || error)) {
      if (formError.hasErrors(error, data?.addDynamicTemplate?.errors)) {
        reset();
      } else {
        reset();
        var newTemplateId = data?.addDynamicTemplate?.template?.templateId;
        onCreated(newTemplateId);
      }
    }
    // eslint-disable-next-line
  }, [data, loading, error, called, reset]);

  return (
    <TemplateFormError
      isErrorAlertOpen={formError.isErrorAlertOpen}
      setIsErrorAlertOpen={formError.setIsErrorAlertOpen}
      alertMessages={formError.alertMessages}
    ></TemplateFormError>
  );
};
