export const ImageManagerDialogStrings = {
  UsedInTemplateLabel: "This image is used in a template.",
  KeepImageLabel: "Keep it in the Gallery",
  ReplaceImageLabel: "Replace Image",
  DeleteImageLabel: "Delete Image",
  DragAndDropLabel: "Drag and drop image here or %browse files%",
  SupportedFileTypesLabel: "JPEG, PNG, TIFF or GIF images only",
  UploadedLabel: "Uploaded",
  UrlUploadedLabel: "URL Loaded",
  UploadFailedLabel: "Failed to upload",
  DeleteFailedLabel: "Failed to delete the image",
  DeleteFailedButtonLabel: "Go Back",
  AddImageModalHeader: "Add Image",
  AddImageButtonLabel: "Add Image",
  CancelButtonLabel: "Cancel",
  UrlUploadLabel: "URL where the image is located",
  UrlUploadHelpTextLine1:
    "If your URL is correct, you'll see an image preview here.",
  UrlUploadInfoTextLabel: "URL images are not being saved to the gallery.",
  UrlUploadHelpTextLine2: "Large images may take a few minutes to appear.",
  UrlInvalidLabel: "Invalid URL",
  UrlNoImageLabel: "The URL is not linked to an image",
  GalleryTabLabel: "Choose from gallery",
  UploadTabLabel: "Upload file",
  UrlTabLabel: "Get from URL",
};
