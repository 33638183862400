import { FC } from "react";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  initializeElementAnswers,
  initializeTemplateDesignElement,
  IPageDesign,
  setAttributeValue,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";
import { useAnswersComponent } from "../../flowelementcomponents/answerscomponent/AnswersComponent";

export const SingleSelectElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
  elementType: string;
}> = ({ page, setElementType, elementId, elementType }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    return isPromptValid() && answersValidated();
  });

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);
  const { MultiSelectAnswers, answersValidated } = useAnswersComponent(
    initializeElementAnswers(element),
    2,
  );

  setAttributeValue(
    element,
    "displayFormat",
    elementType.replace("CLOSE-ENDED:", ""),
  );

  return (
    <FlowElementPanel
      page={page}
      elementType={elementType as TemplateItemTypes}
      setElementType={setElementType}
      elementId={elementId}
      requiredDefault={elementType === "CLOSE-ENDED:CHIPS" ? true : false}
    >
      <PromptInput />
      <MultiSelectAnswers elementId={elementId} />
    </FlowElementPanel>
  );
};
