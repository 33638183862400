import { useContext, useEffect, useState } from "react";
import {
  RequiredInputIndicator,
  SelectInput,
  useFormDialog,
} from "@intouchhealth/mfe-library";
import { TemplateFormStrings } from "./TemplateFormStrings";
import styled from "styled-components";
import {
  findAsyncMessageElements,
  IAsyncMessageElements,
} from "../../app/stores/templateDesignStore";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";

export interface IUpdatedTemplateDetails {
  name: string;
  type: string;
  endAction: string;
}

export const useFirstMessageDialog = (
  onFirstMessageSet: (id: string | undefined) => void,
) => {
  const context = useContext(FlowNavigatorContext);
  const [validationFailed, setValidationFailed] = useState(false);
  const { ...form } = useFormDialog(
    TemplateFormStrings.FirstMessageDialogTitle,
    TemplateFormStrings.OkButtonLabel,
    TemplateFormStrings.CancelButtonLabel,
  );

  const openDialog = (showValidationError: boolean = false) => {
    setValidationFailed(showValidationError);
    form.setIsOpen(true);
  };

  const handleAccepted = (
    selectedMessage: IAsyncMessageElements | undefined,
  ) => {
    onFirstMessageSet(selectedMessage?.element?.id);
    form.setIsOpen(false);
  };

  const handleCancelled = () => {
    form.setIsOpen(false);
  };

  const ModalDialog: React.FC = () => {
    const [selectMessageOptions, setSelectMessageOptions] = useState<
      IAsyncMessageElements[]
    >([]);
    const [selectedMessage, setSelectedMessage] = useState<any>();

    useEffect(() => {
      var options = findAsyncMessageElements(context.sections);
      setSelectMessageOptions(options);
      setSelectedMessage(options.find((o) => o.isFirstMessage));
      // eslint-disable-next-line
    }, [form.isOpen]);

    return (
      <form.FormDialog
        position="appcontainer"
        top={40}
        width={"75%"}
        onAccepted={() => handleAccepted(selectedMessage)}
        onCancelled={handleCancelled}
        acceptDisabled={!selectedMessage}
      >
        <RequiredInputIndicator>
          <SelectInputStyle
            color={validationFailed && !selectedMessage ? "#CB220E" : "#ADA8A4"}
            borderColor={
              validationFailed && !selectedMessage ? "#CB220E" : "#DED9D6"
            }
          >
            <SelectInput
              accessor="question"
              selectedValue={selectedMessage}
              setSelectedValue={setSelectedMessage}
              optionList={selectMessageOptions}
              label={TemplateFormStrings.FirstMessageLabel}
              helpText={
                validationFailed && !selectedMessage
                  ? TemplateFormStrings.FirstMessageErroText
                  : TemplateFormStrings.FirstMessageHelpText
              }
              disabled={false}
            />
          </SelectInputStyle>
        </RequiredInputIndicator>
      </form.FormDialog>
    );
  };

  return {
    ModalDialog,
    openDialog,
  };
};

export const DialogLayout = styled.div`
  .sls-input {
    justify-content: start;
    text-align: left;
    margin-bottom: 10px;
    & input {
      height: inherit;
      line-height: normal;
      background-color: #ffffff;
    }
  }
  .MuiOutlinedInput-root {
    background-color: #ffffff;
  }
  .MuiOutlinedInput-notchedOutline {
    left: auto;
    margin-left: -250px;
  }
`;

export const SelectInputStyle = styled.div<{
  borderColor: string;
  color: string;
}>`
  .MuiOutlinedInput-root {
    border-color: ${({ borderColor }: { borderColor: string }) => borderColor};
  }
  span:last-of-type {
    color: ${({ color }: { color: string }) => color};
  }
`;
