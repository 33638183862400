import { v4 as uuidv4 } from "uuid";
import { FlowNavigatorStrings } from "../../features/flownavigator/FlowNavigatorStrings";
import { TemplateFormStrings } from "../../features/templatesform/TemplateFormStrings";
import { TemplateItemTypes } from "./TemplateItemTypes";
import { TranslatableTypes } from "./translationOptionsStore";
import {
  ITranslationOption,
  NotificationProperty,
} from "@intouchhealth/mfe-library";

export interface ITransLatedText {
  languageCode: string;
  translation: string;
}

export interface IAttribute {
  type: string;
  value: string;
  defaultText: () => string;
  setDefaultText: (text: string) => void;
  translatedText: ITransLatedText[] | undefined;
  getAllTranslations: () => ITransLatedText[];
  getTranslationText: (languageCode: string) => string;
  setTranslationText: (languageCode: string, text: string) => void;
  removeTranslationText: (languageCode: string) => void;
  getAttributeInstance: () => IAttribute;
}

export interface ITranslationData {
  languageCode: string;
  translationPercentage: number;
}

export const ConvertToTranslationData = (
  translations: ITranslationOption[],
) => {
  return translations
    .filter((t) => t.status === "ACTIVE")
    .map((t) => {
      return {
        languageCode: t.languageCode,
        translationPercentage: t.percentComplete,
      };
    }) as ITranslationData[];
};

export interface ITemplateDesign {
  templateId: string;
  organizationId: string;
  practiceId: string;
  name: string;
  tag: ITag | null;
  type: string;
  endAction: string;
  defaultLanguageCode: string;
  translations: ITranslationData[];
  flow: {
    sections: ISectionDesign[];
  };
}

export interface ISectionDesign {
  id: string;
  name: string;
  referenceCategory: string;
  pages: IPageDesign[];
  isValid: () => boolean;
}

export interface ILink {
  translatedLinkText: ITransLatedText[] | undefined;
  linkUrl: string;
}

export interface IPageDesign {
  id: string;
  type: TemplateItemTypes | undefined;
  translatedPageText: ITransLatedText[] | undefined;
  referenceCategory: string;
  attributes: IAttribute[];
  answers?: IAnswer[];
  links?: ILink[];
  subpages: IElementDesign[];
  allowSkip: string | undefined;
  isValid: () => boolean;
}

export interface IAnswer {
  id: string;
  translatedAnswerText: ITransLatedText[] | undefined;
  pages: IPageDesign[];
  referenceCategory: string;
  metaData: string;
}

export interface ITag {
  tagName: string;
}

export interface IElementDesign {
  id: string;
  type: TemplateItemTypes | undefined;
  translatedPageText: ITransLatedText[] | undefined;
  referenceCategory: string | undefined;
  attributes: IAttribute[];
  answers?: IAnswer[];
  links?: ILink[];
  isValid: () => boolean;
}

const defaultLanguageCode = "default";

export const getPageText = (
  page: IPageDesign | IElementDesign,
  langaugeCode: string,
) => {
  return (
    page.translatedPageText?.find((t) => t.languageCode === langaugeCode)
      ?.translation ?? ""
  );
};

export const getDefaultPageText = (page: IPageDesign | IElementDesign) => {
  return getPageText(page, defaultLanguageCode);
};

export const setPageText = (
  page: IPageDesign | IElementDesign,
  text: string,
  languageCode: string,
) => {
  var trans = page.translatedPageText?.find(
    (t) => t.languageCode === languageCode,
  );
  if (!trans) {
    trans = {
      translation: text,
      languageCode: languageCode,
    } as ITransLatedText;
    if (!page.translatedPageText) {
      page.translatedPageText = [trans] as ITransLatedText[];
    } else {
      page.translatedPageText.push(trans);
    }
  } else {
    trans.translation = text;
  }
};

export const setDefaultPageText = (
  page: IPageDesign | IElementDesign,
  text: string,
) => {
  setPageText(page, text, defaultLanguageCode);
};

export const getPageTextTranslations = (page: IPageDesign | IElementDesign) => {
  return (
    page.translatedPageText?.filter(
      (t) => t.languageCode !== defaultLanguageCode,
    ) || ([] as ITransLatedText[])
  );
};

export const getLinkText = (link: ILink, languageCode: string) => {
  return (
    link.translatedLinkText?.find((t) => t.languageCode === languageCode)
      ?.translation ?? ""
  );
};

export const getDefaultLinkText = (link: ILink) => {
  return getLinkText(link, defaultLanguageCode);
};

export const setLinkText = (
  link: ILink,
  text: string,
  languageCode: string,
) => {
  var trans = link.translatedLinkText?.find(
    (t) => t.languageCode === languageCode,
  );
  if (!trans) {
    trans = {
      translation: text,
      languageCode: languageCode,
    } as ITransLatedText;
    if (!link.translatedLinkText) {
      link.translatedLinkText = [trans] as ITransLatedText[];
    } else {
      link.translatedLinkText.push(trans);
    }
  } else {
    trans.translation = text;
  }
};

export const setDefaultLinkText = (link: ILink, text: string) => {
  setLinkText(link, text, defaultLanguageCode);
};

const getDefaultTranslation = (attribute: IAttribute) => {
  return (
    attribute.translatedText?.find(
      (t) => t.languageCode === defaultLanguageCode,
    )?.translation ?? ""
  );
};

const setDefaultTranslation = (attribute: IAttribute, text: string) => {
  var defaultTrans = attribute.translatedText?.find(
    (t) => t.languageCode === defaultLanguageCode,
  );
  if (!defaultTrans) {
    defaultTrans = {
      translation: text,
      languageCode: defaultLanguageCode,
    } as ITransLatedText;
    if (!attribute.translatedText) {
      attribute.translatedText = [defaultTrans] as ITransLatedText[];
    } else {
      attribute.translatedText.push(defaultTrans);
    }
  } else {
    defaultTrans.translation = text;
  }
};

const getTranslation = (attribute: IAttribute, languageCode: string) => {
  return (
    attribute.translatedText?.find((t) => t.languageCode === languageCode)
      ?.translation ?? ""
  );
};

const getAllTranslations = (attribute: IAttribute) => {
  return (
    attribute.translatedText?.filter(
      (t) => t.languageCode !== defaultLanguageCode,
    ) || ([] as ITransLatedText[])
  );
};

export const translationChangedNotification = new NotificationProperty();

const setTranslation = (
  attribute: IAttribute,
  languageCode: string,
  text: string,
) => {
  var translation = attribute.translatedText?.find(
    (t) => t.languageCode === languageCode,
  );
  if (!translation) {
    translation = {
      translation: text,
      languageCode: languageCode,
    } as ITransLatedText;
    if (!attribute.translatedText) {
      attribute.translatedText = [translation] as ITransLatedText[];
    } else {
      attribute.translatedText.push(translation);
    }
    translationChangedNotification.publish(null);
  } else {
    var origLength = translation.translation?.length ?? 0;
    var newLength = text?.length ?? 0;
    translation.translation = text;
    if (
      (origLength === 0 && newLength > 0) ||
      (newLength === 0 && origLength > 0)
    ) {
      translationChangedNotification.publish(null);
    }
  }
};

const removeTranslation = (attribute: IAttribute, languageCode: string) => {
  if (attribute && attribute.translatedText) {
    var index =
      attribute.translatedText?.findIndex(
        (t) => t.languageCode === languageCode,
      ) ?? -1;
    if (index > -1) {
      attribute.translatedText.splice(index, 1);
    }
  }
};

const initDefaultTextHandlers = (attribute: IAttribute) => {
  if (
    !attribute.getAttributeInstance ||
    attribute.getAttributeInstance() !== attribute
  ) {
    attribute.defaultText = () => getDefaultTranslation(attribute);
    attribute.setDefaultText = (text: string) =>
      setDefaultTranslation(attribute, text);
    attribute.getAllTranslations = () => getAllTranslations(attribute);
    attribute.getTranslationText = (languageCode: string) =>
      getTranslation(attribute, languageCode);
    attribute.setTranslationText = (languageCode: string, text: string) =>
      setTranslation(attribute, languageCode, text);
    attribute.removeTranslationText = (languageCode: string) =>
      removeTranslation(attribute, languageCode);
    attribute.getAttributeInstance = () => {
      return attribute;
    };
  }
};

export const getDefaultAnswerText = (answer: IAnswer) => {
  return getTranslatedAnswerText(answer, defaultLanguageCode);
};

export const getTranslatedAnswerText = (
  answer: IAnswer,
  languageCode: string,
) => {
  return (
    answer.translatedAnswerText?.find((t) => t.languageCode === languageCode)
      ?.translation ?? ""
  );
};

export const setDefaultAnswerText = (answer: IAnswer, text: string) => {
  setTranslatedAnswerText(answer, defaultLanguageCode, text);
};

export const setTranslatedAnswerText = (
  answer: IAnswer,
  languageCode: string,
  text: string,
) => {
  var existingTans = answer.translatedAnswerText?.find(
    (t) => t.languageCode === languageCode,
  );
  if (!existingTans) {
    existingTans = {
      translation: text,
      languageCode: languageCode,
    } as ITransLatedText;
    if (!answer.translatedAnswerText) {
      answer.translatedAnswerText = [existingTans] as ITransLatedText[];
    } else {
      answer.translatedAnswerText.push(existingTans);
    }
    translationChangedNotification.publish(null);
  } else {
    var origLength = existingTans.translation?.length ?? 0;
    var newLength = text?.length ?? 0;
    existingTans.translation = text;
    if (
      (origLength === 0 && newLength > 0) ||
      (newLength === 0 && origLength > 0)
    ) {
      translationChangedNotification.publish(null);
    }
  }
};

const removeAnswerTranslation = (answer: IAnswer, languageCode: string) => {
  if (answer && answer.translatedAnswerText) {
    var index =
      answer.translatedAnswerText?.findIndex(
        (t) => t.languageCode === languageCode,
      ) ?? -1;
    if (index > -1) {
      answer.translatedAnswerText.splice(index, 1);
    }
  }
};

export const setAttributeValue = (
  parent: IPageDesign | IElementDesign | undefined,
  attributeType: string,
  value: string,
) => {
  var attribute = getAttribute(parent, attributeType, value);
  attribute.value = value;
};

export const setAttributeText = (
  parent: IPageDesign | IElementDesign | undefined,
  attributeType: string,
  text: string,
) => {
  var attribute = getAttribute(parent, attributeType, undefined, text);
  attribute.setDefaultText(text);
};

export const getAttribute = (
  parent: IPageDesign | IElementDesign | undefined,
  attributeType: string,
  defaultValue: string | undefined,
  defaultText: string | undefined = undefined,
) => {
  var attribute = parent?.attributes?.find((a) => a.type === attributeType);
  if (attribute) {
    initDefaultTextHandlers(attribute);
  } else {
    attribute = createAttribute(
      parent,
      attributeType,
      defaultValue,
      defaultText,
    );
  }
  return attribute;
};

export const tryAttribute = (
  parent: IPageDesign | IElementDesign | undefined,
  attributeType: string,
) => {
  var attribute = parent?.attributes?.find((a) => a.type === attributeType);
  if (attribute) {
    initDefaultTextHandlers(attribute);
  }
  return attribute;
};

export const createAttribute = (
  parent: IPageDesign | IElementDesign | undefined,
  attributeType: string,
  defaultValue: string | undefined,
  defaultText: string | undefined = undefined,
) => {
  var translatedText = defaultText
    ? ([
        {
          languageCode: defaultLanguageCode,
          translation: defaultText,
        } as ITransLatedText,
      ] as ITransLatedText[])
    : undefined;

  var attribute = {
    type: attributeType,
    value: defaultValue,
    translatedText: translatedText,
  } as IAttribute;
  initDefaultTextHandlers(attribute);

  if (parent) {
    if (!parent.attributes) {
      parent.attributes = [attribute];
    } else {
      parent.attributes.push(attribute);
    }
  }
  return parent?.attributes?.find((a) => a.type === attributeType) ?? attribute;
};

export const deleteAttribute = (
  parent: IPageDesign | IElementDesign | undefined,
  attributeType: string,
) => {
  if (parent?.attributes) {
    var attributeNdx = parent.attributes.findIndex(
      (a) => a.type === attributeType,
    );
    if (attributeNdx > -1) {
      parent.attributes.splice(attributeNdx, 1);
    }
  }
};

export const newTemplateDesign = () => {
  return {
    templateId: uuidv4(),
    flow: {
      sections: [newTemplateSection()] as ISectionDesign[],
    },
  } as ITemplateDesign;
};

export const newTemplateSection = () => {
  return {
    id: uuidv4(),
    name: FlowNavigatorStrings.DefaultSectionName,
    referenceCategory: "",
    pages: [newTemplatePage()] as IPageDesign[],
  } as ISectionDesign;
};

export const newTemplatePage = () => {
  var page = {
    id: uuidv4(),
    type: "MULTI-FIELD",
    translatedPageText: [] as ITransLatedText[],
    attributes: [] as IAttribute[],
    referenceCategory: "",
    subpages: [] as IElementDesign[],
  } as IPageDesign;
  createAttribute(
    page,
    "title",
    undefined,
    FlowNavigatorStrings.DefaultPageName,
  );
  return page;
};

export const newComplexPage = (type: TemplateItemTypes) => {
  var page = {
    id: uuidv4(),
    type,
    referenceCategory: "",
  } as IPageDesign;
  return page;
};

export const newTemplateAnswer = (defaultText: string = "") => {
  var answer = { id: uuidv4() } as IAnswer;
  setDefaultAnswerText(answer, defaultText);
  return answer;
};

export const newTemplateAnswers = (defaultText: string = "") => {
  return [newTemplateAnswer(defaultText)] as IAnswer[];
};

export const newBranchedAnswer = () => {
  var answer = newTemplateAnswer(FlowNavigatorStrings.DefaultAnswer);
  answer.pages = [newTemplatePage()] as IPageDesign[];
  return answer;
};

export const initializeElementAnswers = (element: IElementDesign) => {
  if (!element.answers) {
    element.answers = newTemplateAnswers();
  }
  return element.answers;
};

export const initializeTemplateDesignElement = (
  page: IPageDesign,
  elementId: string,
  validation: () => boolean,
): IElementDesign => {
  var element = page.subpages.find((e) => e.id === elementId);
  if (!element) {
    element = {} as IElementDesign;
  }
  element.isValid = validation;
  return element;
};

export const getParent = (
  section: ISectionDesign,
  pageId: string,
): IPageDesign | undefined => {
  var pageInfo = getPageRefs(section, pageId);
  if (pageInfo?.parent?.type === "CLOSE-ENDED:BUTTON") {
    pageInfo = getPageRefs(section, pageInfo.parent.id);
  }
  return pageInfo?.parent;
};

export const pageIsFirstSibling = (
  section: ISectionDesign,
  pageId: string,
): boolean => {
  var siblings = getPageSiblings(section, pageId);
  if (siblings && siblings.length > 0 && siblings[0].id === pageId) {
    return true;
  }
  return false;
};

export const getPageSiblings = (
  section: ISectionDesign,
  pageId: string,
): IPageDesign[] | undefined => {
  var parent = getPageRefs(section, pageId)?.parent;
  if (section?.pages?.find((p) => p.id === pageId)) {
    return section.pages;
  }
  return (
    parent?.answers?.find((a) => a.pages?.find((p) => p.id === pageId))
      ?.pages ?? undefined
  );
};

export const getPageRefs = (
  section: ISectionDesign | undefined,
  pageId: string | undefined,
): IFindPageInPages | undefined => {
  // First try to find a  root page
  var result: IFindPageInPages | undefined;
  if (pageId && section?.pages) {
    for (var pi = 0; pi < section?.pages.length; pi++) {
      var page = section.pages[pi];
      result = findPageInPages([page], pageId, page);
      if (result) break;
    }
  }
  return result;
};

export const getRootPage = (
  section: ISectionDesign,
  pageId: string,
): IPageDesign | undefined => {
  var result: IPageDesign | undefined;
  if (pageId && section?.pages) {
    for (var pi = 0; pi < section?.pages.length; pi++) {
      var page = section.pages[pi];
      if (findPageInPages([page], pageId, page)) {
        result = page;
        break;
      }
    }
  }
  return result;
};

interface IFindPageInPages {
  parent: IPageDesign | undefined;
  page: IPageDesign | undefined;
}
const findPageInPages = (
  pages: IPageDesign[] | undefined,
  pageId: string,
  parent: IPageDesign | undefined,
): IFindPageInPages | undefined => {
  var result: IFindPageInPages | undefined;
  if (pages) {
    for (var pi = 0; pi < pages.length; pi++) {
      if (pages[pi].id === pageId) {
        result = { parent, page: pages[pi] };
        break;
      }
      var page = pages[pi];
      var answers = page.answers;
      if (answers) {
        for (var ai = 0; ai < answers.length; ai++) {
          result = findPageInPages(answers[ai].pages, pageId, page);
          if (result) break;
        }
      }
      if (!result && page.subpages) {
        result = findPageInPages(page.subpages as IPageDesign[], pageId, page);
      }
      if (result) {
        break;
      }
    }
  }
  return result;
};

export const getPage = (
  section: ISectionDesign | undefined,
  pageId: string | undefined,
): IPageDesign | undefined => {
  var pageInfo = getPageRefs(section, pageId);
  return pageInfo?.page;
};

export const getElement = (
  page: IPageDesign | undefined,
  elementId: string | undefined,
) => {
  return page?.subpages?.find((e) => e.id === elementId);
};

export const pageHasBranching = (page: IPageDesign): boolean => {
  var result =
    page.type === "CLOSE-ENDED:BUTTON" ||
    (page.subpages?.length > 0 &&
      page.subpages[0].type === "CLOSE-ENDED:BUTTON");
  return result;
};

export const getBranchAnswers = (page: IPageDesign) => {
  if (
    page.subpages?.length > 0 &&
    page.subpages[0].type === "CLOSE-ENDED:BUTTON"
  ) {
    return page.subpages[0].answers;
  } else if (page.type === "CLOSE-ENDED:BUTTON") {
    return page.answers;
  } else {
    return [] as IAnswer[];
  }
};

export const getBranchPageInfo = (
  section: ISectionDesign,
  pageId: string,
):
  | {
      parent: IPageDesign;
      siblings: IPageDesign[];
      parentTitle: string;
      parentAnswerText: string;
    }
  | undefined => {
  var branchPage = getPageRefs(section, pageId)?.parent;
  if (!branchPage || !pageHasBranching(branchPage)) {
    return undefined;
  }

  var branchFromPage = getPageRefs(section, branchPage.id)?.parent;
  if (!branchFromPage || (branchFromPage.subpages?.length ?? 0) < 1) {
    /* istanbul ignore next line */
    return undefined;
  }

  var parentAnswer = branchPage.answers?.find(
    (a) => a.pages && a.pages.length > 0 && a.pages[0].id === pageId,
  );
  if (!parentAnswer) {
    return undefined;
  }

  return {
    parent: branchFromPage,
    siblings: parentAnswer.pages,
    parentTitle: tryAttribute(branchFromPage, "title")?.defaultText() ?? "",
    parentAnswerText: getDefaultAnswerText(parentAnswer),
  };
};

export const parentHasBranching = (section: ISectionDesign, pageId: string) => {
  var result = false;
  var rootPage = getRootPage(section, pageId);
  if (rootPage?.id !== pageId) {
    var parentPage = getPageRefs(section, pageId)?.parent;
    if (parentPage) {
      result = pageHasBranching(parentPage);
    }
  }
  return result;
};

export const pageAllowsContinueText = (page: IPageDesign) => {
  if (pageIsComplex(page)) {
    return false;
  }

  var result = true;
  if (pageHasBranching(page)) {
    var attributeLocation: IPageDesign | IElementDesign | undefined;
    /* istanbul ignore next line */
    if (
      page.subpages?.length > 0 &&
      page.subpages[0].type === "CLOSE-ENDED:BUTTON"
    ) {
      attributeLocation = page.subpages[0];
    } else {
      /* istanbul ignore next line */
      attributeLocation = page;
    }
    result = tryAttribute(attributeLocation, "allowSkip")?.value === "true";
  }
  return result;
};

export const getComplexPageTitle = (page: IPageDesign) => {
  if (page.type === "PHARMACY") {
    return FlowNavigatorStrings.DefaultPharmacyPageName;
  }

  if (page.type === "PAYMENT") {
    return FlowNavigatorStrings.DefaultPaymentPageName;
  }

  return undefined;
};

export const pageIsComplex = (page: IPageDesign) => {
  return page.type === "PHARMACY" || page.type === "PAYMENT";
};

export const insertPage = (
  section: ISectionDesign,
  pageId: string,
  complexPage: IPageDesign,
) => {
  if (section) {
    const siblings = getPageSiblings(section, pageId);
    const page = getPage(section, pageId);

    if (siblings) {
      const newPage = { ...complexPage };
      const pageIndex = siblings.findIndex((p) => p.id === page?.id);
      const replace = (page?.subpages?.length ?? 0) === 0;

      siblings.splice(
        replace ? pageIndex : pageIndex + 1,
        replace ? 1 : 0,
        newPage,
      );
    }
  }
};

export const prepareInboundTemplate = (template: ITemplateDesign) => {
  template.flow.sections.forEach((s) =>
    s.pages.forEach((p) => expandSingleIElementsubpages(p)),
  );
};

const convertToSpecialPageTypes = (page: IPageDesign | IElementDesign) => {
  var displayFormatAttribute = tryAttribute(page, "displayFormat");
  if (page.type === "CLOSE-ENDED" && displayFormatAttribute) {
    page.type = ("CLOSE-ENDED:" +
      displayFormatAttribute.value) as TemplateItemTypes;
  }
  if (
    page.type === "OPEN-ENDED" &&
    displayFormatAttribute?.value === "MULTI_LINE"
  ) {
    page.type = "OPEN-ENDED:MULTI_LINE";
  }
};

// Recursive function that takes pages with a single element and moves them to sub pages that designer can work with.
const expandSingleIElementsubpages = (page: IPageDesign) => {
  convertToSpecialPageTypes(page);
  page.attributes?.forEach((a) => initDefaultTextHandlers(a));

  if (
    (page.subpages?.length ?? 0) === 0 &&
    page.type !== "MULTI-FIELD" &&
    !pageIsComplex(page)
  ) {
    page.subpages = [
      {
        id: uuidv4(),
        type: page.type,
        translatedPageText: page.translatedPageText,
        attributes: [] as IAttribute[],
      } as IElementDesign,
    ] as IElementDesign[];
    page.subpages[0].attributes = page.attributes?.filter(
      (a) => a.type !== "title" && a.type !== "continueText",
    );
    page.attributes = page.attributes?.filter(
      (a) => a.type === "title" || a.type === "continueText",
    );
    page.subpages[0].answers = page.answers;
    page.answers = undefined;
    page.subpages[0].links = page.links;
    page.links = undefined;
    page.type = "MULTI-FIELD";
    if (page.referenceCategory === asyncFirstMessageTag) {
      page.subpages[0].referenceCategory = asyncFirstMessageTag;
      page.referenceCategory = "";
    }
  }
  page.subpages?.forEach((sp) => {
    convertToSpecialPageTypes(sp);
    sp.answers?.forEach((a) =>
      a.pages?.forEach((p) => expandSingleIElementsubpages(p)),
    );
  });
  page.answers?.forEach((a) =>
    a.pages?.forEach((p) => expandSingleIElementsubpages(p)),
  );
};

export const prepareOutboundTemplate = (template: ITemplateDesign) => {
  template.flow.sections.forEach((s) =>
    s.pages.forEach((p) => reduceSingleIElementsubpages(p)),
  );
};

const convertFromSpecialPageTypes = (page: IPageDesign | IElementDesign) => {
  if (page.type?.toString().startsWith("CLOSE-ENDED:")) {
    page.type = "CLOSE-ENDED";
  }
  if (page.type?.toString().startsWith("OPEN-ENDED:")) {
    page.type = "OPEN-ENDED";
  }
  if (
    page.type === "DATE-PICKER" &&
    tryAttribute(page, "restriction")?.value === "NO_RESTRICTIONS"
  ) {
    deleteAttribute(page, "restriction");
  }

  // Delete empty prompt attribute
  var promptAttribute = tryAttribute(page, "prompt");
  if (
    promptAttribute &&
    promptAttribute.defaultText() === "" &&
    (promptAttribute.translatedText?.length ?? 0) === 0
  ) {
    /* istanbul ignore next line */
    deleteAttribute(page, "prompt");
  }
};

// Recursive function that takes single element sub pages and puts it on the page.
const reduceSingleIElementsubpages = (page: IPageDesign) => {
  if (
    page.subpages?.length === 1 &&
    !(
      page.referenceCategory?.length > 0 &&
      page.subpages[0].referenceCategory === asyncFirstMessageTag
    )
  ) {
    page.type = page.subpages[0].type;
    page.translatedPageText = page.subpages[0].translatedPageText;
    if (page.subpages[0].attributes?.length > 0) {
      page.attributes =
        page.attributes?.filter(
          (a) => a.type === "title" || a.type === "continueText",
        ) ?? ([] as IAttribute[]);
      page.subpages[0].attributes.forEach((a) => page.attributes.push(a));
    }
    if (page.subpages[0].answers) {
      page.answers = page.subpages[0].answers;
    }
    if (page.subpages[0].links) {
      page.links = page.subpages[0].links;
    }
    if (page.subpages[0].referenceCategory === asyncFirstMessageTag) {
      page.referenceCategory = asyncFirstMessageTag;
    }
    page.subpages = [] as IElementDesign[];
  } else if (page.subpages) {
    page.subpages.forEach((element) => {
      convertFromSpecialPageTypes(element);
    });
  }
  if (page.answers) {
    for (var ai = 0; ai < page.answers.length; ai++) {
      if (page.answers[ai].pages) {
        for (var pi = 0; pi < page.answers[ai].pages.length; pi++) {
          reduceSingleIElementsubpages(page.answers[ai].pages[pi]);
        }
      }
    }
  }
  convertFromSpecialPageTypes(page);
};

const asyncFirstMessageTag = "ASYNC_INTAKE_MSG";
const asyncFirstMessageTemplateTypes = ["SHARED_RAV_PREEXAM_ASYNC"];
const asyncFirstMessageQuestionTypes = [
  "OPEN-ENDED",
  "OPEN-ENDED:MULTI_LINE",
] as TemplateItemTypes[];

// Flag will allow selection of first message from branches if needed in future.
// For now this is only turned on for unit tests.
var asyncFirstMessageAllowBranches = false;
export const SetAsyncFirstMessageAllowBranches = (value: boolean) => {
  asyncFirstMessageAllowBranches = value;
};

export interface IAsyncMessageElements {
  element: IElementDesign;
  question: string;
  isFirstMessage: boolean;
}
export const isAsyncMessageTemplateType = (
  type: string | TemplateItemTypes | undefined,
) => {
  if (type) {
    return asyncFirstMessageTemplateTypes.includes(type);
  } else {
    return false;
  }
};

export const findAsyncMessageElements = (sections: ISectionDesign[]) => {
  var asyncElements: IAsyncMessageElements[] = [];
  sections.forEach((s) =>
    s.pages.forEach((p) =>
      findAsyncMessageElementsInSubpages(p, asyncElements),
    ),
  );
  return asyncElements;
};

// Recursive function to walk subpages finding elements that can be used for first async message templates
const findAsyncMessageElementsInSubpages = (
  page: IPageDesign,
  asyncElements: IAsyncMessageElements[],
) => {
  if (page.type && asyncFirstMessageQuestionTypes.includes(page.type)) {
    asyncElements.push({
      element: page as IElementDesign,
      question: tryAttribute(page, "prompt")?.defaultText() ?? "",
      isFirstMessage:
        page.referenceCategory?.includes(asyncFirstMessageTag) ?? false,
    });
  }

  if (page.subpages) {
    page.subpages.forEach((sp) => {
      findAsyncMessageElementsInSubpages(sp as IPageDesign, asyncElements);
    });
  }

  if (asyncFirstMessageAllowBranches && page.answers) {
    for (var ai = 0; ai < page.answers.length; ai++) {
      if (page.answers[ai].pages) {
        for (var pi = 0; pi < page.answers[ai].pages.length; pi++) {
          findAsyncMessageElementsInSubpages(
            page.answers[ai].pages[pi],
            asyncElements,
          );
        }
      }
    }
  }
};

export const findAsyncFirstMessageElement = (sections: ISectionDesign[]) => {
  return findAsyncMessageElements(sections).find(
    (e) => e.isFirstMessage === true,
  )?.element;
};

export const clearAsyncFirstMessageElement = (sections: ISectionDesign[]) => {
  var element = findAsyncFirstMessageElement(sections);
  if (element) {
    element.referenceCategory = undefined;
  }
};

export const setAsyncFirstMessageElement = (
  sections: ISectionDesign[],
  id: string | undefined,
) => {
  // If first element already set to different id then clear it
  var firstElement = findAsyncFirstMessageElement(sections);
  if (firstElement && firstElement.id !== id) {
    firstElement.referenceCategory = undefined;
  }
  // If id is set and does not match one that was previously set then set then set as first async message
  if (firstElement?.id !== id) {
    var element = findAsyncMessageElements(sections).find(
      (e) => e.element.id === id,
    )?.element;
    if (element) {
      element.referenceCategory = asyncFirstMessageTag;
    }
  }
};

export const elementHasAsyncFirstMessage = (
  page: IPageDesign | IElementDesign | undefined,
) => {
  if (
    page?.type &&
    page?.referenceCategory &&
    asyncFirstMessageQuestionTypes.includes(page?.type) &&
    page?.referenceCategory.includes(asyncFirstMessageTag)
  ) {
    return true;
  }
  return false;
};

interface ITemplateValidation {
  errorMessage: string;
  sectionId: string;
  pageId: string;
}

export const validateTemplateDesign = (
  template: ITemplateDesign | undefined,
) => {
  var validationResults = [] as ITemplateValidation[];

  if (!template) {
    validationResults.push({
      errorMessage: TemplateFormStrings.TemplateValidationErrorUnexpected,
      sectionId: "",
      pageId: "",
    });
    return validationResults;
  }

  template.flow.sections.forEach((s) => {
    if (s.isValid && !s.isValid()) {
      validationResults.push({
        errorMessage:
          TemplateFormStrings.TemplateValidationErrorSectionSettings,
        sectionId: s.id,
        pageId: "",
      });
    }
    if (s.pages?.length < 1) {
      validationResults.push({
        errorMessage:
          TemplateFormStrings.TemplateValidationErrorSectionMinPages,
        sectionId: s.id,
        pageId: "",
      });
    } else {
      s.pages.forEach((p) => validatePage(s, p, validationResults));
    }
  });
  return validationResults;
};

const validatePage = (
  section: ISectionDesign,
  page: IPageDesign,
  validationResults: ITemplateValidation[],
) => {
  var subPageCount = page.subpages?.length ?? 0;
  if (
    page.type === "MULTI-FIELD" &&
    (page.answers?.length ?? 0) < 1 &&
    (subPageCount < 1 ||
      (subPageCount === 1 && page.subpages[0].type === "NEW"))
  ) {
    validationResults.push({
      errorMessage: TemplateFormStrings.TemplateValidationErrorPageMinElements,
      sectionId: section.id,
      pageId: page.id,
    });
  } else if (page.isValid && !page.isValid()) {
    validationResults.push({
      errorMessage: TemplateFormStrings.TemplateValidationErrorElements,
      sectionId: section.id,
      pageId:
        page.type === "MULTI-FIELD"
          ? page.id
          : getPageRefs(section, page.id)?.parent?.id ?? section.pages[0].id,
    });
  }

  page.subpages?.forEach((p) =>
    validatePage(section, p as IPageDesign, validationResults),
  );
  page.answers?.forEach((a) =>
    a.pages?.forEach((p) => validatePage(section, p, validationResults)),
  );
};

interface ITranslationStatistics {
  languageCode: string;
  fieldCount: number;
  translatedCount: number;
  percentCompleted: number;
}

export const updateTranslationStatistics = (
  template: ITemplateDesign | undefined,
  translationOptions: ITranslationOption[],
) => {
  var activeLanguageCodes = translationOptions
    .filter((o) => o.status === "ACTIVE")
    .map((o) => o.languageCode);

  var inactiveLanguageCodes = translationOptions
    .filter((o) => o.status === "INACTIVE")
    .map((o) => o.languageCode);

  var translationStatistics = [] as ITranslationStatistics[];
  activeLanguageCodes.forEach((code) => {
    translationStatistics.push({
      languageCode: code,
      fieldCount: 0,
      translatedCount: 0,
      percentCompleted: 0,
    } as ITranslationStatistics);
  });

  if (!template) {
    return translationStatistics;
  }

  template.flow.sections.forEach((s) => {
    s.pages.forEach((p) =>
      getPageTranslationStatistics(
        p,
        translationStatistics,
        inactiveLanguageCodes,
      ),
    );
  });

  translationStatistics.forEach((s) => {
    var translation = translationOptions.find(
      (t) => t.languageCode === s.languageCode,
    );
    if (translation) {
      translation.percentComplete = s.percentCompleted;
    }
  });
};

const calculateAttributeTranslationStatistics = (
  attribute: IAttribute,
  translationStatistics: ITranslationStatistics[],
) => {
  if (attribute && TranslatableTypes.includes(attribute.type)) {
    translationStatistics.forEach((stat) => {
      var text = attribute.getTranslationText(stat.languageCode);
      stat.fieldCount += 1;
      if (text?.length > 0) {
        stat.translatedCount += 1;
      }
      if (stat.fieldCount > 0) {
        // calculate percentage as whole number, rounding down.
        stat.percentCompleted = Math.floor(
          (stat.translatedCount / stat.fieldCount) * 100,
        );
      }
    });
  }
};

const calculateAnswerTanslationStatistics = (
  answer: IAnswer,
  translationStatistics: ITranslationStatistics[],
) => {
  if ((answer.translatedAnswerText?.length ?? 0) > 0) {
    translationStatistics.forEach((stat) => {
      var text = getTranslatedAnswerText(answer, stat.languageCode);
      stat.fieldCount += 1;
      if (text?.length > 0) {
        stat.translatedCount += 1;
      }
      if (stat.fieldCount > 0) {
        // calculate percentage as whole number, rounding down.
        stat.percentCompleted = Math.floor(
          (stat.translatedCount / stat.fieldCount) * 100,
        );
      }
    });
  }
};

const getPageTranslationStatistics = (
  page: IPageDesign,
  translationStatistics: ITranslationStatistics[],
  inactiveLanguageCodes: string[],
) => {
  if (page.attributes) {
    page.attributes.forEach((a) => {
      initDefaultTextHandlers(a);
      if (
        !pageIsComplex(page) &&
        TranslatableTypes.includes(a.type) &&
        !(a.type === "url" && page.type === "IMAGE") &&
        !(a.type === "continueText" && !pageAllowsContinueText(page))
      ) {
        calculateAttributeTranslationStatistics(a, translationStatistics);
        inactiveLanguageCodes.forEach((c) => a.removeTranslationText(c));
      }
    });
  }

  page.subpages?.forEach((p) =>
    getPageTranslationStatistics(
      p as IPageDesign,
      translationStatistics,
      inactiveLanguageCodes,
    ),
  );

  page.answers?.forEach((a) => {
    if (TranslatableTypes.includes(page.type as any)) {
      calculateAnswerTanslationStatistics(a, translationStatistics);
      inactiveLanguageCodes.forEach((c) => removeAnswerTranslation(a, c));
    }
    a.pages?.forEach((p) => {
      getPageTranslationStatistics(
        p,
        translationStatistics,
        inactiveLanguageCodes,
      );
    });
  });

  if (page.type === "TEXT") {
    translationStatistics.forEach((stat) => {
      const translatedPageText = page.translatedPageText?.find(
        (text) => text.languageCode === stat.languageCode,
      );
      stat.fieldCount += 1;
      if (translatedPageText && translatedPageText.translation.length > 0) {
        stat.translatedCount += 1;
      }
      if (stat.fieldCount > 0) {
        stat.percentCompleted = Math.floor(
          (stat.translatedCount / stat.fieldCount) * 100,
        );
      }
    });
  }
};
