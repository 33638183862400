import { useContext } from "react";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import { FlowMenuControlChoices, useFlowMenuControl } from "./FlowMenuControl";

export type FlowPageOptionMenuContext =
  | "navigator"
  | "workspace"
  | "branchWorkspace";

export const useFlowPageMenu = (menuContext: FlowPageOptionMenuContext) => {
  const activeMenuItems = () => {
    switch (menuContext) {
      case "branchWorkspace":
        return [
          "refCategory",
          "moveItemUp",
          "moveItemDown",
          "deleteItem",
        ] as FlowMenuControlChoices[];
      case "navigator":
        return [
          "moveItemUp",
          "moveItemDown",
          "deleteItem",
        ] as FlowMenuControlChoices[];
      case "workspace":
        return ["refCategory", "deleteItem"] as FlowMenuControlChoices[];
      default:
        return ["none"] as FlowMenuControlChoices[];
    }
  };

  const { PopupMenu, isOpen, setIsOpen, menuConfig, setMenuConfig } =
    useFlowMenuControl(activeMenuItems());

  const context = useContext(FlowNavigatorContext);
  context.pageOptionMenuHandler = (
    pageId: string,
    menuChoice: FlowMenuControlChoices,
  ) => {
    switch (menuChoice) {
      case "moveItemUp":
        context.movePageHandler(pageId, "up");
        break;

      case "moveItemDown":
        context.movePageHandler(pageId, "down");
        break;

      case "refCategory":
        context.referenceCategoryHandler(pageId);
        break;

      case "deleteItem":
        context.deletePageHandler(pageId);
        break;

      default:
        break;
    }
  };

  return { PopupMenu, isOpen, setIsOpen, menuConfig, setMenuConfig };
};
