import { FC, useState } from "react";
import styled from "styled-components";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  getAttribute,
  initializeElementAnswers,
  initializeTemplateDesignElement,
  IPageDesign,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";
import { useAnswersComponent } from "../../flowelementcomponents/answerscomponent/AnswersComponent";
import { ScaleFlowElementStrings } from "./ScaleFlowElementStrings";
import { Checkbox } from "@intouchhealth/cig-components";

export const ScaleFlowElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
  elementType: string;
}> = ({ page, setElementType, elementId, elementType }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    const isValid = isPromptValid() && answersValidated();

    return isValid;
  });

  const defaultEmptyAttribute = getAttribute(element, "defaultEmpty", "true");
  const [defaultEmpty, setDefaultEmpty] = useState<boolean>(
    defaultEmptyAttribute.value === "false" ? true : false,
  );

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);
  const { MultiSelectAnswers, answersValidated } = useAnswersComponent(
    initializeElementAnswers(element),
    2,
  );
  return (
    <FlowElementPanel
      page={page}
      elementType={elementType as TemplateItemTypes}
      setElementType={setElementType}
      elementId={elementId}
    >
      <RowLayoutContainer>
        <PromptInput />
      </RowLayoutContainer>
      <MultiSelectAnswers elementId={elementId} />
      <HorizontalLine />
      <ChildContainer>
        <Checkbox
          label={ScaleFlowElementStrings.ScaleFirstAnswerLabel}
          checked={defaultEmpty}
          onCheck={(e) => {
            const checkedStatus = (e?.target as HTMLInputElement).checked;

            setDefaultEmpty(checkedStatus);
            defaultEmptyAttribute.value = checkedStatus ? "false" : "true";
          }}
        />
      </ChildContainer>
    </FlowElementPanel>
  );
};

const RowLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 20px;
  flex: 2 1 auto;
`;

const HorizontalLine = styled.div`
  width: 100%;
  height: 2px;
  margin: 10px 0;
  background: #ded9d6;
`;

const ChildContainer = styled.div`
  display: flex;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 8px;
  background-color: transparent;
`;
