import { FC, useEffect, useState } from "react";
import styled from "styled-components";

import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  initializeTemplateDesignElement,
  IPageDesign,
  getAttribute,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";
import { FileUploadElementStrings } from "./FileUploadElementStrings";
import {
  FixedSizeContainer,
  HorizontalSpacer,
  RequiredInputIndicator,
  SelectInput,
} from "@intouchhealth/mfe-library";

export const FileUploadElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    var isValid = isPromptValid();
    return isValid;
  });

  const MAX_FILES = 9;

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);
  const minFilesAttribute = getAttribute(element, "minFiles", "0");
  const maxFilesAttribute = getAttribute(
    element,
    "maxFiles",
    MAX_FILES.toString(),
  );

  interface IMinMaxInputOptions {
    label: string;
    value: number;
  }

  const initializeOptionsList = (max: number) => {
    var result: IMinMaxInputOptions[] = [];
    for (var x = 0; x <= max; x++) {
      result.push({ label: x.toString(), value: x } as IMinMaxInputOptions);
    }
    return result;
  };

  const optionsList = initializeOptionsList(MAX_FILES);
  const [minimum, setMinimum] = useState<IMinMaxInputOptions | null>(
    optionsList.find((o) => o.value === parseInt(minFilesAttribute.value)) ??
      null,
  );
  const [maximum, setMaximum] = useState<IMinMaxInputOptions | null>(
    optionsList.find((o) => o.value === parseInt(maxFilesAttribute.value)) ??
      null,
  );

  const MinMaxInput: FC<{
    label: string;
    minOption: number;
    maxOption: number;
    selectedValue: IMinMaxInputOptions | null;
    setSelectedValue: (value: IMinMaxInputOptions | null) => void;
  }> = ({ label, minOption, maxOption, selectedValue, setSelectedValue }) => {
    return (
      <>
        <FixedSizeContainer width="120px">
          <RequiredInputIndicator>
            <SelectInput
              accessor="label"
              selectedValue={selectedValue}
              setSelectedValue={(value) =>
                setSelectedValue(value as IMinMaxInputOptions)
              }
              optionList={optionsList.filter(
                (o) => o.value >= minOption && o.value <= maxOption,
              )}
              label={label}
              helpText={""}
              disabled={false}
            />
          </RequiredInputIndicator>
        </FixedSizeContainer>
      </>
    );
  };

  useEffect(() => {
    if (minimum) {
      minFilesAttribute.value = minimum.value.toString();
    }
    // eslint-disable-next-line
  }, [minimum]);

  useEffect(() => {
    if (maximum) {
      maxFilesAttribute.value = maximum.value.toString();
    }
    // eslint-disable-next-line
  }, [maximum]);

  return (
    <FlowElementPanel
      page={page}
      elementType="FILE-UPLOAD"
      setElementType={setElementType}
      elementId={elementId}
      hideRequired={true}
    >
      <HeadingHelpTextContainer>
        {FileUploadElementStrings.FileTypesHeadingText}
      </HeadingHelpTextContainer>
      <PromptInput />
      <SecondaryHeadingContainer>
        {FileUploadElementStrings.RequiredNumberOfFilesHeading}
      </SecondaryHeadingContainer>
      <HeadingHelpTextContainer>
        {FileUploadElementStrings.RequiredNumberOfFilesHelpText}
      </HeadingHelpTextContainer>
      <RowLayoutContainer>
        <MinMaxInput
          label={FileUploadElementStrings.InputTypeMinimum}
          selectedValue={minimum}
          setSelectedValue={setMinimum}
          minOption={0}
          maxOption={maximum?.value ?? MAX_FILES}
        />
        <HorizontalSpacer />
        <MinMaxInput
          label={FileUploadElementStrings.InputTypeMaximum}
          selectedValue={maximum}
          setSelectedValue={setMaximum}
          minOption={minimum?.value ?? 0}
          maxOption={MAX_FILES}
        />
      </RowLayoutContainer>
    </FlowElementPanel>
  );
};

const SecondaryHeadingContainer = styled.div`
  margin-top: 5px;
  padding-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
  color: #302e2c;
  font-family: "Montserrat";
`;

const HeadingHelpTextContainer = styled.div`
  font-size: 14px;
  font-color: #ada8a4;
  margin-top: -10px;
  margin-bottom: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
`;

const RowLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
`;
