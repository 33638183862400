import { ChatIcon } from "./ChatIcon";
import { ChipsIcon } from "./ChipsIcon";
import { ForkIcon } from "./ForkIcon";
import { ImageIcon } from "./ImageIcon";
import { ImportUrlIcon } from "./ImportUrlIcon";
import { LongTextInputIcon } from "./LongTextInputIcon";
import { TextInputIcon } from "./TextInputIcon";

export const AppIcons = {
  Chat: ChatIcon,
  Chips: ChipsIcon,
  Fork: ForkIcon,
  Image: ImageIcon,
  LongTextInput: LongTextInputIcon,
  TextInput: TextInputIcon,
  ImportUrl: ImportUrlIcon,
};
