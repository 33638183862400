import { ElementTypeStoreStrings } from "./elementTypeStoreStrings";
import { TemplateItemTypes } from "./TemplateItemTypes";
import {
  CreditCard,
  Check,
  FileUploadOutlined,
  TextFields,
  RadioButtonChecked,
  CheckBoxOutlined,
  SignalCellularAlt,
  CalendarMonthOutlined,
  ArrowDropDownCircleOutlined,
  RoomOutlined,
} from "@mui/icons-material";
import { TemplateCategoryTypes } from "./TemplateCategoryTypes";
import { AppIcons } from "../icons/AppIcons";

export interface ITemplateElementTypeInfo {
  name: string;
  footNoteKey: string | undefined;
  type: TemplateItemTypes | undefined;
  category: TemplateCategoryTypes | undefined;
  icon: any;
  featureToggle: string | undefined;
}

const templateElementTypeInfo = () => {
  return [
    {
      name: ElementTypeStoreStrings.OpenEndedLabel,
      type: "OPEN-ENDED" as TemplateItemTypes,
      category: "QUESTION",
      icon: AppIcons.TextInput,
    },
    {
      name: ElementTypeStoreStrings.OpenEndedMultiLineLabel,
      type: "OPEN-ENDED:MULTI_LINE" as TemplateItemTypes,
      category: "QUESTION",
      icon: AppIcons.LongTextInput,
    },
    {
      name: ElementTypeStoreStrings.CloseEndedRadioButtonLabel,
      type: "CLOSE-ENDED:RADIO_BUTTON" as TemplateItemTypes,
      category: "QUESTION",
      icon: RadioButtonChecked,
    },
    {
      name: ElementTypeStoreStrings.CloseEndedChipsLabel,
      type: "CLOSE-ENDED:CHIPS" as TemplateItemTypes,
      category: "QUESTION",
      icon: AppIcons.Chips,
      featureToggle: "GAV3",
    },
    {
      name: ElementTypeStoreStrings.CloseEndedButtonLabel,
      footNoteKey: "*",
      type: "CLOSE-ENDED:BUTTON" as TemplateItemTypes,
      category: "QUESTION",
      icon: AppIcons.Fork,
    },
    {
      name: ElementTypeStoreStrings.CloseEndedMultiSelectLabel,
      type: "CLOSE-ENDED:MULTI_SELECT" as TemplateItemTypes,
      category: "QUESTION",
      icon: CheckBoxOutlined,
    },
    {
      name: ElementTypeStoreStrings.CloseEndedDropwdownLabel,
      type: "CLOSE-ENDED:DROPDOWN" as TemplateItemTypes,
      category: "QUESTION",
      icon: ArrowDropDownCircleOutlined,
    },
    {
      name: ElementTypeStoreStrings.ScaleLabel,
      type: "SCALE" as TemplateItemTypes,
      category: "QUESTION",
      icon: SignalCellularAlt,
      featureToggle: "GAV3",
    },
    {
      name: ElementTypeStoreStrings.DatePickerLabel,
      type: "DATE-PICKER" as TemplateItemTypes,
      category: "QUESTION",
      icon: CalendarMonthOutlined,
    },
    {
      name: ElementTypeStoreStrings.AcknowledgeLabel,
      type: "ACKNOWLEDGE" as TemplateItemTypes,
      category: "QUESTION",
      icon: Check,
    },
    {
      name: ElementTypeStoreStrings.FileUploadLabel,
      type: "FILE-UPLOAD" as TemplateItemTypes,
      category: "QUESTION",
      icon: FileUploadOutlined,
      featureToggle: "GAV3",
    },
    {
      name: ElementTypeStoreStrings.PaymentLabel,
      footNoteKey: "*",
      type: "PAYMENT" as TemplateItemTypes,
      category: "COMPLEX_PAGE",
      icon: CreditCard,
      featureToggle: "GAV3",
    },
    {
      name: ElementTypeStoreStrings.PharmacyLabel,
      footNoteKey: "*",
      type: "PHARMACY" as TemplateItemTypes,
      category: "COMPLEX_PAGE",
      icon: RoomOutlined,
      featureToggle: "GAV3",
    },
    {
      name: ElementTypeStoreStrings.TextLabel,
      type: "TEXT" as TemplateItemTypes,
      category: "CONTENT",
      icon: TextFields,
    },
    {
      name: ElementTypeStoreStrings.ImageLabel,
      type: "IMAGE" as TemplateItemTypes,
      category: "CONTENT",
      icon: AppIcons.Image,
      featureToggle: "GAV3",
    },
    {
      name: ElementTypeStoreStrings.HtmlLabel,
      type: "HTML" as TemplateItemTypes,
      category: "CONTENT",
      icon: AppIcons.ImportUrl,
    },
  ] as ITemplateElementTypeInfo[];
};

export const getTemplateElementTypesInfo = () =>
  templateElementTypeInfo().filter(
    (t) =>
      !t.featureToggle ||
      process.env.REACT_APP_FEATURE_TOGGLES?.includes(t.featureToggle),
  );

export const getTemplateElementTypeInfo = (
  type: TemplateItemTypes | undefined,
): ITemplateElementTypeInfo =>
  getTemplateElementTypesInfo().find((t) => t.type === type) ??
  ({
    name: "",
    type: undefined,
  } as ITemplateElementTypeInfo);

export const getTemplateElementName = (
  type: TemplateItemTypes | undefined,
): string =>
  getTemplateElementTypesInfo().find((t) => t.type === type)?.name ??
  type ??
  "";
