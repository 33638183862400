import { useContext, VFC } from "react";
import styled from "styled-components";
import { ISectionDesign } from "../../app/stores/templateDesignStore";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { FlowNavigatorContext, flowNavigatorWidth } from "./FlowNavigator";
import { VerticalSpacer } from "@intouchhealth/mfe-library";
import { useFlowPage } from "./FlowPage";
import { AddNewIcon, Button } from "@intouchhealth/cig-components";
import { FlowNavigatorStrings } from "./FlowNavigatorStrings";

export const useFlowContainer = (section: ISectionDesign) => {
  const context = useContext(FlowNavigatorContext);
  const { FlowPage } = useFlowPage();

  const FlowContainer: VFC = () => {
    return (
      <FlowLayout width={flowNavigatorWidth}>
        <SectionsLayout>
          <SimpleBar autoHide={false} style={{ height: "100%" }}>
            <VerticalSpacer />
            {section === undefined ? <></> : <FlowPage pages={section.pages} />}
            <SectionButtonsLayout>
              <SectionButton>
                <Button
                  size="xs"
                  textAlign="left"
                  iconPosition="right"
                  icon={<AddNewIcon />}
                  wide={true}
                  appearance="filled"
                  themeName="whitelabel"
                  onClick={() => {
                    context.addPageHandler();
                  }}
                >
                  {FlowNavigatorStrings.AddPageButton}
                </Button>
              </SectionButton>
            </SectionButtonsLayout>
          </SimpleBar>
        </SectionsLayout>
      </FlowLayout>
    );
  };

  return { FlowContainer };
};

const FlowLayout = styled.div<{ width: string }>`
  padding: 0px;
  margin-top: 0px;
  margin-bottom: 15px;
  padding-right: 15px;
  width: ${({ width }: { width: string }) => width};
  height: 100%;
  flex: 1 1 0%;
  align-items: start;
  justify-content: start;
  display: flex;
  flex-direction: column;
  color: black;
  background-color: transparent;
  .sls-input {
    justify-content: start;
    text-align: left;
    margin-bottom: 10px;
    & input {
      height: inherit;
      line-height: normal;
      background-color: #ffffff;
    }
  }
  & span {
    font-family: inherit;
  }
  .MuiOutlinedInput-root {
    background-color: #ffffff;
  }
  .MuiOutlinedInput-notchedOutline {
    left: auto;
    margin-left: -250px;
  }
`;

const SectionsLayout = styled.div`
  width: calc(100% + 15px);
  height: 100%;
  & div {
    overflow-x: clip !important;
  }
  .simplebar-track {
    opacity: 0.3;
  }
`;

const SectionButtonsLayout = styled.div`
  font-size: xxs;
  margin-top: 5px;
  margin-bottom: 0px;
  width: calc(100% - 16px);
`;

const SectionButton = styled.div`
  margin-top: 5px;
`;
