import { cloneDeep } from "@apollo/client/utilities";
import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { stripTypeNames } from "@intouchhealth/mfe-library";
import { DetailsSectionContext } from "./DetailsSection";
import { FlowSectionContext } from "./FlowSection";
import TemplateFormError, { useTemplateError } from "./TemplateFormError";
import { useImportDynamicTemplateMutation } from "./TemplateFormMutations";
import { DynamicTemplateType } from "./TemplateFormQueries";

interface ImportTemplateProviderProps {
  dynamicTemplate: DynamicTemplateType;
  updated: boolean;
  setUpdated: Dispatch<SetStateAction<boolean>>;
  onCreated: (templateId: string) => void;
}

export const ImportTemplateProvider: React.FC<ImportTemplateProviderProps> = ({
  dynamicTemplate,
  updated,
  setUpdated,
  onCreated,
}) => {
  const [
    createDynamicTemplateMutation,
    { data, loading, error, called, reset },
  ] = useImportDynamicTemplateMutation();
  const { ...formError } = useTemplateError();

  const detailsContext = useContext(DetailsSectionContext);
  const flowContext = useContext(FlowSectionContext);

  useEffect(() => {
    if (updated && !called) {
      // Make sure all cient side validations passed before proceeding with save otherwise open the alert and return.
      if (!detailsContext.isValidated || !flowContext.isValidated) {
        setUpdated(false);
        return;
      }

      //Build the updated dynamic template and populate with changes from form sections
      var newDynamicTemplate = cloneDeep(dynamicTemplate);

      detailsContext.updateFields(newDynamicTemplate);
      flowContext.updateFields(newDynamicTemplate);

      // Make call to update the dynamic template
      createDynamicTemplateMutation({
        variables: { input: stripTypeNames(newDynamicTemplate) },
      });

      setUpdated(false);
    }
    // eslint-disable-next-line
  }, [updated, called]);

  useEffect(() => {
    if (called && (!loading || error)) {
      if (formError.hasErrors(error, data?.importDynamicTemplate?.errors)) {
        reset();
      } else {
        var newTemplateId = data?.importDynamicTemplate?.template?.templateId;
        reset();
        dynamicTemplate.templateId = newTemplateId;
        onCreated(newTemplateId);
      }
    }
    // eslint-disable-next-line
  }, [data, loading, error, called, reset]);

  return (
    <TemplateFormError
      isErrorAlertOpen={formError.isErrorAlertOpen}
      setIsErrorAlertOpen={formError.setIsErrorAlertOpen}
      alertMessages={formError.alertMessages}
    ></TemplateFormError>
  );
};
