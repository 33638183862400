import { FC, useContext } from "react";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  getDefaultLinkText,
  getDefaultPageText,
  getLinkText,
  getPageText,
  getPageTextTranslations,
  initializeTemplateDesignElement,
  IPageDesign,
  setDefaultPageText,
  setPageText,
  translationChangedNotification,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { TextElementStrings } from "./TextElementStrings";
import "simplebar/dist/simplebar.min.css";
import { TranslatableLinkTextBoxComponent } from "../../flowelementcomponents/linkeditorcomponent/TranslatableLinkEditorComponent";
import { TranslationManagerContext } from "@intouchhealth/mfe-library";

export const TextElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  const context = useContext(TranslationManagerContext);
  const element = initializeTemplateDesignElement(page, elementId, () => {
    return isValid();
  });

  const isValid = () => {
    const allTranslations = getPageTextTranslations(page);
    const invalidTranslation = allTranslations.find(
      (translation) => !linksAreUnique(translation.languageCode),
    );

    if (invalidTranslation) {
      context.setActiveLanguageCode(invalidTranslation.languageCode);
      return false;
    }

    return !getRequiredMessage() && linksAreUnique();
  };

  const getRequiredMessage = (
    languageCode?: string,
    required: boolean = true,
  ) => {
    if (getDefaultPageText(element).length === 0 && required) {
      return TextElementStrings.TextInputRequiredTextNotBlank;
    } else if (!linksAreUnique(languageCode)) {
      return TextElementStrings.TextInputRequiredTextLinksNotUnique;
    }

    return "";
  };

  const getTranslatedPageText = (languageCode?: string) => {
    if (!languageCode || languageCode === "default") {
      return getDefaultPageText(element);
    } else {
      return getPageText(element, languageCode);
    }
  };

  const linksAreUnique = (languageCode?: string) => {
    const text = getTranslatedPageText(languageCode);
    let isUnique = true;

    if (text) {
      element.links?.forEach((l) => {
        const linkText =
          !languageCode || languageCode === "default"
            ? getDefaultLinkText(l)
            : getLinkText(l, languageCode);
        if (linkText.length > 0 && text.split(linkText).length > 2) {
          isUnique = false;
        }
      });
    }

    return isUnique;
  };

  return (
    <>
      <FlowElementPanel
        page={page}
        elementType="TEXT"
        setElementType={setElementType}
        elementId={elementId}
        hideRequired={true}
      >
        <TranslatableLinkTextBoxComponent
          element={element}
          elementId={elementId}
          title={TextElementStrings.TextInputLabel}
          helptext={TextElementStrings.TextInputHelpText}
          getInitialText={() => getDefaultPageText(element)}
          getInitialTranslatedText={(languageCode: string) =>
            getPageText(element, languageCode)
          }
          onTextChanged={(text: string) => setDefaultPageText(element, text)}
          onTranslatedTextChanged={(text: string, languageCode: string) => {
            setPageText(element, text, languageCode);
            translationChangedNotification.publish(null);
          }}
          getRequiredMessage={getRequiredMessage}
        />
      </FlowElementPanel>
    </>
  );
};
