import { cloneDeep } from "@apollo/client/utilities";
import { useContext, useState } from "react";
import { useFormDialog } from "@intouchhealth/mfe-library";
import { DynamicTemplateType } from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { DetailsSectionContext, DetailsSectionBase } from "./DetailsSection";
import { RootStoreContext } from "../../app/stores/rootStore";
import styled from "styled-components";
import { translationChangedNotification } from "../../app/stores/templateDesignStore";

export interface IUpdatedTemplateDetails {
  name: string;
  type: string;
  endAction: string;
}

export const useTemplateDetailsDialog = (
  dynamicTemplate: DynamicTemplateType,
  isCreate: boolean,
  onDetailsUpdated: (templateDetails: IUpdatedTemplateDetails) => void,
) => {
  const { ...form } = useFormDialog(
    TemplateFormStrings.DetailsSectionTitle,
    TemplateFormStrings.OkButtonLabel,
    TemplateFormStrings.CancelButtonLabel,
  );
  const { commonStore } = useContext(RootStoreContext);
  const detailsContext = useContext(DetailsSectionContext);
  const [editTemplate, setEditTemplate] = useState(cloneDeep(dynamicTemplate));
  const [cancelCreate, setCancelCreate] = useState(isCreate);

  const handleAccepted = async () => {
    if (await detailsContext.validateTemplateTag()) {
      var updatedFields = cloneDeep(editTemplate);
      detailsContext.updateFields(updatedFields);
      setEditTemplate(updatedFields);
      var templateDetails = {
        name: updatedFields.name,
        type: updatedFields.type,
        endAction: updatedFields.endAction,
      } as IUpdatedTemplateDetails;
      onDetailsUpdated(templateDetails);
      setCancelCreate(false);
      form.setIsOpen(false);
      translationChangedNotification.publish(null);
    }
  };

  const handleCancelled = () => {
    form.setIsOpen(false);
    if (cancelCreate) {
      commonStore.history.push(`${commonStore.rootPath}?notify=cancel`);
    }
  };

  const TemplateDetailsDialog: React.FC = () => {
    const [enabled, setEnabled] = useState<boolean>(false);

    return (
      <form.FormDialog
        position="appcontainer"
        top={40}
        width={"75%"}
        onAccepted={handleAccepted}
        onCancelled={handleCancelled}
        acceptDisabled={!enabled}
      >
        <DetailsSectionBase
          isCreate={isCreate}
          isImport={false}
          dynamicTemplate={editTemplate}
          setEnabled={setEnabled}
        />
      </form.FormDialog>
    );
  };

  return {
    TemplateDetailsDialog,
    setIsOpen: form.setIsOpen,
  };
};

export const DialogLayout = styled.div`
  .sls-input {
    justify-content: start;
    text-align: left;
    margin-bottom: 10px;
    & input {
      height: inherit;
      line-height: normal;
      background-color: #ffffff;
    }
  }
  .MuiOutlinedInput-root {
    background-color: #ffffff;
  }
  .MuiOutlinedInput-notchedOutline {
    left: auto;
    margin-left: -250px;
  }
`;
