import { configure } from "mobx";
import { createContext } from "react";
import CommonStore from "./commonStore";

configure({ enforceActions: "always", isolateGlobalState: true });

export class RootStore {
  commonStore: CommonStore;

  constructor() {
    this.commonStore = new CommonStore(this);
  }
}

export const RootStoreSingleton = new RootStore();
export const RootStoreContext = createContext(RootStoreSingleton);
