export const LongTextInputIcon = () => {
  return (
    <div
      style={{
        marginLeft: "1px",
        marginRight: "-6px",
        width: "27px",
        height: "24px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_1123_94305)">
          <rect
            x="1.75"
            y="1.75"
            width="20.5"
            height="20.5"
            rx="1.25"
            stroke="black"
            stroke-width="1.5"
          />
          <path
            d="M8.5 11.3915C8.5 11.7376 8.24811 12 7.9375 12C7.26232 12 6.66273 11.6605 6.25 11.1346C5.83727 11.6605 5.23768 12 4.5625 12C4.25189 12 4 11.72 4 11.3915C4 11.063 4.25189 10.7654 4.5625 10.7654C5.18301 10.7654 5.6875 10.2035 5.6875 9.51321V8.6349C5.6875 8.24999 5.6875 8.35512 5.6875 8.0088C5.6875 7.66249 5.6875 7.74079 5.6875 7.3827V6.5044C5.6875 5.81413 5.18301 5.2522 4.5625 5.2522C4.25189 5.2522 4 4.97222 4 4.6261C4 4.27998 4.25189 4 4.5625 4C5.23768 4 5.83727 4.33946 6.25 4.86539C6.66273 4.33946 7.26232 4 7.9375 4C8.24811 4 8.5 4.27998 8.5 4.6261C8.5 4.97222 8.24811 5.2522 7.9375 5.2522C7.31699 5.2522 6.8125 5.81413 6.8125 6.5044V7.3827C6.8125 7.81059 6.8125 7.66269 6.8125 8.0088C6.8125 8.35492 6.8125 8.23753 6.8125 8.6349V9.51321C6.8125 10.2035 7.31699 10.7654 7.9375 10.7654C8.24863 10.7654 8.5 11.0452 8.5 11.3915Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1123_94305">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
