import { useEffect, useState } from "react";
import { IImageInfo } from "./ImageManagerDialog";
import {
  useGetUploadedImagesQuery,
  IUploadedImage,
} from "./TemplateFormQueries";

// Gets details about all the internal images that have been uploaded to the server.
export const useS3ImageList = (practiceId: string) => {
  const { loading, data, refetch, called } =
    useGetUploadedImagesQuery(practiceId);
  const [s3ImageList, setS3ImageList] = useState<IImageInfo[]>([]);

  // Finds the image details from the server, if it exists. It will return undefined if the provided image URL is not hosted on the server.
  const findS3ImageFromUrl = (sourceUrl: string, imageList?: IImageInfo[]) => {
    return (imageList ?? s3ImageList).find((elem) => elem.url === sourceUrl);
  };

  useEffect(() => {
    if (!loading && data) {
      let list = data
        ? (data.dynamicTemplateImageFiles.map((t: IUploadedImage) => {
            return {
              name: t.key.substring(t.key.lastIndexOf("/") + 1, t.key.length), // Remove file path for the image name.
              key: t.key,
              url: t.url,
              isUsedInTemplate: t.isUsedInTemplate,
            };
          }) as IImageInfo[])
        : [];

      setS3ImageList(
        list.map((elem, index) => {
          return { ...elem, index };
        }),
      );
    }
  }, [loading, data]);

  return {
    loading,
    refetch,
    called,
    s3ImageList,
    findS3ImageFromUrl,
  };
};
