import { FC, useEffect, useState } from "react";
import { convertFromRaw, genKey } from "draft-js";
import {
  isProperlyFormattedUrl,
  RequiredInputIndicator,
  useFormDialog,
} from "@intouchhealth/mfe-library";
import { PureInput } from "@intouchhealth/cig-components";
import {
  addLink,
  createLinkEditorState,
  getAllLinkData,
  getLinkData,
  getSelectionEntity,
  getSelectionText,
  ILinkData,
  processEditsToLinkText,
  updateLink,
} from "./DraftJsLinkHelper";
import { LinkEditorComponentStrings } from "./LinkEditorComponentStrings";

const initializeContent = (text: string, links: ILinkData[] | undefined) => {
  var entityMap = new Map();
  var mapIndex = 0;
  var blocks = text.split("\n").map((blockText) => {
    var entityRanges = [] as any[];
    links?.forEach((link) => {
      if (link.text.length > 0 && blockText.includes(link.text)) {
        var mapKey = mapIndex.toString();
        mapIndex++;
        var mapEntry = {
          type: "LINK",
          mutability: "MUTABLE",
          data: {
            text: link.text,
            url: link.url,
          },
        };
        entityMap.set(mapKey, mapEntry);
        entityRanges.push({
          offset: blockText.lastIndexOf(link.text),
          length: link.text.length,
          key: mapKey,
        });
      }
    });

    return {
      key: genKey(),
      text: blockText,
      type: "unstyled",
      depth: 0,
      inlineStyleRanges: [],
      entityRanges: entityRanges,
      data: {},
    };
  });

  return convertFromRaw({
    blocks: blocks,
    entityMap: Object.fromEntries(entityMap),
  });
};

export interface ILinkEditorData {
  text: string;
  links: ILinkData[];
  language?: string;
}

export const useLinkEditorComponent = (initialData: ILinkEditorData) => {
  const initialContent = initializeContent(initialData.text, initialData.links);
  const [editorState, setEditorState] = useState<Draft.EditorState>(
    createLinkEditorState(initialContent),
  );
  const [linkEditorData, setLinkEditorData] =
    useState<ILinkEditorData>(initialData);
  const [currentEditorContent, setCurrentEditorContent] =
    useState<Draft.ContentState>(initialContent);
  const [editorDialogState, setEditorDialogState] = useState<
    "none" | "open" | "closed"
  >("none");
  const [editEntityKey, setEditEntityKey] = useState<string>();
  const [dialogTitle, setDialogTitle] = useState<string>("");
  const [okButtonText, setOkButtonText] = useState<string>("");
  const { ...form } = useFormDialog(
    dialogTitle,
    okButtonText,
    LinkEditorComponentStrings.CancelButtonLabel,
  );

  const openLinkEditor = () => {
    setEditEntityKey(getSelectionEntity(editorState));
    form.setIsOpen(true);
  };

  const LinkEditorDialog: FC = () => {
    const [displayText, setDisplayText] = useState<string>("");
    const [linkUrl, setLinkUrl] = useState<string>("");

    const displayTextIsValid = () => {
      return displayText.length > 0;
    };

    const urlIsValid = () => {
      return linkUrl.length > 0 && isProperlyFormattedUrl(linkUrl, true);
    };

    const isValid = () => {
      return displayTextIsValid() && urlIsValid();
    };

    const onAccepted = () => {
      if (form.isOpen && isValid()) {
        form.setIsOpen(false);
        const entityData = {
          text: displayText,
          url: linkUrl,
        } as ILinkData;

        if (editEntityKey) {
          updateLink(editorState, setEditorState, entityData, editEntityKey);
        } else {
          addLink(editorState, setEditorState, entityData);
        }
        setEditEntityKey(undefined);
      }
    };

    const onCancelled = () => {
      if (form.isOpen) {
        form.setIsOpen(false);
        setEditEntityKey(undefined);
      }
    };

    useEffect(() => {
      if (form.isOpen) {
        const languagePostfix = initialData.language
          ? ` (${initialData.language})`
          : "";

        setEditorDialogState("open");
        if (editEntityKey) {
          setDialogTitle(
            `${LinkEditorComponentStrings.UpdateTitleBarText}${languagePostfix}`,
          );
          setOkButtonText(LinkEditorComponentStrings.OkButtonOnUpdateLabel);
          var linkData = getLinkData(editEntityKey, editorState);
          setDisplayText(linkData?.text ?? "");
          setLinkUrl(linkData?.url ?? "");
        } else {
          setDialogTitle(
            `${LinkEditorComponentStrings.InsertTitleBarText}${languagePostfix}`,
          );
          setOkButtonText(LinkEditorComponentStrings.OkButtonOnInsertLabel);
          setDisplayText(getSelectionText(editorState) ?? "");
          setLinkUrl("");
        }
      } else if (editorDialogState === "open") {
        setEditorDialogState("closed");
      }

      var latestContent = editorState.getCurrentContent();
      if (currentEditorContent !== latestContent) {
        processEditsToLinkText(editorState);
        setCurrentEditorContent(latestContent);
        setLinkEditorData({
          text: latestContent.getPlainText(),
          links: getAllLinkData(editorState),
        });
      }
    }, []);

    return (
      <form.FormDialog
        position="appcontainer"
        top={"25%"}
        width={"45%"}
        onAccepted={onAccepted}
        onCancelled={onCancelled}
        acceptDisabled={!isValid()}
      >
        <RequiredInputIndicator>
          <PureInput
            label={LinkEditorComponentStrings.TextInputLabel}
            value={displayText}
            onChange={(value) => {
              setDisplayText(value);
            }}
            className="sls-input"
            invalid={!displayTextIsValid()}
            infoMessage={
              !displayTextIsValid()
                ? LinkEditorComponentStrings.TextInputRequiredText
                : LinkEditorComponentStrings.TextInputHelpText
            }
          />
        </RequiredInputIndicator>
        <RequiredInputIndicator>
          <PureInput
            label={LinkEditorComponentStrings.UrlInputLabel}
            value={linkUrl}
            onChange={(value) => {
              setLinkUrl(value);
            }}
            className="sls-input"
            invalid={!urlIsValid()}
            infoMessage={
              !urlIsValid()
                ? LinkEditorComponentStrings.UrlInputInvalidText
                : LinkEditorComponentStrings.UrlInputHelpText
            }
          />
        </RequiredInputIndicator>
      </form.FormDialog>
    );
  };

  return {
    LinkEditorDialog,
    openLinkEditor,
    editorDialogState,
    editorState,
    setEditorState,
    linkEditorData,
  };
};
