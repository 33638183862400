import { FC, useContext } from "react";
import {
  isProperlyFormattedUrl,
  useTranslatableTextInput,
  TranslationManagerContext,
} from "@intouchhealth/mfe-library";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  getAttribute,
  initializeTemplateDesignElement,
  IPageDesign,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { HtmlFlowElementStrings } from "./HtmlFlowElementStrings";

export const HtmlFlowElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  const context = useContext(TranslationManagerContext);
  const element = initializeTemplateDesignElement(page, elementId, () => {
    const translations = urlAttribute.getAllTranslations();
    const isValid =
      isDefaultUrlValid() &&
      isTranslatedUrlsValid(
        translations.map((t) => t.languageCode),
        true,
      );

    return isValid;
  });
  const urlAttribute = getAttribute(element, "url", undefined, "");
  const { TranslatableTextInput } = useTranslatableTextInput(
    "Url-" + element.id,
  );

  const isDefaultUrlValid = () =>
    isProperlyFormattedUrl(
      urlAttribute.defaultText() || urlAttribute.value,
      true,
    );
  const isTranslatedUrlsValid = (
    languageCodes: string[],
    switchLanguageOnError: boolean = false,
  ) => {
    const invalidTranslation = languageCodes.find((languageCode) => {
      const translatedUrl = urlAttribute.getTranslationText(languageCode);
      return (
        translatedUrl.length && !isProperlyFormattedUrl(translatedUrl, true)
      );
    });

    if (switchLanguageOnError && invalidTranslation) {
      context.setActiveLanguageCode(invalidTranslation);
    }

    return !invalidTranslation;
  };

  return (
    <FlowElementPanel
      page={page}
      elementType="HTML"
      setElementType={setElementType}
      elementId={elementId}
      hideRequired={true}
    >
      <TranslatableTextInput
        label={HtmlFlowElementStrings.HtmlUrlLabel}
        initializeDefaultValue={() => {
          return urlAttribute.defaultText() || urlAttribute.value;
        }}
        onDefaultChanged={(value) => {
          urlAttribute.setDefaultText(value);
        }}
        initializeTranslatedValue={(languageCode) => {
          return urlAttribute.getTranslationText(languageCode);
        }}
        onTranslatedChanged={(value, languageCode) => {
          urlAttribute.setTranslationText(languageCode, value);
        }}
        isDefaultValid={() => isDefaultUrlValid()}
        isDefaultRequired={() => true}
        isTranslatedValid={(languageCode) =>
          isTranslatedUrlsValid([languageCode])
        }
        isTranslatedRequired={() => false}
        helpText={HtmlFlowElementStrings.HtmlUrlHelpText}
        requiredHelpText={HtmlFlowElementStrings.HtmlUrlRequiredText}
      />
    </FlowElementPanel>
  );
};
