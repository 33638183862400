import { useContext, useEffect, useState } from "react";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { Button } from "@intouchhealth/cig-components";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import {
  Visibility as PreviewIcon,
  VisibilityOff as PreviewOffIcon,
} from "@mui/icons-material";
import styled from "styled-components";
import { StyledTooltip } from "@intouchhealth/mfe-library";
import { DesignTemplateFormContext } from "./DesignTemplateForm";

export const usePreviewTemplateButton = (templateId?: string) => {
  const flowNavigator = useContext(FlowNavigatorContext);
  const designForm = useContext(DesignTemplateFormContext);

  const [previewTemplateId, setPreviewTemplateId] = useState<
    string | undefined
  >();

  flowNavigator.previewNotification?.subscribe(
    `previewNotification-${templateId}`,
    setPreviewTemplateId,
  );

  useEffect(() => {
    flowNavigator.previewNotification?.publish(previewTemplateId);
    // eslint-disable-next-line
  }, [previewTemplateId]);

  const handlePreviewButtonPressed = () => {
    if (!previewTemplateId) {
      if (designForm.validateTemplate()) {
        designForm.updateGeneratedFlow();
        flowNavigator.previewNotification?.publish(templateId);
      }
    } else {
      flowNavigator.previewNotification?.publish(undefined);
      designForm.resetToFirstPage();
    }
  };

  const PreviewButton = () => (
    <StyledTooltip
      title={
        previewTemplateId
          ? TemplateFormStrings.PreviewCloseButtonTooltip
          : TemplateFormStrings.PreviewButtonTooltip
      }
    >
      <PreviewButtonWrapper hidden={false} className={"PreviewButton"}>
        <Button
          appearance="outline"
          icon={previewTemplateId ? <PreviewOffIcon /> : <PreviewIcon />}
          onClick={handlePreviewButtonPressed}
          size={"xs"}
          themeName="whitelabel"
        />
      </PreviewButtonWrapper>
    </StyledTooltip>
  );

  return {
    PreviewButton,
    previewTemplateId,
    setPreviewTemplateId,
    hasPreview: !!previewTemplateId,
  };
};

const PreviewButtonWrapper = styled.div`
  & svg {
    transform: scale(0.75);
  }
`;
