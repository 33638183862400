import { FC, useContext } from "react";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  getAttribute,
  getDefaultLinkText,
  getLinkText,
  initializeTemplateDesignElement,
  IPageDesign,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import styled from "styled-components";
import { TranslationManagerContext } from "@intouchhealth/mfe-library";
import { AcknowledgeElementStrings } from "./AcknowledgeElementStrings";
import "simplebar/dist/simplebar.min.css";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";
import { TranslatableLinkTextBoxComponent } from "../../flowelementcomponents/linkeditorcomponent/TranslatableLinkEditorComponent";

export const AcknowledgeElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    return isValid();
  });
  const context = useContext(TranslationManagerContext);

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);

  const acknowledgeTextAttribute = getAttribute(
    element,
    "acknowledgeText",
    undefined,
    "",
  );

  const getAcknowledgeText = (languageCode?: string) => {
    if (!languageCode || languageCode === "default") {
      return acknowledgeTextAttribute.defaultText();
    } else {
      return acknowledgeTextAttribute.getTranslationText(languageCode);
    }
  };

  const checkboxTextIsBlank = (langaugeCode?: string) => {
    return getAcknowledgeText(langaugeCode).trim().length === 0;
  };

  const linksAreUnique = (languageCode?: string) => {
    const text = getAcknowledgeText(languageCode);
    let isUnique = true;

    element.links?.forEach((l) => {
      const linkText =
        !languageCode || languageCode === "default"
          ? getDefaultLinkText(l)
          : getLinkText(l, languageCode);
      if (linkText.length > 0 && text.split(linkText).length > 2) {
        isUnique = false;
      }
    });

    return isUnique;
  };

  const isValid = () => {
    const allTranslations = acknowledgeTextAttribute.getAllTranslations();
    const invalidTranslation = allTranslations.find(
      (translation) => !linksAreUnique(translation.languageCode),
    );

    if (invalidTranslation) {
      context.setActiveLanguageCode(invalidTranslation.languageCode);
      return false;
    }

    return isPromptValid() && !checkboxTextIsBlank() && linksAreUnique();
  };

  const getRequiredMessage = (languageCode: string, required: boolean) => {
    if (checkboxTextIsBlank(languageCode) && required) {
      return AcknowledgeElementStrings.CheckboxTextInputRequiredTextNotBlank;
    } else if (!linksAreUnique(languageCode)) {
      return AcknowledgeElementStrings.TextInputRequiredTextLinksNotUnique;
    }

    return "";
  };

  return (
    <>
      <FlowElementPanel
        page={page}
        elementType="ACKNOWLEDGE"
        setElementType={setElementType}
        elementId={elementId}
        hideRequired={false}
      >
        <RowLayoutContainer>
          <PromptInput />
        </RowLayoutContainer>
        <TranslatableLinkTextBoxComponent
          element={element}
          elementId={elementId}
          title={AcknowledgeElementStrings.CheckboxTextInputLabel}
          helptext={AcknowledgeElementStrings.CheckboxTextInputHelpText}
          getInitialText={() => acknowledgeTextAttribute.defaultText()}
          getInitialTranslatedText={(languageCode: string) =>
            acknowledgeTextAttribute.getTranslationText(languageCode)
          }
          onTextChanged={(text: string) =>
            acknowledgeTextAttribute.setDefaultText(text)
          }
          onTranslatedTextChanged={(text: string, languageCode: string) =>
            acknowledgeTextAttribute.setTranslationText(languageCode, text)
          }
          getRequiredMessage={getRequiredMessage}
        />
      </FlowElementPanel>
    </>
  );
};

const RowLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 5px;
`;
