export const TextInputIcon = () => {
  return (
    <div
      style={{
        marginLeft: "1px",
        marginRight: "-6px",
        width: "27px",
        height: "24px",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        viewBox="0 0 24 24"
        fill="none"
      >
        <g clip-path="url(#clip0_1123_94304)">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M8.9375 19C9.24811 19 9.5 18.7376 9.5 18.3915C9.5 18.0452 9.24863 17.7654 8.9375 17.7654C8.31699 17.7654 7.8125 17.2035 7.8125 16.5132V12.6349V12.0088V11.3827V7.5044C7.8125 6.81413 8.31699 6.2522 8.9375 6.2522C9.24811 6.2522 9.5 5.97222 9.5 5.6261C9.5 5.27998 9.24811 5 8.9375 5C8.26232 5 7.66273 5.33946 7.25 5.86539C6.83727 5.33946 6.23768 5 5.5625 5C5.25189 5 5 5.27998 5 5.6261C5 5.97222 5.25189 6.2522 5.5625 6.2522C6.18301 6.2522 6.6875 6.81413 6.6875 7.5044V11.3827V12.0088V12.6349V16.5132C6.6875 17.2035 6.18301 17.7654 5.5625 17.7654C5.25189 17.7654 5 18.063 5 18.3915C5 18.72 5.25189 19 5.5625 19C6.23768 19 6.83727 18.6605 7.25 18.1346C7.66273 18.6605 8.26232 19 8.9375 19ZM3 7.5H4V9H3C2.72386 9 2.5 9.22386 2.5 9.5V14.5C2.5 14.7761 2.72386 15 3 15H4V16.5H3C1.89543 16.5 1 15.6046 1 14.5V9.5C1 8.39543 1.89543 7.5 3 7.5ZM21 15H10V16.5H21C22.1046 16.5 23 15.6046 23 14.5V9.5C23 8.39543 22.1046 7.5 21 7.5H10V9H21C21.2761 9 21.5 9.22386 21.5 9.5V14.5C21.5 14.7761 21.2761 15 21 15Z"
            fill="black"
          />
        </g>
        <defs>
          <clipPath id="clip0_1123_94304">
            <rect width="24" height="24" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
