import { Checkbox } from "@intouchhealth/cig-components";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { GroupContainer } from "@intouchhealth/mfe-library";
import { TemplateItemTypes } from "../../../app/stores/TemplateItemTypes";
import {
  initializeTemplateDesignElement,
  IPageDesign,
  getAttribute,
  setAttributeValue,
} from "../../../app/stores/templateDesignStore";
import { FlowElementPanel } from "../../flowworkspace/FlowElementPanel";
import { DatePickerFlowElementStrings } from "./DatePickerFlowElementStrings";
import { usePromptInputComponent } from "../../flowelementcomponents/promptinputcomponent/PromptInputComponent";

const RESTRICTION = {
  NO_RESTRICTIONS: "NO_RESTRICTIONS",
  PAST_DATE: "PAST_DATE",
  PAST_DATE_INCLUDING_TODAY: "PAST_DATE_INCLUDING_TODAY",
  FUTURE_DATE: "FUTURE_DATE",
  FUTURE_DATE_INCLUDING_TODAY: "FUTURE_DATE_INCLUDING_TODAY",
};

export const DatePickerFlowElement: FC<{
  page: IPageDesign;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
}> = ({ page, setElementType, elementId }) => {
  const element = initializeTemplateDesignElement(page, elementId, () => {
    const isValid = isPromptValid() && isSelectedDateAttributeValid();

    return isValid;
  });

  const restrictionAttribute = getAttribute(
    element,
    "restriction",
    RESTRICTION.NO_RESTRICTIONS,
  );

  const checkBoxStates = (() => {
    if (restrictionAttribute) {
      const { value } = restrictionAttribute;
      return {
        allowPastDate:
          value === RESTRICTION.PAST_DATE_INCLUDING_TODAY ||
          value === RESTRICTION.PAST_DATE ||
          value === RESTRICTION.NO_RESTRICTIONS,
        allowToday:
          value === RESTRICTION.PAST_DATE_INCLUDING_TODAY ||
          value === RESTRICTION.FUTURE_DATE_INCLUDING_TODAY ||
          value === RESTRICTION.NO_RESTRICTIONS,
        allowFutureDate:
          value === RESTRICTION.FUTURE_DATE_INCLUDING_TODAY ||
          value === RESTRICTION.FUTURE_DATE ||
          value === RESTRICTION.NO_RESTRICTIONS,
      };
    } else {
      return {
        allowPastDate: false,
        allowToday: false,
        allowFutureDate: false,
      };
    }
  })();

  const [allowPastDate, setAllowPastDate] = useState<boolean>(
    checkBoxStates.allowPastDate,
  );
  const [allowToday, setAllowToday] = useState<boolean>(
    checkBoxStates.allowToday,
  );
  const [allowFutureDate, setAllowFutureDate] = useState<boolean>(
    checkBoxStates.allowFutureDate,
  );
  const [todayForceChecked, setTodayForceChecked] = useState<boolean>(false);
  const [todayForceUnchecked, setTodayForceUnchecked] =
    useState<boolean>(false);

  useEffect(() => {
    let restrictionValue: string = "";

    setTodayForceUnchecked(!allowPastDate && !allowFutureDate);
    setTodayForceChecked(allowPastDate && allowFutureDate);
    setAllowToday(
      todayForceUnchecked ? false : todayForceChecked ? true : allowToday,
    );

    if (allowPastDate && allowToday && allowFutureDate) {
      restrictionValue = RESTRICTION.NO_RESTRICTIONS;
    } else if (allowPastDate && !allowFutureDate) {
      if (allowToday) {
        restrictionValue = RESTRICTION.PAST_DATE_INCLUDING_TODAY;
      } else {
        restrictionValue = RESTRICTION.PAST_DATE;
      }
    } else if (allowFutureDate && !allowPastDate) {
      if (allowToday) {
        restrictionValue = RESTRICTION.FUTURE_DATE_INCLUDING_TODAY;
      } else {
        restrictionValue = RESTRICTION.FUTURE_DATE;
      }
    }

    setAttributeValue(element, "restriction", restrictionValue);
  }, [
    allowPastDate,
    allowToday,
    allowFutureDate,
    todayForceChecked,
    todayForceUnchecked,
    element,
  ]);

  const isSelectedDateAttributeValid = () => {
    return restrictionAttribute.value !== "";
  };

  const isSelectedDateStateValid = () => {
    if (!allowPastDate && !allowToday && !allowFutureDate) {
      return false;
    }

    if (allowToday && !(allowPastDate || allowFutureDate)) {
      return false;
    }

    return true;
  };

  const { PromptInput, isPromptValid } = usePromptInputComponent(page, element);

  return (
    <FlowElementPanel
      page={page}
      elementType="DATE-PICKER"
      setElementType={setElementType}
      elementId={elementId}
    >
      <RowLayoutContainer>
        <PromptInput />
      </RowLayoutContainer>
      <RowLayoutContainer>
        <GroupContainer
          required={true}
          title={DatePickerFlowElementStrings.DateSelectionHelpText}
          requiredLabel={
            !isSelectedDateStateValid()
              ? DatePickerFlowElementStrings.DateSelectionInvalidText
              : ""
          }
        >
          <CheckboxStyle>
            <Checkbox
              label={DatePickerFlowElementStrings.AllowPastDatesLabel}
              checked={allowPastDate}
              onCheck={(e) => {
                setAllowPastDate((e?.target as HTMLInputElement).checked);
              }}
            />
          </CheckboxStyle>
          <CheckboxStyle>
            <Checkbox
              label={DatePickerFlowElementStrings.AllowTodayLabel}
              checked={
                (allowToday || todayForceChecked) && !todayForceUnchecked
              }
              disabled={todayForceChecked || todayForceUnchecked}
              onCheck={(e) => {
                setAllowToday((e?.target as HTMLInputElement).checked);
              }}
            />
          </CheckboxStyle>
          <CheckboxStyle>
            <Checkbox
              label={DatePickerFlowElementStrings.AllowFutureDatesLabel}
              checked={allowFutureDate}
              onCheck={(e) => {
                setAllowFutureDate((e?.target as HTMLInputElement).checked);
              }}
            />
          </CheckboxStyle>
        </GroupContainer>
      </RowLayoutContainer>
    </FlowElementPanel>
  );
};

const CheckboxStyle = styled.div`
  margin-right: 45px;
`;

const RowLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  margin-bottom: 20px;
`;
