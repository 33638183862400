import React, { useContext } from "react";
import { useMessageDialog } from "@intouchhealth/mfe-library";
import { getPageSiblings } from "../../app/stores/templateDesignStore";
import { FlowNavigatorContext } from "./FlowNavigator";
import { FlowNavigatorStrings } from "./FlowNavigatorStrings";

export const useMovePageHandler = () => {
  const context = useContext(FlowNavigatorContext);
  const { ...moveNotAllowed } = useMessageDialog();

  const MovePageDialog: React.VFC = () => {
    return (
      <moveNotAllowed.ModalDialog
        type="warning"
        position="appcontainer"
        top={"25%"}
        title={FlowNavigatorStrings.MovePageNotAllowedDialogTile}
        closeButtonText={FlowNavigatorStrings.MovePageNotAllowedCloseButton}
      >
        {FlowNavigatorStrings.MovePageNotAllowedDialogMessage}
      </moveNotAllowed.ModalDialog>
    );
  };

  context.movePageHandler = (pageId: string, direction: "up" | "down") => {
    var siblings = getPageSiblings(context.section(), pageId);
    if (siblings) {
      var curPageIndex = siblings.findIndex((s) => s.id === pageId);
      var newPageIndex =
        direction === "down" ? curPageIndex + 1 : curPageIndex - 1;
      var noMove =
        siblings.length < 2 ||
        newPageIndex < 0 ||
        newPageIndex > siblings.length - 1;
      if (noMove) {
        context.selectPageHandler(pageId);
        moveNotAllowed.setIsOpen(true);
      } else {
        var swapPage = siblings[newPageIndex];
        siblings[newPageIndex] = siblings[curPageIndex];
        siblings[curPageIndex] = swapPage;
        context.selectPageHandler(pageId);
        context.refreshNavElements();
      }
    }
  };

  return {
    MovePageDialog,
  };
};
