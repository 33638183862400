export const LinkEditorComponentStrings = {
  UpdateTitleBarText: "Edit link",
  InsertTitleBarText: "Add link",
  OkButtonOnInsertLabel: "Add",
  OkButtonOnUpdateLabel: "Edit",
  CancelButtonLabel: "Cancel",
  TextInputLabel: "Link text",
  TextInputHelpText:
    "Choose the 2-5 words of consent text that most clearly describe link destination",
  TextInputRequiredText: "Link text can't be blank",
  UrlInputLabel: "URL",
  UrlInputHelpText: "Webpage you want patients to visit",
  UrlInputInvalidText: "URL starting with http:// or https:// is required",
  LinkTooltip:
    "Links may be updated by clicking on the Link Editor button when the cursor is within the text. Links may be deleted by highlighting the text and pressing the delete key.",
};
