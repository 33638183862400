import { useEffect, useState } from "react";
import {
  FixedSizeContainer,
  RequiredInputIndicator,
  SelectInput,
} from "@intouchhealth/mfe-library";
import { TemplateType, useGetTemplateTypesQuery } from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";

export const useTemplateTypeInput = (
  initialTemplateType: string,
  disabled: boolean,
) => {
  const [selectedTemplateType, setSelectedTemplateType] =
    useState<Object | null>(null);

  const [templateTypeList, setTemplateTypeList] = useState<TemplateType[]>([
    { name: "", type: "" },
  ]);
  const { loading, data } = useGetTemplateTypesQuery();

  useEffect(() => {
    let list = data
      ? (data.dynamicTemplateTypes.map((t: TemplateType) => {
          return {
            name: t.name,
            type: t.type,
          };
        }) as TemplateType[])
      : [];
    setTemplateTypeList(list);

    if (list?.length > 0) {
      var selectedObject =
        list.find((t) => t.type === initialTemplateType) ?? null;
      if (selectedObject) {
        setSelectedTemplateType(selectedObject);
      }
    }
    // eslint-disable-next-line
  }, [data, loading]);

  const TemplateTypeInput = () => {
    return (
      <>
        <FixedSizeContainer width="25%" marginRight="20px">
          <RequiredInputIndicator hidden={disabled}>
            <SelectInput
              accessor="name"
              selectedValue={
                loading ? { name: "", type: "" } : selectedTemplateType
              }
              setSelectedValue={setSelectedTemplateType}
              optionList={templateTypeList}
              label={TemplateFormStrings.DetailsTypeLabel}
              helpText={TemplateFormStrings.DetailsTypeHelpText}
              disabled={disabled}
            />
          </RequiredInputIndicator>
        </FixedSizeContainer>
      </>
    );
  };

  return {
    TemplateTypeInput,
    selectedTemplateType,
    setSelectedTemplateType,
  };
};
