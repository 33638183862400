import { PureInput } from "@intouchhealth/cig-components";
import { observer } from "mobx-react-lite";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import {
  PanelContainer,
  RequiredInputIndicator,
  hasValue,
  VerticalSpacer,
  TranslationManagerContext,
  FixedSizeContainer,
} from "@intouchhealth/mfe-library";
import { useEndActionInput } from "./EndActionInput";
import {
  DynamicTemplateByTag,
  DynamicTemplateType,
  EndActionType,
  TemplateType,
  useGetTemplateByTagQuery,
} from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { useTemplateTypeInput } from "./TemplateTypeInput";
import { useDefaultLanguageInput } from "./DefaultLanguageInput";
import { DesignerTodoContext } from "./DesignTemplateForm";
import { sleep } from "../../app/utils/sleep";

const translationEnabled =
  process.env.REACT_APP_FEATURE_TOGGLES?.includes("GAV3");

export const DetailsSectionContext = createContext({
  isValidated: false,
  initialized: false,
  updateFields: (_dynamicTemplate: DynamicTemplateType) => {},
  validateTemplateTag: async (): Promise<Boolean> => {
    return true;
  },
});

export type Props = {
  isCreate: boolean;
  isImport: boolean;
  dynamicTemplate?: DynamicTemplateType;
  setEnabled?: Function;
};

export function DetailsSection({
  isCreate,
  isImport,
  dynamicTemplate,
  setEnabled,
}: Props) {
  return (
    <PanelContainer title={TemplateFormStrings.DetailsSectionTitle}>
      <DetailsSectionBase
        isCreate={isCreate}
        isImport={isImport}
        dynamicTemplate={dynamicTemplate}
        setEnabled={setEnabled}
      />
    </PanelContainer>
  );
}

export function DetailsSectionBase({
  isCreate,
  isImport,
  dynamicTemplate,
  setEnabled,
}: Props) {
  const [name, setName] = useState<string>(dynamicTemplate?.name ?? "");
  const [templateTag, setTemplateTag] = useState<string>(
    dynamicTemplate?.tag?.tagName ?? "",
  );
  const [templateTagQueryValue, setTemplateTagQueryValue] =
    useState<string>("");
  const [templateTagError, setTemplateTagError] = useState(false);
  const [templateType, setTemplateType] = useState<string>(
    dynamicTemplate?.type ?? "",
  );
  const [endAction, setEndAction] = useState<string>(
    dynamicTemplate?.endAction ?? "",
  );
  const [defaultLanguageCode, setDefaultLanguageCode] = useState<string>(
    dynamicTemplate?.defaultLanguageCode ?? "",
  );
  const context = useContext(TranslationManagerContext);
  const designerContext = useContext(DesignerTodoContext);

  const { TemplateTypeInput, selectedTemplateType } = useTemplateTypeInput(
    templateType,
    !isCreate,
  );

  const [, { loading, data, called, refetch: getTemplateByTagName }] =
    useGetTemplateByTagQuery(templateTagQueryValue ?? "");
  const dataRef = useRef<{ dynamicTemplateByTag: DynamicTemplateByTag } | null>(
    null,
  );

  const {
    EndActionInput,
    selectedEndAction,
    endActionTemplateType,
    setEndActionTemplateType,
  } = useEndActionInput(templateType, endAction, !isCreate);

  const { DefaultLanguageInput, selectedLanguage } =
    useDefaultLanguageInput(defaultLanguageCode);

  // Connect state to the components context
  const detailsContext = useContext(DetailsSectionContext);
  detailsContext.isValidated =
    hasValue(name) && hasValue(templateType) && hasValue(endAction);

  if (setEnabled) {
    setTimeout(() =>
      setEnabled(detailsContext.isValidated && !!defaultLanguageCode),
    );
  }

  const processTempleteTagText = useCallback((text: string) => {
    setTemplateTagError(false);
    return text.toUpperCase().replace(/[^A-Z0-9_-]/g, "");
  }, []);

  detailsContext.updateFields = (dynamicTemplate: DynamicTemplateType) => {
    dynamicTemplate.name = name;
    dynamicTemplate.tag = templateTag ? { tagName: templateTag } : null;
    dynamicTemplate.type = templateType;
    dynamicTemplate.endAction = endAction;

    if (dynamicTemplate.defaultLanguageCode !== defaultLanguageCode) {
      dynamicTemplate.defaultLanguageCode = defaultLanguageCode;
      context.changeDefaultLanguage(defaultLanguageCode);
      designerContext.refreshDesigner();
    }
  };

  detailsContext.validateTemplateTag = async () => {
    if (!templateTag) {
      return true;
    }

    setTemplateTagQueryValue("");
    setTimeout(() => {
      setTemplateTagQueryValue(templateTag);
      getTemplateByTagName();
    });

    while (!dataRef.current) {
      await sleep(1);
    }

    const error =
      dataRef.current?.dynamicTemplateByTag !== null &&
      dataRef.current?.dynamicTemplateByTag?.tag?.tagName === templateTag &&
      dataRef.current.dynamicTemplateByTag.templateId !==
        dynamicTemplate?.templateId;
    setTemplateTagError(error);

    dataRef.current = null;
    return !error;
  };

  detailsContext.initialized = true;

  useEffect(() => {
    if (data && !loading && called) {
      dataRef.current = data;
    }
  }, [loading, called, data]);

  useEffect(() => {
    const dtType = selectedTemplateType as TemplateType;
    if (dtType) {
      setTemplateType(dtType.type);
      if (endActionTemplateType !== dtType.type) {
        setEndAction("");
        setEndActionTemplateType(dtType.type);
      }
    }
  }, [selectedTemplateType, endActionTemplateType, setEndActionTemplateType]);

  useEffect(() => {
    const dtEndAction = selectedEndAction as EndActionType;
    if (dtEndAction) {
      setEndAction(dtEndAction.endAction);
    }
  }, [selectedEndAction]);

  useEffect(() => {
    setDefaultLanguageCode(selectedLanguage?.languageCode || "");
  }, [selectedLanguage]);

  return (
    <DetailsWrapper>
      <DetailsContainer>
        <InputContainer width="calc(50% - 45px)" marginRight="20px">
          <RequiredInputIndicator>
            <PureInput
              label={TemplateFormStrings.DetailsNameLabel}
              value={name}
              onChange={(value) => setName(value)}
              className="sls-input"
              invalid={!hasValue(name)}
              infoMessage={
                !hasValue(name)
                  ? `${TemplateFormStrings.DetailsNameLabel} is required`
                  : TemplateFormStrings.DetailsNameHelpText
              }
            />
          </RequiredInputIndicator>
        </InputContainer>
        <TemplateTypeInput />
        <EndActionInput />
        {translationEnabled && (
          <>
            <VerticalSpacer />
            <DefaultLanguageInput />
          </>
        )}
      </DetailsContainer>
      <DetailsContainer>
        {!isImport && (
          <>
            <FixedSizeContainer width="calc(50% - 45px)" marginRight="20px">
              <PureInput
                label={TemplateFormStrings.TemplateTagLabel}
                value={templateTag}
                onChange={(value) =>
                  setTemplateTag(processTempleteTagText(value))
                }
                className="sls-input"
                invalid={templateTagError}
                infoMessage={
                  templateTagError
                    ? TemplateFormStrings.TemplateTagErrorLabel
                    : TemplateFormStrings.TemplateTagHelpText
                }
              />
            </FixedSizeContainer>
          </>
        )}
      </DetailsContainer>
    </DetailsWrapper>
  );
}

export default observer(DetailsSection);

const DetailsWrapper = styled.div`
  & > div:not(:last-of-type) {
    margin-bottom: 10px;
  }
`;

const DetailsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

interface StylingProps {
  width: string;
  marginRight?: string;
}

const InputContainer = styled.div<StylingProps>`
  width: ${({ width }: { width: string }) => width};
  margin-right: ${({ marginRight }: { marginRight?: string }) =>
    marginRight ?? "0px"};
`;
