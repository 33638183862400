import React, { useContext, VFC } from "react";
import styled from "styled-components";
import {
  getAttribute,
  getComplexPageTitle,
  IPageDesign,
  pageHasBranching,
} from "../../app/stores/templateDesignStore";
import {
  MouseEventPosition,
  uniqueName,
  FlexRowCenteredStyle,
} from "@intouchhealth/mfe-library";
import { FlowGroupTitleBar } from "./FlowGroupTitleBar";
import { FlowNavigatorContext } from "./FlowNavigator";
import { useFlowPageMenu } from "../flowmenus/FlowPageMenu";
import { useFlowPageBranches } from "./FlowPageBranches";
import { FlowContextMenuButton } from "../flowworkspace/FlowContextMenuButton";

export const useFlowPage = () => {
  const optionMenu = useFlowPageMenu("navigator");
  const context = useContext(FlowNavigatorContext);
  const { FlowPageBranches } = useFlowPageBranches();

  const FlowPageItem: VFC<{
    page: IPageDesign;
  }> = ({ page }) => {
    const menuClickHandler = (event: React.MouseEvent) => {
      optionMenu.setMenuConfig({
        optionMenuHandler: (menuChoice) =>
          context.pageOptionMenuHandler(page.id, menuChoice),
        position: MouseEventPosition(event),
      });
      optionMenu.setIsOpen(true);
    };

    return (
      <>
        <StyledContainer
          id={"navgroup-" + page.id}
          borderColor={context.pageIsActive(page.id) ? "#1976d2" : "#C6C6C6"}
        >
          {!pageHasBranching(page) ? (
            <FlowGroupTitleBar
              id={page.id}
              title={
                getComplexPageTitle(page) ||
                getAttribute(page, "title", undefined, "").defaultText()
              }
              titleClickHandler={() => context.selectPageHandler(page.id)}
              menuClickHandler={menuClickHandler}
            />
          ) : (
            <>
              <FlexRowCenteredStyle
                style={{
                  marginTop: "-5px",
                  marginBottom: "-5px",
                  marginLeft: "calc(100% - 16px)",
                }}
              >
                <FlowContextMenuButton
                  menuClickHandler={menuClickHandler}
                ></FlowContextMenuButton>
              </FlexRowCenteredStyle>
              <FlowPageBranches page={page} />
            </>
          )}
        </StyledContainer>
        <optionMenu.PopupMenu />
      </>
    );
  };

  const FlowPage: VFC<{
    pages: IPageDesign[];
  }> = ({ pages }) => {
    return (
      <>
        {pages.map((p) => {
          return <FlowPageItem key={uniqueName("flow-page")} page={p} />;
        })}
      </>
    );
  };

  return {
    FlowPage,
  };
};

const StyledContainer = styled.div<{ borderColor: string }>`
  padding: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  width: calc(100% - 16px);
  border-radius: 10px;
  border: ${({ borderColor }: { borderColor: string }) => borderColor} solid 2px;
  font-family: "Medium", sans-serif;
  color: #1976d2;
  background-color: #ffffff;
`;
