export const ImportUrlIcon = () => {
  return (
    <div
      style={{
        marginLeft: "1px",
        marginRight: "-2px",
        width: "28px",
        height: "24px",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 21 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.87329 15.2132C9.38001 15.3561 9.86075 15.0832 10.0167 14.557L13.7066 1.91502C13.856 1.38231 13.6027 0.895081 13.096 0.745664C12.5957 0.602743 12.115 0.882088 11.9591 1.4018L8.26913 14.0503C8.11971 14.5765 8.37307 15.0637 8.87329 15.2132ZM16.0193 12.8874L20.6448 8.70376C21.0995 8.29449 21.0995 7.65784 20.6448 7.24857L16.0193 3.07138C15.6101 2.70108 15.0579 2.71407 14.7071 3.10386C14.3563 3.49364 14.3952 4.04584 14.7915 4.40964L18.7348 7.97616L14.7915 11.5427C14.3952 11.9065 14.3563 12.4587 14.7071 12.8485C15.0579 13.2383 15.6101 13.2577 16.0193 12.8874ZM5.95641 12.8939C6.35918 13.2642 6.91787 13.2512 7.26868 12.855C7.61949 12.4652 7.58051 11.913 7.17773 11.5492L3.23441 7.98266L7.17773 4.42263C7.58051 4.05233 7.61949 3.50664 7.26868 3.11036C6.91787 2.72057 6.36568 2.70758 5.95641 3.07787L1.32447 7.25506C0.869721 7.66434 0.876217 8.30748 1.32447 8.71026L5.95641 12.8939Z"
          fill="black"
        />
      </svg>
    </div>
  );
};
