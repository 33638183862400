import styled from "styled-components";
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { TemplateFormStrings } from "./TemplateFormStrings";
import {
  ConfirmButton,
  DeleteButton,
  HorizontalSpacer,
  StyledTooltip,
} from "@intouchhealth/mfe-library";
import { DeleteIcon } from "@intouchhealth/cig-components";
import { Refresh as RefreshIcon } from "@mui/icons-material";
import { useDeleteTemplateProvider } from "./DeleteTemplateProvider";
import {
  DesignTemplateFormContext,
  DesignTemplateModeTypes,
} from "./DesignTemplateForm";
import { usePreviewTemplateButton } from "./PreviewTemplateButton";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import { TemplatePreviewContext } from "../templatepreview/TemplatePreviewContainer";
import { TemplatePreviewStrings } from "../templatepreview/TemplatePreviewStrings";

export type Props = {
  templateId?: string;
  templateName?: string;
  mode?: DesignTemplateModeTypes;
  isChanged?: () => boolean;
  setIsUpdated: Dispatch<SetStateAction<boolean>>;
};

export function TemplateFormButtons({
  templateId,
  templateName,
  mode,
  setIsUpdated,
}: Props) {
  const { DeleteTemplateProvider, handleDelete } = useDeleteTemplateProvider();
  const { PreviewButton, hasPreview } = usePreviewTemplateButton(templateId);
  const flowNavigator = useContext(FlowNavigatorContext);
  const previewContext = useContext(TemplatePreviewContext);
  const designForm = useContext(DesignTemplateFormContext);
  const [allowRestart, setAllowRestart] = useState(false);

  const [previewTemplateId, setPreviewTemplateId] = useState<
    string | undefined
  >();

  flowNavigator.previewNotification?.subscribe(
    `previewNotificationButtons-${templateId}`,
    setPreviewTemplateId,
  );

  const restartTemplatePreview = useCallback(() => {
    if (previewTemplateId) {
      previewContext.restartPreview();
    }
    //eslint-disable-next-line
  }, [previewTemplateId]);

  const getPreviewShadowRoot = useCallback(() => {
    return document.querySelector("#TemplatePreviewShadow")?.shadowRoot;
    //eslint-disable-next-line
  }, [previewTemplateId]);

  useEffect(() => {
    if (previewTemplateId) {
      const checkBackAllowed = () => {
        const shadowRoot = getPreviewShadowRoot();
        setAllowRestart(
          !!shadowRoot?.querySelector(
            ".root-web-sdk button[data-testid=button-back]",
          ),
        );
      };

      const checkEndOfTemplate = () => {
        const shadowRoot = getPreviewShadowRoot();
        const button = shadowRoot?.querySelector(
          "button[name=dt_continue]",
        ) as HTMLButtonElement;
        if (
          button &&
          button.outerText ===
            TemplatePreviewStrings.PreviewLastPageContinueButtonText
        ) {
          button.addEventListener("click", (e) => {
            e.stopPropagation();
            flowNavigator.previewNotification?.publish(undefined);
            designForm.resetToFirstPage();
          });
        }
      };

      const domNodeInsertedHandler = () => {
        checkBackAllowed();
        checkEndOfTemplate();
      };

      document.body.addEventListener("DOMNodeInserted", domNodeInsertedHandler);
      return () => {
        document.body.removeEventListener(
          "DOMNodeInserted",
          domNodeInsertedHandler,
        );
      };
    }
    //eslint-disable-next-line
  }, [previewTemplateId, getPreviewShadowRoot]);

  return (
    <ButtonLayoutContainer>
      <ButtonListContainer>
        {!templateId ? (
          <></>
        ) : (
          <>
            <StyledTooltip title={TemplateFormStrings.DeleteButtonTooltip}>
              <div>
                <DeleteButton
                  size="xs"
                  icon={<DeleteIcon />}
                  hidden={false}
                  onClick={() => {
                    handleDelete(templateId, templateName);
                  }}
                />
              </div>
            </StyledTooltip>
            <HorizontalSpacer />
            <PreviewButton />
            <HorizontalSpacer />
          </>
        )}
        {!hasPreview ? (
          <ConfirmButton
            size="xs"
            onClick={(): void | Promise<void> => {
              setIsUpdated(true);
            }}
          >
            {mode === "CREATE"
              ? TemplateFormStrings.CreateButtonLabel
              : mode === "UPDATE"
                ? TemplateFormStrings.SaveButtonLabel
                : TemplateFormStrings.CopyButtonLabel}
          </ConfirmButton>
        ) : (
          <div>
            <ConfirmButton
              size="xs"
              wide={true}
              icon={<RefreshIcon />}
              iconPosition="left"
              onClick={restartTemplatePreview}
              disabled={!allowRestart}
            >
              {TemplateFormStrings.PreviewRestartLabel}
            </ConfirmButton>
          </div>
        )}
      </ButtonListContainer>
      <DeleteTemplateProvider />
    </ButtonLayoutContainer>
  );
}

const ButtonLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

const ButtonListContainer = styled.div`
  width: 250px;
  padding-left: 15px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  flex-wrap: nowrap;
`;
