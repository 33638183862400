import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { InitialDefaultLanguage } from "../../app/stores/translationOptionsStore";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { AddTemplateProvider } from "./AddTemplateProvider";
import { DesignTemplateForm } from "./DesignTemplateForm";
import { DynamicTemplateType } from "./TemplateFormQueries";
import {
  ISectionDesign,
  ITemplateDesign,
  newTemplateSection,
} from "../../app/stores/templateDesignStore";

const CreateTemplateLayout: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);
  const [isUpdated, setIsUpdated] = useState(false);
  const [updatedTemplate, setUpdatedTemplate] = useState<ITemplateDesign>();

  const dynamicTemplate = {
    organizationId: commonStore.organizationId,
    practiceId: commonStore.practiceId,
    defaultLanguageCode: InitialDefaultLanguage,
    flow: {
      sections: [newTemplateSection()] as ISectionDesign[],
    },
  } as DynamicTemplateType;

  useEffect(() => {
    if (updatedTemplate) {
      setIsUpdated(true);
    }
  }, [updatedTemplate]);

  return (
    <>
      <DesignTemplateForm
        dynamicTemplate={dynamicTemplate}
        mode={"CREATE"}
        setUpdatedTemplate={setUpdatedTemplate}
      />
      <AddTemplateProvider
        dynamicTemplate={updatedTemplate}
        updated={isUpdated}
        setUpdated={setIsUpdated}
        onCreated={() => {
          commonStore.history.push(`${commonStore.rootPath}?notify=created`);
        }}
      ></AddTemplateProvider>
    </>
  );
};

const CreateTemplateForm: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);

  useEffect(() => {
    commonStore.setBreadCrumbs([
      {
        text: TemplateFormStrings.DynamicTemplatesBreadCrumb,
        link: "./dynamic-template",
        icon: undefined,
      },
    ]);
    commonStore.setHeader(TemplateFormStrings.PageHeaderCreate);
  });

  return <CreateTemplateLayout />;
};

export default observer(CreateTemplateForm);
