import { useContext, useState } from "react";
import { mui } from "@intouchhealth/cig-components";
import {
  ArrowUpward,
  ArrowDownward,
  AltRoute,
  RemoveCircleOutline,
  Code,
  ContentCopy,
} from "@mui/icons-material";
import { DeleteIcon, AddNewIcon } from "@intouchhealth/cig-components";
import { IPosition } from "@intouchhealth/mfe-library";
import { DesignerTodoContext } from "../templatesform/DesignTemplateForm";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import { FlowMenuStrings } from "./FlowMenuStrings";
import {
  getAttribute,
  getComplexPageTitle,
} from "../../app/stores/templateDesignStore";

export type FlowMenuControlChoices =
  | "none"
  | "addBranch"
  | "removeBranch"
  | "moveItemUp"
  | "moveItemDown"
  | "refCategory"
  | "metaData"
  | "deleteItem"
  | "duplicateItem";

export type FlowContextMenuConfig = {
  optionMenuHandler: (menuChoice: FlowMenuControlChoices) => void;
  position: IPosition;
};

const MenuIconStyle = {
  height: "17px",
  width: "17px",
  marginTop: "-2px",
  paddingRight: "4px",
  color: "#ADA8A4",
};

export const useFlowMenuControl = (
  includedMenuItems: FlowMenuControlChoices[],
) => {
  const { Menu, MenuItem, Box } = mui;
  const [menuConfig, setMenuConfig] = useState<FlowContextMenuConfig>({
    optionMenuHandler: (_menuChoice: FlowMenuControlChoices) => {},
    position: { top: 0, left: 0 },
  });
  const [isOpen, setIsOpen] = useState(false);
  const [hasBranch, setHasBranch] = useState(false);

  const context = useContext(FlowNavigatorContext);
  const todo = useContext(DesignerTodoContext);
  const todoMenuItem = (
    menuChoice: FlowMenuControlChoices,
    pageId?: string,
    elementId?: string,
  ) => {
    var section = context.section();
    var sectionName = section?.name;
    var page = pageId ? context.page(section, pageId) : undefined;
    var pageTitle = page
      ? getComplexPageTitle(page) ||
        getAttribute(page, "title", undefined, "").defaultText()
      : "";
    var subPage = page?.subpages.find((e) => e.id === elementId);
    var elementType = subPage?.type;
    todo.setMessageContent(
      <>
        Context menu option is not implemented yet.
        <br />
        MenuChoice: {menuChoice}
        <br />
        Section: {sectionName}
        <br />
        <div hidden={!pageId}>
          Page: {pageTitle}
          <br />
        </div>
        <div hidden={!elementId}>Element: {elementType}</div>
      </>,
    );
    todo.setIsOpen(true);
  };

  const handleMenuSelection = (menuChoice: FlowMenuControlChoices) => {
    setIsOpen(false);
    if (menuChoice !== "none") {
      menuConfig.optionMenuHandler(menuChoice);
    }
  };

  const PopupMenu = () => {
    return (
      <>
        <Menu
          onBackdropClick={() => {
            handleMenuSelection("none");
          }}
          anchorReference="anchorPosition"
          anchorPosition={menuConfig.position}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          open={isOpen}
        >
          <Box hidden={!includedMenuItems.includes("addBranch") || hasBranch}>
            <MenuItem
              onClick={() => {
                handleMenuSelection("addBranch");
              }}
            >
              <AltRoute style={MenuIconStyle} />
              {FlowMenuStrings.AddBranchMenuItem}
            </MenuItem>
          </Box>
          <Box
            hidden={!includedMenuItems.includes("removeBranch") || !hasBranch}
          >
            <MenuItem
              onClick={() => {
                handleMenuSelection("removeBranch");
              }}
            >
              <RemoveCircleOutline style={MenuIconStyle} />
              {FlowMenuStrings.RemoveBranchMenutItem}
            </MenuItem>
          </Box>
          <Box hidden={!includedMenuItems.includes("moveItemUp")}>
            <MenuItem
              onClick={() => {
                handleMenuSelection("moveItemUp");
              }}
            >
              <ArrowUpward style={MenuIconStyle} />
              {FlowMenuStrings.MoveUpMenuItem}
            </MenuItem>
          </Box>
          <Box hidden={!includedMenuItems.includes("moveItemDown")}>
            <MenuItem
              onClick={() => {
                handleMenuSelection("moveItemDown");
              }}
            >
              <ArrowDownward style={MenuIconStyle} />
              {FlowMenuStrings.MoveDownMenuItem}
            </MenuItem>
          </Box>
          <Box hidden={!includedMenuItems.includes("refCategory")}>
            <MenuItem
              onClick={() => {
                handleMenuSelection("refCategory");
              }}
            >
              <AddNewIcon style={MenuIconStyle} fill={MenuIconStyle.color} />
              {FlowMenuStrings.ReferenceCategoryMenuItem}
            </MenuItem>
          </Box>
          <Box hidden={!includedMenuItems.includes("metaData")}>
            <MenuItem
              onClick={() => {
                handleMenuSelection("metaData");
              }}
            >
              <Code style={MenuIconStyle} />
              {FlowMenuStrings.MetaDataMenuItem}
            </MenuItem>
          </Box>
          <Box hidden={!includedMenuItems.includes("duplicateItem")}>
            <MenuItem
              onClick={() => {
                handleMenuSelection("duplicateItem");
              }}
            >
              <ContentCopy style={MenuIconStyle} />
              {FlowMenuStrings.DuplicateMenuItem}
            </MenuItem>
          </Box>
          <Box hidden={!includedMenuItems.includes("deleteItem")}>
            <MenuItem
              onClick={() => {
                handleMenuSelection("deleteItem");
              }}
            >
              <DeleteIcon style={MenuIconStyle} fill={MenuIconStyle.color} />
              {FlowMenuStrings.DeleteMenuItem}
            </MenuItem>
          </Box>
        </Menu>
      </>
    );
  };

  return {
    PopupMenu,
    isOpen,
    setIsOpen,
    menuConfig,
    setMenuConfig,
    todoMenuItem,
    setHasBranch,
  };
};
