import { RootStore } from "./rootStore";
import { observable, action, makeObservable } from "mobx";
import { Breadcrumb } from "../../serviceInterface";
import { BrowserHistory } from "history";
import { AppVersion } from "./appversion";

function getParentPath(rootPath: string) {
  const mfeRootLevels = [
    "service-line",
    "dynamic-template",
    "template-preview",
    "insurance-payment",
  ];

  for (var i = 0; i < mfeRootLevels.length; i++) {
    var matchPos = (rootPath + "/").lastIndexOf("/" + mfeRootLevels[i] + "/");
    if (matchPos > -1) {
      return rootPath.substring(0, matchPos);
    }
  }
  return "/";
}

export default class CommonStore {
  rootStore: RootStore;
  storeInitialized = false;
  appLoaded = false;
  history: BrowserHistory;
  organizationId!: string;
  practiceId!: string;
  hostPath: string = "";
  rootPath: string = "";
  parentPath: string = "";
  appTop: number = 0;
  appLeft: number = 0;
  setBreadCrumbs: (breadcrumbs: Breadcrumb[]) => void = () => {};
  setHeader: (title: string) => void = () => {};
  idpToken: () => Promise<string> = () =>
    new Promise<string>((resolve) => {
      resolve("");
    });

  constructor(rootStore: RootStore) {
    this.history = {} as BrowserHistory;
    this.rootStore = rootStore;
    this.hostPath = window.location.pathname.substring(
      0,
      window.location.pathname.lastIndexOf("/"),
    );
    this.rootPath = window.location.pathname
      .replace("/superadmin", "")
      .replace(/dynamic-template\/.*/, "dynamic-template/");
    this.parentPath = getParentPath(this.rootPath);
    var startUpValues = {
      logType: "Startup Values",
      version: AppVersion,
      hostPath: this.hostPath,
      rootPath: this.rootPath,
      location: window.location,
    };
    console.log(startUpValues);

    makeObservable(this, {
      appLoaded: observable,
      setAppLoaded: action,
    });
  }

  setStoreInitialized = () => (this.storeInitialized = true);
  setAppLoaded = () => (this.appLoaded = true);
  setAppLocation = (top: number, left: number) => {
    this.appTop = top;
    this.appLeft = left;
  };
  setHistory = (history: any) => (this.history = history);
  setOrganizationId = (organizationId: string) =>
    (this.organizationId = organizationId);
  setPracticeId = (practiceId: string) => (this.practiceId = practiceId);
  InitializeBreadCrumbsSetter = (func: (breadcrumbs: Breadcrumb[]) => void) =>
    (this.setBreadCrumbs = func);
  InitializeHeaderSetter = (func: (title: string) => void) =>
    (this.setHeader = func);
  InitializeIdpTokenFunc = async (func: () => Promise<string>) =>
    (this.idpToken = func);
}
