export const DashboardStrings = {
  PageHeader: "Forms",
  NameColumn: "Title",
  CreatedAtColumn: "Created",
  StatusColumn: "Status",
  StatusButtonEnabled: "Enabled",
  StatusButtonDisabled: "Disabled",
  CreateButton: "Create",
  FetchingErrorMessage:
    "We couldn't load your forms. Check your connection and try again.",
  SeeConsoleLogMessage:
    "If the problem persists, contact your tech support team or administrator. Tell them to look for details in the console log.",
  SavedAlertTitle: "Saved",
  SavedAlertMessage: "Form changes have been saved.",
  CreatedAlertTitle: "Created",
  CreatedAlertMessage: "Form has been created.",
  DeletedAlertTitle: "Deleted",
  DeletedAlertMessage: "Form has been deleted.",
  CancelAlertTitle: "Cancelled",
  CancelAlertMessage: "Form creation cancelled.",
};
