import { useEffect, useState } from "react";
import {
  FixedSizeContainer,
  RequiredInputIndicator,
  SelectInput,
} from "@intouchhealth/mfe-library";
import { EndActionType, useGetEndActionsQuery } from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";

export const useEndActionInput = (
  initialTemplateType: string,
  initialEndAction: string,
  disabled: boolean,
) => {
  const [endActionTemplateType, setEndActionTemplateType] =
    useState(initialTemplateType);
  const [selectedEndAction, setSelectedEndAction] = useState<Object | null>(
    null,
  );

  const [endActionList, setEndActionList] = useState<EndActionType[]>([]);
  const { loading, data, refetch } = useGetEndActionsQuery(
    endActionTemplateType,
  );

  useEffect(() => {
    let list = data
      ? (data.dynamicTemplateEndActionsByTemplateType.map(
          (t: EndActionType) => {
            return {
              name: t.name,
              endAction: t.endAction,
            };
          },
        ) as EndActionType[])
      : [];
    setEndActionList(list);

    if (list?.length > 0) {
      var selectedObject =
        list.find((t) => t.endAction === initialEndAction) ?? null;
      if (selectedObject) {
        setSelectedEndAction(selectedObject);
      }
    }
    // eslint-disable-next-line
  }, [data, loading]);

  useEffect(() => {
    refetch();
    setSelectedEndAction(null);
  }, [endActionTemplateType, refetch, setEndActionTemplateType]);

  const EndActionInput = () => {
    return (
      <>
        <FixedSizeContainer width="25%">
          <RequiredInputIndicator hidden={disabled}>
            <SelectInput
              accessor="name"
              selectedValue={
                loading ? { name: "", endAction: "" } : selectedEndAction
              }
              setSelectedValue={setSelectedEndAction}
              optionList={endActionList}
              label={TemplateFormStrings.DetailsEndActionLabel}
              helpText={TemplateFormStrings.DetailsEndActionHelpText}
              disabled={disabled}
            />
          </RequiredInputIndicator>
        </FixedSizeContainer>
      </>
    );
  };

  return {
    EndActionInput,
    selectedEndAction,
    setSelectedEndAction,
    endActionTemplateType,
    setEndActionTemplateType,
  };
};
