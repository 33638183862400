export const ForkIcon = () => {
  return (
    <div
      style={{
        marginLeft: "3px",
        marginRight: "-3px",
        width: "24px",
        height: "24px",
      }}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10 0L12.29 2.29L9.41 5.17L10.83 6.59L13.71 3.71L16 6V0H10ZM6 0H0V6L2.29 3.71L7 8.41V16H9V7.59L3.71 2.29L6 0Z"
          fill="#302E2C"
        />
      </svg>
    </div>
  );
};
