import {
  FlowMenuControlChoices,
  useFlowMenuControl,
} from "../../flowmenus/FlowMenuControl";

export const useAnswerComponentMenu = (allowBranching: boolean) => {
  const activeMenuItems = () => {
    var items = [] as FlowMenuControlChoices[];
    if (allowBranching) {
      items.push("addBranch", "removeBranch");
    }
    items.push(
      "refCategory",
      "metaData",
      "moveItemUp",
      "moveItemDown",
      "deleteItem",
    );
    return items;
  };

  const {
    PopupMenu,
    isOpen,
    setIsOpen,
    menuConfig,
    setMenuConfig,
    setHasBranch,
  } = useFlowMenuControl(activeMenuItems());

  return {
    PopupMenu,
    isOpen,
    setIsOpen,
    menuConfig,
    setMenuConfig,
    setHasBranch,
  };
};
