import { useMessageDialog } from "@intouchhealth/mfe-library";
import { ReactNode, useState } from "react";
import styled from "styled-components";
import {
  getTemplateElementTypesInfo,
  ITemplateElementTypeInfo,
} from "../../app/stores/elementTypesStore";
import { TemplateCategoryTypes } from "../../app/stores/TemplateCategoryTypes";
import { v4 as uuidv4 } from "uuid";
import { ClearIcon } from "@intouchhealth/cig-components";
import { FlowAddElementMenuStrings } from "./FlowAddElementMenuStrings";

interface IMenuElementCategoryProps {
  title?: string;
  children?: ReactNode;
  includeFootNotes?: boolean;
}

export const useFlowAddElementMenu = () => {
  const [elementSelection, setElementSelection] = useState<
    ITemplateElementTypeInfo | undefined
  >(undefined);

  const QuestionCategory = useMenuElementCategory(
    "QUESTION",
    setElementSelection,
  );
  const ComplexPageCategory = useMenuElementCategory(
    "COMPLEX_PAGE",
    setElementSelection,
  );
  const ContentCategory = useMenuElementCategory(
    "CONTENT",
    setElementSelection,
  );
  const { ...addElementDialog } = useMessageDialog();
  const hasComplexPageTypes =
    getTemplateElementTypesInfo().filter((t) => t.category === "COMPLEX_PAGE")
      .length > 0;

  const PopupMenu = () => (
    <addElementDialog.ModalDialog
      type={undefined}
      title={undefined}
      closeButtonText={undefined}
      position="appcontainer"
      top={"auto"}
    >
      <ModalDialogStyle>
        <ClearIconStyle>
          <ClearIcon
            tabIndex={-1}
            onClick={() => addElementDialog.setIsOpen(false)}
          ></ClearIcon>
        </ClearIconStyle>
        <AddElementContainer>
          <AddElementGroup>
            <QuestionCategory
              title={FlowAddElementMenuStrings.AddQuestionTitle}
            />
            {hasComplexPageTypes ? (
              <ComplexPageCategory includeFootNotes={true}>
                <p>{FlowAddElementMenuStrings.AddsNewPageText}</p>
              </ComplexPageCategory>
            ) : (
              <></>
            )}
          </AddElementGroup>
          <AddElementGroup>
            <ContentCategory
              title={FlowAddElementMenuStrings.AddContentTitle}
            />
          </AddElementGroup>
        </AddElementContainer>
      </ModalDialogStyle>
    </addElementDialog.ModalDialog>
  );

  return {
    PopupMenu,
    isOpen: addElementDialog.isOpen,
    setIsOpen: addElementDialog.setIsOpen,
    elementSelection,
  };
};

const useMenuElementCategory = (
  category: TemplateCategoryTypes,
  setElementSelection: (info: ITemplateElementTypeInfo) => void,
) => {
  const elementTypesInfo = getTemplateElementTypesInfo().filter(
    (t) =>
      !t.featureToggle ||
      process.env.REACT_APP_FEATURE_TOGGLES?.includes(t.featureToggle),
  );

  return (props: IMenuElementCategoryProps) => (
    <VerticalColumnStyle>
      <TitleHeader>
        <span className="header">
          <p>{props.title}</p>
        </span>
      </TitleHeader>
      {elementTypesInfo
        .map((info) => {
          return info.category === category ? (
            <span
              key={uuidv4()}
              onClick={() => setElementSelection(info)}
              onKeyDown={() => setElementSelection(info)}
              aria-hidden="true"
            >
              {info.icon && (
                <IconBorder>
                  <IconContainer>
                    <info.icon />
                  </IconContainer>
                </IconBorder>
              )}
              <div>{info.name + (info.footNoteKey ?? "")}</div>
            </span>
          ) : undefined;
        })
        .filter((info) => info)}
      {props.children && <span className="unclickable">{props.children}</span>}
    </VerticalColumnStyle>
  );
};

const ModalDialogStyle = styled.div`
  padding: 7px;
  margin-right: 13px;
`;

const ClearIconStyle = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;

  & svg {
    fill: #ada8a4;
  }
`;

const TitleHeader = styled.div`
  min-height: 40px;
`;

const AddElementContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const AddElementGroup = styled.div`
  display: flex;
  align-items: flex-start;
  height: fit-content;
  grid-gap: 50px;
  gap: 50px;
  margin: 10px;
  padding: 7px;
  border-radius: 3px;
  background-color: #f5f5f5;
  flex-direction: row;
`;

const VerticalColumnStyle = styled.div`
  display: grid;
  grid-template-columns: auto;
  padding-right: 20px;
  font-size: 13px;
  & * {
    display: flex;
    margin: 0;
    flex-grow: 0;
    color: black;
  }

  & .header {
    margin-top: 0;
    font-size: 1.25rem;
    font-weight: bold;
    padding: 0;
  }

  & p {
    white-space: nowrap;
  }

  & svg {
    margin-right: 5px;
  }

  & span {
    display: inline-flex;
    align-items: center;
    margin: 10px 10px 0 10px;
  }

  & span:not(.header) {
    margin: 10px;
  }

  & span:not(.unclickable):not(.header) {
    cursor: pointer;
  }
`;

const IconBorder = styled.div`
  background: #ffff;
  border: 2px solid lightgray;
  border-radius: 5px;
  padding: 2px;
  margin-top: -15px;
  margin-bottom: -15px;
  margin-right: 5px;
`;

const IconContainer = styled.div`
  background-color: transparent;
  height: 24px;
  width: 24px;
`;
