import { PureInput } from "@intouchhealth/cig-components";
import { useFormDialog } from "@intouchhealth/mfe-library";
import { useContext, useState } from "react";
import { IAnswer } from "../../app/stores/templateDesignStore";
import { FlowWorkspaceStrings } from "../flowworkspace/FlowWorkspaceStrings";
import { TemplateFormStrings } from "../templatesform/TemplateFormStrings";
import { FlowNavigatorContext } from "./FlowNavigator";

export const useMetaDataHandler = () => {
  const navigator = useContext(FlowNavigatorContext);
  const [answer, setAnswer] = useState<IAnswer | undefined>(undefined);
  const { ...form } = useFormDialog(
    TemplateFormStrings.MetaDataDialogTitle,
    TemplateFormStrings.OkButtonLabel,
    TemplateFormStrings.CancelButtonLabel,
  );

  const MetaDataDialog: React.VFC = () => {
    const [metaData, setMetaData] = useState<string>(answer?.metaData ?? "");

    const handleAccepted = () => {
      if (answer) {
        answer.metaData = metaData;
      }
      form.setIsOpen(false);
    };

    const handleCancelled = () => {
      form.setIsOpen(false);
    };

    navigator.metaDataHandler = (answer: IAnswer | undefined = undefined) => {
      setAnswer(answer);
      form.setIsOpen(true);
    };

    return (
      <form.FormDialog
        position="appcontainer"
        top={40}
        width={"75%"}
        onAccepted={handleAccepted}
        onCancelled={handleCancelled}
        acceptDisabled={false}
      >
        <PureInput
          label={FlowWorkspaceStrings.MetaDataLabel}
          value={metaData}
          onChange={(value) => {
            setMetaData(value);
          }}
          className="sls-input"
          infoMessage={FlowWorkspaceStrings.MetaDataHelpText}
        />
      </form.FormDialog>
    );
  };

  return {
    MetaDataDialog,
  };
};
