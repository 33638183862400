import { Dimmer, LoaderIcon } from "@intouchhealth/cig-components";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";
import {
  ErrorContainer,
  PageContainer,
  VerticalSpacer,
} from "@intouchhealth/mfe-library";
import { RootStoreContext } from "../../app/stores/rootStore";
import DetailsSection from "./DetailsSection";
import { TemplateFormButtons } from "./TemplateFormButtons";
import { useGetTemplateForAdminByIdQuery } from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { UpdateTemplateProvider } from "./UpdateTemplateProvider";

const UpdateTemplateDetailsLayout: React.FC = () => {
  const params = useParams<Params<string>>();
  const templateId = params.templateId ?? "";
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(true);
  const { commonStore } = useContext(RootStoreContext);
  const { loading, error, data, refetch } =
    useGetTemplateForAdminByIdQuery(templateId);

  useEffect(() => {
    if (!loading) {
      refetch();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && !error && data?.dynamicTemplateForAdminById) {
      setIsActive(data?.dynamicTemplateForAdminById?.isActive);
    }
  }, [loading, error, data]);

  if (loading) {
    return (
      <Dimmer
        header="Loading app..."
        icon={<LoaderIcon iconSize="xxl" sizing="relative" />}
      />
    );
  }

  if (error || !data?.dynamicTemplateForAdminById || !isActive) {
    if (error) {
      console.log(
        "Error Fetching in UpdateTemplate on templateId: " + templateId,
      );
      console.log(error);
    } else {
      console.log("Template not found for ID: " + templateId);
    }

    return (
      <ErrorContainer>
        <h4>{TemplateFormStrings.FetchingErrorMessage}</h4>
        {TemplateFormStrings.SeeConsoleLogMessage}
      </ErrorContainer>
    );
  }

  return (
    <>
      <PageContainer>
        <DetailsSection
          isCreate={false}
          isImport={false}
          dynamicTemplate={data.dynamicTemplateById}
        />
        <VerticalSpacer />
        <TemplateFormButtons
          templateId={templateId}
          setIsUpdated={setIsUpdated}
        />
      </PageContainer>
      <UpdateTemplateProvider
        dynamicTemplate={data.dynamicTemplateById}
        updated={isUpdated}
        setUpdated={setIsUpdated}
        onUpdated={() => {
          commonStore.history.push(`${commonStore.rootPath}?notify=updated`);
        }}
      />
    </>
  );
};

const UpdateTemplateDetailsForm: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);

  useEffect(() => {
    commonStore.setBreadCrumbs([
      {
        text: TemplateFormStrings.DynamicTemplatesBreadCrumb,
        link: "./dynamic-template",
        icon: undefined,
      },
    ]);
    commonStore.setHeader(TemplateFormStrings.PageHeaderUpdate);
  });

  return <UpdateTemplateDetailsLayout />;
};

export default observer(UpdateTemplateDetailsForm);
