export const ImageFlowElementStrings = {
  AddImageLabel: "Add Image",
  ChangeImageLabel: "Change Image",
  WidthLabel: "Width (px)",
  WidthHelpText: "Width of the image as an integer. Recommended 1000.",
  WidthRequiredText: "A properly formatted integer is required.",
  HeightLabel: "Height (px)",
  HeightHelpText: "Height of the image as an integer. Recommended 800.",
  HeightRequiredText: "A properly formatted integer is required.",
  AccessibilityLabel: "Accessibility Label",
  AccessibilityHelpText:
    "Detailed text that will be read via the screen reader.",
  AccessibilityRequiredText: "Description (alt text) is required",
  DisplaySettingsMobileText: "Display settings on mobile devices",
  DisplaySettingsMobileDefaultText: "Default (recommended)",
  DisplaySettingsMobileCustomText: "Custom",
};
