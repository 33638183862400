import { ApolloError } from "@apollo/client";
import { Dispatch, SetStateAction, useState } from "react";
import { Toaster } from "@intouchhealth/mfe-library";
import { TemplateFormStrings } from "./TemplateFormStrings";

export const useTemplateError = () => {
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState<boolean>(false);
  const [alertMessages, setAlertMessages] = useState<string[]>([]);

  const extractErrorMessages = (
    executionErrors: ApolloError | undefined,
    validationErrors: any,
  ): string[] => {
    var messages: string[] = [];

    if (executionErrors) {
      messages.push(TemplateFormStrings.ErrorAlertUnknownMessage);
    }
    if (validationErrors?.length > 0) {
      validationErrors.forEach((e: any) => {
        messages.push(e.message);
      });
    }
    return messages;
  };

  const hasErrors = (
    executionErrors: ApolloError | undefined,
    validationErrors: any,
  ): boolean => {
    var messages = extractErrorMessages(executionErrors, validationErrors);
    if (messages.length > 0) {
      setAlertMessages(messages);
      setIsErrorAlertOpen(true);
      return true;
    }
    return false;
  };

  return {
    isErrorAlertOpen,
    setIsErrorAlertOpen,
    alertMessages,
    hasErrors,
  };
};

interface TemplateFormErrorProps {
  isErrorAlertOpen: boolean;
  setIsErrorAlertOpen: Dispatch<SetStateAction<boolean>>;
  alertMessages: string[];
}

const TemplateFormError: React.FC<TemplateFormErrorProps> = ({
  isErrorAlertOpen,
  setIsErrorAlertOpen,
  alertMessages,
}: TemplateFormErrorProps) => {
  return (
    <Toaster
      type={"error"}
      title={TemplateFormStrings.ErrorAlertTitle}
      isOpen={isErrorAlertOpen}
      setIsOpen={setIsErrorAlertOpen}
      autoHideDuration={7000}
    >
      <ul>
        {alertMessages.map((m, index) => {
          return <li key={index}>{m}</li>;
        })}
      </ul>
    </Toaster>
  );
};

export default TemplateFormError;
