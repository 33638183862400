import React, { useContext, useEffect, useRef, useState } from "react";
import {
  DynamicTemplateType,
  useDynamicTemplatesQuery,
} from "./DashboardQueries";
import { DashboardStrings } from "./DashboardStrings";
import { observer } from "mobx-react-lite";
import { RootStoreContext } from "../../app/stores/rootStore";
import {
  EditIcon,
  DeleteIcon,
  AddNewIcon,
} from "@intouchhealth/cig-components";
import { ContentCopy } from "@mui/icons-material";
import { IAccessor } from "@intouchhealth/cig-components/build/table/Table.types";
import {
  Container,
  ErrorContainer,
  HeaderContainer,
  Toaster,
  IconLinkColumnElement,
  LabelLinkColumnElement,
  OutlineStyleButton,
  SearchInput,
  NotificationProperty,
  useTableWithPagination,
} from "@intouchhealth/mfe-library";
import { useDeleteTemplateProvider } from "../templatesform/DeleteTemplateProvider";

const searchNotification = new NotificationProperty();

const DynamicTemplateHeaderControls: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);
  const [searchValue, setSearchValue] = useState<string>();

  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (searchInput?.current) {
      searchInput.current.focus();
    }
  }, []);

  useEffect(() => {
    searchNotification.publish(searchValue);
  }, [searchValue]);

  return (
    <HeaderContainer>
      <div style={{ width: "50%" }}>
        <SearchInput
          setInputRef={searchInput}
          value={searchValue}
          onChange={(value) => setSearchValue(value)}
        />
      </div>
      <OutlineStyleButton
        icon={<AddNewIcon />}
        size="s"
        onClick={(): void | Promise<void> => {
          commonStore.history.push(`${commonStore.rootPath}/createoptions`);
        }}
      >
        {DashboardStrings.CreateButton}
      </OutlineStyleButton>
    </HeaderContainer>
  );
};

const DynamicTemplateTable: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);
  const { DeleteTemplateProvider, handleDelete } = useDeleteTemplateProvider();

  const dynamicTemplatesAccessor: IAccessor[] = [
    {
      label: DashboardStrings.NameColumn,
      key: "name",
      width: "225px",
    },
    {
      label: DashboardStrings.CreatedAtColumn,
      key: "createdAt",
      width: "200px",
    },
    { label: "", key: "edit", width: "30px" },
  ];

  if (process.env.REACT_APP_DTS_DESIGNER_ENABLED === "TRUE") {
    dynamicTemplatesAccessor.push({ label: "", key: "copy", width: "30px" });
  }
  dynamicTemplatesAccessor.push({ label: "", key: "delete", width: "30px" });

  const { TableWithPagination, setTableData } = useTableWithPagination(
    dynamicTemplatesAccessor,
  );

  const [formData, setFormData] = useState<DynamicTemplateType[]>([]);
  const { loading, error, data, refetch } = useDynamicTemplatesQuery(
    commonStore.practiceId,
  );

  useEffect(() => {
    // @ts-ignore
    setFormData(data?.dynamicTemplatesByPracticeIds);
  }, [data]);

  const [searchValue, setSearchValue] = useState<string>("");
  searchNotification.subscribe("DynamicTemplateTable", setSearchValue);

  // On loading complete or search, update the tabled data with the rows to be displayed
  useEffect(() => {
    var searchValueLower = searchValue?.toLowerCase().trim();
    var tableData =
      loading || !formData
        ? []
        : (formData
            .filter(
              (v: DynamicTemplateType) =>
                !searchValueLower ||
                v.name.toLocaleLowerCase().includes(searchValueLower),
            )
            .map((dt: DynamicTemplateType) => {
              return {
                id: dt.templateId,
                name: LabelLinkColumnElement({
                  Label: dt.name,
                  LinkPath: "dynamic-template/design/" + dt.templateId,
                  History: commonStore.history,
                }),
                createdAt: new Date(dt.createdAt).toLocaleString(),
                edit: IconLinkColumnElement({
                  LinkIcon: EditIcon,
                  LinkPath: "dynamic-template/design/" + dt.templateId,
                  History: commonStore.history,
                }),
                copy: (
                  <div style={ContentCopyIconStyle}>
                    <ContentCopy
                      cursor={"pointer"}
                      onClick={() => {
                        commonStore.history.push(
                          "dynamic-template/copy/" + dt.templateId,
                        );
                      }}
                    />
                  </div>
                ),
                delete: (
                  <DeleteIcon
                    cursor={"pointer"}
                    fill={"#ADA8A4"}
                    onClick={() => {
                      handleDelete(dt.templateId, dt.name, () =>
                        setFormData((formData) =>
                          formData.filter((data) => data.name !== dt.name),
                        ),
                      );
                    }}
                  />
                ),
              };
            }) as []);

    setTableData(tableData);
    // eslint-disable-next-line
  }, [setTableData, loading, formData, commonStore.history, searchValue]);

  useEffect(() => {
    if (!loading) {
      console.log("Dashboard refreshed.");
      refetch();
    }
    // eslint-disable-next-line
  }, []);

  if (error) {
    console.log(
      "Error Fetching in DynamicTemplate on organizationId: " +
        commonStore.organizationId,
    );
    console.log(error);
    return (
      <ErrorContainer>
        <h4>{DashboardStrings.FetchingErrorMessage}</h4>
        {DashboardStrings.SeeConsoleLogMessage}
      </ErrorContainer>
    );
  }

  return (
    <>
      <TableWithPagination />
      <DeleteTemplateProvider />
    </>
  );
};

const SaveNotification: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);
  const [isSavedAlertOpen, setIsSavedAlertOpen] = useState<boolean>(false);
  const [alertTitle, setAlertTitle] = useState<string>("");
  const [alertMessage, setAlertMessage] = useState<string>("");
  const queryParams = new URLSearchParams(window.location.search);
  const notify = queryParams.get("notify");

  useEffect(() => {
    if (notify === "updated") {
      setAlertTitle(DashboardStrings.SavedAlertTitle);
      setAlertMessage(DashboardStrings.SavedAlertMessage);
      setIsSavedAlertOpen(true);
      commonStore.history.replace("./dynamic-template");
    }
    if (notify === "created") {
      setAlertTitle(DashboardStrings.CreatedAlertTitle);
      setAlertMessage(DashboardStrings.CreatedAlertMessage);
      setIsSavedAlertOpen(true);
      commonStore.history.replace("./dynamic-template");
    }
    if (notify === "deleted") {
      setAlertTitle(DashboardStrings.DeletedAlertTitle);
      setAlertMessage(DashboardStrings.DeletedAlertMessage);
      setIsSavedAlertOpen(true);
      commonStore.history.replace("./dynamic-template");
    }
    if (notify === "cancel") {
      setAlertTitle(DashboardStrings.CancelAlertTitle);
      setAlertMessage(DashboardStrings.CancelAlertMessage);
      setIsSavedAlertOpen(true);
      commonStore.history.replace("./dynamic-template");
    }
  }, [notify, commonStore.history]);

  return (
    <Toaster
      type={"success"}
      title={alertTitle}
      isOpen={isSavedAlertOpen}
      setIsOpen={setIsSavedAlertOpen}
      autoHideDuration={7000}
    >
      {alertMessage}
    </Toaster>
  );
};

const Dashboard: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);

  useEffect(() => {
    commonStore.setHeader(DashboardStrings.PageHeader);
  });

  return (
    <>
      <SaveNotification />
      <Container>
        <DynamicTemplateHeaderControls />
        <DynamicTemplateTable />
      </Container>
    </>
  );
};

const ContentCopyIconStyle = {
  color: "#ADA8A4",
};

export default observer(Dashboard);
