import {
  FlexRowStartStyle,
  GroupContainer,
  HorizontalSpacer,
  TranslationManagerContext,
} from "@intouchhealth/mfe-library";
import { FC, useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { TranslationOptions } from "../../../app/stores/translationOptionsStore";
import { ILinkEditorData, useLinkEditorComponent } from "./LinkEditorComponent";
import { Button } from "@intouchhealth/cig-components";
import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Editor } from "draft-js";
import { AddLink } from "@mui/icons-material";
import {
  getDefaultLinkText,
  getLinkText,
  IElementDesign,
  ILink,
  setDefaultLinkText,
  setLinkText,
} from "../../../app/stores/templateDesignStore";

interface ITranslateableTextBoxParams {
  element: IElementDesign;
  elementId: string;
  title: string;
  helptext: string;
  onTextChanged: Function;
  onTranslatedTextChanged: Function;
  getInitialText: Function;
  getInitialTranslatedText: Function;
  getRequiredMessage: Function;
}

export const TranslatableLinkTextBoxComponent = (
  params: ITranslateableTextBoxParams,
) => {
  const {
    element,
    elementId,
    title,
    helptext,
    onTextChanged,
    onTranslatedTextChanged,
    getInitialText,
    getInitialTranslatedText,
    getRequiredMessage,
  } = params;
  const context = useContext(TranslationManagerContext);
  const [activeLanguage, setActiveLanguage] = useState<string>(
    context.activeLanguage,
  );

  context.languageChangeNotification.subscribe(
    `translatableLinkEditor-${elementId}`,
    setActiveLanguage,
  );

  useEffect(() => {
    // Remove links from the element that dont have any translations attached to them
    element.links = element.links?.filter(
      (link) =>
        link.translatedLinkText &&
        link.translatedLinkText.some(
          (translatedLinkText) => !!translatedLinkText.translation,
        ),
    );

    if (!element.links?.length) {
      element.links = undefined;
    }
    // eslint-disable-next-line
  }, [element.links]);

  return (
    <FlexRowStartStyle>
      <div
        style={{
          width: "100%",
          minWidth: activeLanguage ? "33%" : "auto",
          maxWidth: activeLanguage ? "33%" : "auto",
        }}
      >
        <TranslatableLinkTextBoxElement
          required={true}
          activeLanguage={activeLanguage}
          element={element}
          title={title}
          helptext={helptext}
          getInitialText={getInitialText}
          onTextChanged={onTextChanged}
          getRequiredMessage={getRequiredMessage}
        />
      </div>
      {!activeLanguage ? (
        <></>
      ) : (
        <>
          <HorizontalSpacer />
          <div
            style={{
              width: "100%",
            }}
          >
            <TranslatableLinkTextBoxElement
              required={false}
              activeLanguage={activeLanguage}
              language={activeLanguage}
              element={element}
              title={title}
              helptext={helptext}
              getInitialText={getInitialTranslatedText}
              onTextChanged={onTranslatedTextChanged}
              getRequiredMessage={getRequiredMessage}
            />
          </div>
        </>
      )}
    </FlexRowStartStyle>
  );
};
interface ILinkTextBoxProps {
  element: IElementDesign;
  language?: string;
  activeLanguage: string;
  required: boolean;
  title: string;
  helptext: string;
  onTextChanged: Function;
  getInitialText: Function;
  getRequiredMessage: Function;
}

const TranslatableLinkTextBoxElement = (props: ILinkTextBoxProps) => {
  const context = useContext(TranslationManagerContext);
  const editorRef = useRef<Draft.Editor>(null);
  const {
    element,
    language,
    activeLanguage,
    required,
    title,
    helptext,
    onTextChanged,
    getInitialText,
    getRequiredMessage,
  } = props;

  const languageName = language
    ? language
    : activeLanguage
      ? context.defaultLanguage
      : "";
  const languageCode = language
    ? TranslationOptions.find((option) => option.languageName === language)
        ?.languageCode || "default"
    : "default";
  const languagePostfix = `${languageName ? ` (${languageName})` : ""}`;

  const initialEditorData = {
    text: getInitialText(languageCode),
    links:
      element.links?.map((l) => {
        return {
          text: !language
            ? getDefaultLinkText(l)
            : getLinkText(l, languageCode),
          url: l.linkUrl,
        };
      }) ?? [],
    language: languageName,
  } as ILinkEditorData;

  const {
    LinkEditorDialog,
    openLinkEditor,
    editorState,
    setEditorState,
    linkEditorData,
  } = useLinkEditorComponent(initialEditorData);

  const [requiredLabel, setRequiredLabel] = useState<string>(
    getRequiredMessage(languageCode, required),
  );

  useEffect(() => {
    // On changes in editor update the element data to latest
    onTextChanged(linkEditorData.text, languageCode);
    setRequiredLabel(getRequiredMessage(languageCode, required));

    // Add or update any links with their translated text on element links from the link editor data.
    let traversedLinks: { [key: string]: number } = {};
    linkEditorData.links.forEach((editorLink) => {
      if (!traversedLinks[editorLink.url]) {
        traversedLinks[editorLink.url] = 0;
      }

      let elementLink = element.links
        ?.filter((elementLink) => elementLink.linkUrl === editorLink.url)
        .find((_, index) => index === traversedLinks[editorLink.url]);
      const elementLinkExists = !!elementLink;

      if (!element.links || !element.links.length) {
        element.links = [];
      }

      if (!elementLinkExists) {
        elementLink = {
          translatedLinkText: [],
          linkUrl: editorLink.url,
        } as ILink;

        element.links.push(elementLink);
      }

      if (!language) {
        setDefaultLinkText(elementLink as ILink, editorLink.text);
      } else {
        setLinkText(elementLink as ILink, editorLink.text, languageCode);
      }

      traversedLinks[editorLink.url]++;
    });

    // Remove any translations from element links that are not contained in the link editor data.
    traversedLinks = {};
    element.links?.forEach((elementLink) => {
      if (!traversedLinks[elementLink.linkUrl]) {
        traversedLinks[elementLink.linkUrl] = 0;
      }

      if (elementLink.translatedLinkText) {
        let linkEditorLink = linkEditorData.links
          .filter(
            (editorLink) =>
              editorLink.url === elementLink.linkUrl && editorLink.text,
          )
          .find((_, index) => index === traversedLinks[elementLink.linkUrl]);

        if (!linkEditorLink) {
          const translationLink = elementLink.translatedLinkText.find(
            (translatedLinkText) =>
              translatedLinkText.languageCode === languageCode,
          );
          if (translationLink) {
            translationLink.translation = "";
          }
        }
      }

      traversedLinks[elementLink.linkUrl]++;
    });
    // eslint-disable-next-line
  }, [linkEditorData]);

  // Wrap the link icon to support being inside a cig-component button.
  const AddLinkIcon: FC<{ iconSize?: string }> = () => (
    <AddLink style={{ marginRight: "10px" }} />
  );

  return (
    <>
      <LinkEditorButtonStyle required={required}>
        <Button
          size="xs"
          textAlign="right"
          iconPosition="left"
          icon={<AddLinkIcon />}
          appearance="subtle"
          themeName="whitelabel"
          onClick={openLinkEditor}
        >
          Link Editor
        </Button>
      </LinkEditorButtonStyle>
      <GroupContainer
        title={`${title}${languagePostfix}`}
        helpText={helptext}
        required={required}
        requiredLabel={requiredLabel}
      >
        <EditorContainer>
          <SimpleBar
            autoHide={false}
            style={{ height: "100%", width: "calc(100% + 10px)" }}
          >
            <Editor
              ref={editorRef}
              editorState={editorState}
              onChange={setEditorState}
            />
          </SimpleBar>
        </EditorContainer>
      </GroupContainer>
      <LinkEditorDialog />
    </>
  );
};

const EditorContainer = styled.div`
  height: 55px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  .DraftEditor-root {
    font-family: "Medium", sans-serif;
    font-size: 14px;
    color: black;
  }
  .simplebar-track {
    opacity: 0.3;
  }
`;

const LinkEditorButtonStyle = styled.div<{ required: boolean }>`
  width: 100%;
  margin-bottom: -18px;
  padding-right: ${({ required }: { required: boolean }) =>
    required ? "40px" : "0px"};
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: end;
  & span {
    margin-left: -6px;
    margin-right: 3px !important;
  }
  & button {
    white-space: nowrap;
    height: 24px;
    padding-right: 0px;
    margin-right: ${({ required }: { required: boolean }) =>
      required ? "-18px" : "0px"} !important;
    border: dotted 1px transparent;
    :focus {
      border: dotted 1px gray;
    }
    :hover {
      color: #1986e2;
    }
  }
`;
