import React, { useContext, useState, VFC } from "react";
import styled from "styled-components";
import {
  KeyboardArrowDown,
  KeyboardArrowRight,
  SubdirectoryArrowRight,
} from "@mui/icons-material";
import {
  getAttribute,
  IPageDesign,
  getBranchAnswers,
  pageIsFirstSibling,
  getComplexPageTitle,
} from "../../app/stores/templateDesignStore";
import { FlowNavigatorContext } from "./FlowNavigator";
import { TreeItem } from "@mui/x-tree-view";
import { TreeView } from "@mui/x-tree-view";
import { Tooltip } from "@mui/material";

export const useFlowPageBranches = () => {
  const [expanded, setExpanded] = useState<string[] | undefined>();
  const context = useContext(FlowNavigatorContext);

  const renderTree = (page: IPageDesign, rootId: string) => (
    <React.Fragment key={page.id}>
      <TreeItem
        nodeId={page.id}
        label={
          <Tooltip
            title={
              getComplexPageTitle(page) ||
              getAttribute(page, "title", undefined, "").defaultText()
            }
            enterDelay={750}
            placement={"right"}
          >
            <LabelContainer
              style={{
                background:
                  page.id === context.selectedPageId ? "#f7f7f7" : "inherit",
              }}
              id={"navlabel-" + page.id}
              onClick={(event) => {
                context.selectPageHandler(page.id);
                event.stopPropagation();
              }}
              tabIndex={0}
              role={"link"}
              onKeyPress={(event): void | Promise<void> => {
                if (event.code === "Enter") {
                  context.selectPageHandler(page.id);
                  event.stopPropagation();
                }
              }}
            >
              {page.id !== rootId &&
              pageIsFirstSibling(context.section(), page.id) ? (
                <SubdirectoryArrowRight style={{ height: "18px" }} />
              ) : (
                <span
                  style={{ marginRight: page.id !== rootId ? "24px" : "0px" }}
                />
              )}
              <span id={"navtitle-" + page.id}>
                {getComplexPageTitle(page) ||
                  getAttribute(page, "title", undefined, "").defaultText()}
              </span>
            </LabelContainer>
          </Tooltip>
        }
      >
        {getBranchAnswers(page)?.map((a) =>
          a.pages?.map((p) => renderTree(p, rootId)),
        ) ?? null}
      </TreeItem>
    </React.Fragment>
  );

  const FlowPageBranches: VFC<{
    page: IPageDesign;
  }> = ({ page }) => {
    const handleToggle = (_event: React.SyntheticEvent, nodeIds: string[]) => {
      setExpanded(nodeIds);
    };

    const handleSelect = (_event: React.SyntheticEvent, nodeId: string) => {
      context.selectPageHandler(nodeId);
    };

    return (
      <div key={context.navElementsKey()}>
        <TreeContainer>
          <TreeView
            aria-label="rich object"
            expanded={expanded}
            defaultCollapseIcon={<KeyboardArrowDown />}
            defaultExpanded={["root"]}
            defaultExpandIcon={<KeyboardArrowRight />}
            onNodeToggle={handleToggle}
            onNodeSelect={handleSelect}
          >
            {renderTree(page, page.id)}
          </TreeView>
        </TreeContainer>
      </div>
    );
  };

  return {
    FlowPageBranches,
  };
};

const LabelContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: solid 1px transparent;
  :focus-visible {
    outline: none;
    border: dotted 1px gray;
  }
`;
const TreeContainer = styled.div`
  no-wrap: true;
  margin-top: -26px;
  & .MuiTreeView-root {
    width: calc(100% - 8px);
    margin-left: -4px;
  }
  & .MuiTreeItem-root {
    padding: 0;
    & .MuiTreeItem-group {
      padding: 0;
    }
    & .MuiTreeItem-content {
      background: transparent !important;
      padding: 0;
      :hover {
        background: transparent;
      }
    }
    & .MuiTreeItem-iconContainer {
      margin-right: 0;
    }
    & .Mui-selected {
      background: transparent;
      & .MuiTreeItem-label {
        color: #1986e2;
        background: transparent;
      }
      & MuiTreeItem-content {
        background: transparent;
      }
    }
    & .Mui-expanded {
    }
    & .Mui-focused {
    }
    & .MuiTreeItem-label {
      line-height: inherit;
      font-family: inherit;
      font-size: medium;
      border: solid 1px transparent;
      :hover {
        color: #1986e2;
        border: dotted 1px gray;
      }
    }
  }
`;
