import {
  EditorState,
  EntityInstance,
  ContentState,
  Modifier,
  ContentBlock,
  CompositeDecorator,
  convertToRaw,
} from "draft-js";
import { FC, ReactNode } from "react";
import { mui } from "@intouchhealth/cig-components";
import { LinkEditorComponentStrings } from "./LinkEditorComponentStrings";
import styled from "styled-components";

const { Tooltip } = mui;
const LINK_TYPE = "LINK";

export interface ILinkData {
  text: string;
  url: string;
}

export const getCurrentLinkKey = (
  editorState: EditorState,
  contentState?: ContentState,
): string => {
  if (contentState === undefined) {
    contentState = editorState.getCurrentContent();
  }

  const startKey = editorState.getSelection().getStartKey();
  const startOffset = editorState.getSelection().getStartOffset();
  const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);

  return blockWithLinkAtBeginning.getEntityAt(startOffset);
};

export const getCurrentLinkEntity = (
  editorState: EditorState,
): EntityInstance | null => {
  const contentState = editorState.getCurrentContent();
  const linkKey = getCurrentLinkKey(editorState, contentState);

  if (linkKey) {
    return contentState.getEntity(linkKey);
  }

  return null;
};

export const getLinkData = (
  entityKey: string,
  editorState: EditorState,
): ILinkData | null => {
  const currentContent = editorState.getCurrentContent();
  const linkEntity = currentContent.getEntity(entityKey);
  if (linkEntity !== null) {
    return linkEntity.getData() as ILinkData;
  }
  return null;
};

export const getCurrentLinkData = (
  editorState: EditorState,
): ILinkData | null => {
  const currentLinkEntity = getCurrentLinkEntity(editorState);
  if (currentLinkEntity !== null) {
    return currentLinkEntity.getData() as ILinkData;
  }
  return null;
};

export const processEditsToLinkText = (editorState: EditorState) => {
  const currentContent = editorState.getCurrentContent();
  var entityKey =
    getCurrentLinkKey(editorState, currentContent) ??
    getSelectionEntity(editorState);
  if (entityKey) {
    var entityData = getLinkData(entityKey, editorState);
    var entityRange = getEntityRange(editorState, entityKey);
    if (entityData && entityRange && entityData.text !== entityRange.text) {
      if (entityData.text.length === 2 && entityRange.text.length === 1) {
        entityData.text = "";
        entityData.url = "";
      } else {
        entityData.text = entityRange.text;
      }
      currentContent.replaceEntityData(entityKey, entityData);
    }
  }
};

export const getAllLinkData = (editorState: EditorState): ILinkData[] => {
  var entityMap = convertToRaw(editorState.getCurrentContent()).entityMap;
  var links = [] as ILinkData[];
  Object.entries(entityMap)
    .filter(
      ([_key, value]) =>
        value !== undefined &&
        value.type === LINK_TYPE &&
        value.data.text.length > 0 &&
        value.data.url.length > 0,
    )
    .forEach(([_key, value]) => {
      links.push(value.data as ILinkData);
    });
  return links;
};

export function getSelectedBlocksMap(editorState: EditorState) {
  const selectionState = editorState.getSelection();
  const contentState = editorState.getCurrentContent();
  const startKey = selectionState.getStartKey();
  const endKey = selectionState.getEndKey();
  const blockMap = contentState.getBlockMap();
  return blockMap
    .toSeq()
    .skipUntil((_, k) => k === startKey)
    .takeUntil((_, k) => k === endKey)
    .concat([[endKey, blockMap.get(endKey)]]);
}

export function getSelectedBlocksList(editorState: EditorState) {
  return getSelectedBlocksMap(editorState).toList();
}

export function getSelectedBlock(
  editorState: EditorState,
): ContentBlock | undefined {
  if (editorState) {
    return getSelectedBlocksList(editorState).get(0);
  }
  return undefined;
}

export function getSelectionText(editorState: EditorState) {
  let selectedText: string | undefined;
  let selection = editorState.getSelection();
  if (!selection.isCollapsed()) {
    let anchorKey = selection.getAnchorKey();
    let currentContent = editorState.getCurrentContent();
    let currentContentBlock = currentContent.getBlockForKey(anchorKey);
    let start = selection.getStartOffset();
    let end = selection.getEndOffset();
    selectedText = currentContentBlock.getText().slice(start, end);
  }
  return selectedText;
}

export function getSelectionEntity(editorState: EditorState) {
  let entityKey: string | undefined;
  const selection = editorState.getSelection();
  let start = selection.getStartOffset();
  let end = selection.getEndOffset();
  if (start === end && start === 0) {
    end = 1;
  } else if (start === end) {
    start -= 1;
  }
  const block = getSelectedBlock(editorState);

  for (let i = start; i < end; i += 1) {
    const currentEntity = block?.getEntityAt(i);
    if (!currentEntity) {
      entityKey = undefined;
      break;
    }
    if (i === start) {
      entityKey = currentEntity;
    } else if (entityKey !== currentEntity) {
      entityKey = undefined;
      break;
    }
  }

  // If found a deleted entity, clear the key
  if (entityKey) {
    var linkData = getLinkData(entityKey, editorState);
    if (linkData && linkData.text.length === 0 && linkData.url.length === 0) {
      entityKey = undefined;
    }
  }
  return entityKey;
}

interface IEntityRange {
  start: number;
  end: number;
  text: string;
}

export function getEntityRange(editorState: EditorState, entityKey: string) {
  const block = getSelectedBlock(editorState);
  let entityRange: IEntityRange | undefined;
  block?.findEntityRanges(
    (value) => value.getEntity() === entityKey,
    (start, end) => {
      entityRange = {
        start,
        end,
        text: block.get("text").slice(start, end),
      };
    },
  );
  return entityRange;
}

const findLinkEntities = (
  contentBlock: ContentBlock,
  callback: any,
  contentState: ContentState,
) => {
  contentBlock.findEntityRanges((character) => {
    const entityKey = character.getEntity();
    return (
      entityKey !== null &&
      contentState.getEntity(entityKey).getType() === LINK_TYPE
    );
  }, callback);
};

interface ILink {
  entityKey: string;
  children: ReactNode;
}

const LinkStyle = styled.span`
  cursor: help;
  line-height: normal;
  color: #1976d2;
  text-decoration: underline;
`;

const Link: FC<ILink> = (props) => {
  return (
    <>
      <Tooltip
        title={LinkEditorComponentStrings.LinkTooltip}
        enterDelay={50}
        placement={"top-start"}
      >
        <LinkStyle>{props.children}</LinkStyle>
      </Tooltip>
    </>
  );
};

const createLinkDecorator = () =>
  new CompositeDecorator([
    {
      strategy: findLinkEntities,
      component: Link,
    },
  ]);

export const createLinkEditorState = (content: ContentState) => {
  return EditorState.createWithContent(content, createLinkDecorator());
};

export const addLink = (
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void,
  entityData: ILinkData,
): void => {
  const currentContent = editorState.getCurrentContent();
  currentContent.createEntity(LINK_TYPE, "MUTABLE", entityData);
  let entityKey = currentContent.getLastCreatedEntityKey();
  let selection = editorState.getSelection();
  const textWithEntity = Modifier.replaceText(
    currentContent,
    selection,
    entityData.text,
    undefined,
    entityKey,
  );
  let newEditorState = EditorState.createWithContent(
    textWithEntity,
    createLinkDecorator(),
  );

  setEditorState(
    EditorState.push(newEditorState, textWithEntity, "insert-characters"),
  );
};

export const updateLink = (
  editorState: EditorState,
  setEditorState: (editorState: EditorState) => void,
  entityData: ILinkData,
  entityKey: string,
): void => {
  const currentContent = editorState.getCurrentContent();

  let selection = editorState.getSelection();

  const entityRange = getEntityRange(editorState, entityKey);

  if (!entityRange) {
    return;
  }

  currentContent.replaceEntityData(entityKey, entityData);

  if (selection.getIsBackward()) {
    selection = selection.merge({
      anchorOffset: entityRange.end,
      focusOffset: entityRange.start,
    });
  } else {
    selection = selection.merge({
      anchorOffset: entityRange.start,
      focusOffset: entityRange.end,
    });
  }

  const updatedEditorWithText = Modifier.replaceText(
    currentContent,
    selection,
    entityData.text,
    editorState.getCurrentInlineStyle(),
    entityKey,
  );

  const newEditorState = EditorState.push(
    editorState,
    updatedEditorWithText,
    "insert-characters",
  );

  setEditorState(
    EditorState.push(
      newEditorState,
      updatedEditorWithText,
      "insert-characters",
    ),
  );
};
