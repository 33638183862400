export const FlowMenuStrings = {
  AddBranchMenuItem: "Add branch",
  RemoveBranchMenutItem: "Delete branch",
  MoveUpMenuItem: "Move up",
  MoveDownMenuItem: "Move down",
  ReferenceCategoryMenuItem: "Reference Category",
  MetaDataMenuItem: "Meta Data",
  DeleteMenuItem: "Delete",
  DuplicateMenuItem: "Duplicate",
};
