import * as serviceWorker from "./serviceWorker";
import App from "./app/layout/App";
import ReactDOM from "react-dom";
import { DynamicTemplateInterface } from "./serviceInterface";

//@ts-ignore
window.renderDynamicTemplate = (props: DynamicTemplateInterface) => {
  if (
    document.getElementById(props.containerName)?.querySelectorAll("*")
      .length !== 0
  ) {
    return;
  }

  //@ts-ignore
  if (window.renderedDynamicTemplateFrontend) {
    window.location.reload();
    return;
  }

  console.log("Load dynamic template frontend");
  ReactDOM.render(
    <div>
      <App
        organizationId={props.organizationId}
        practiceId={props.practiceId}
        browserHistory={props.browserHistory}
        setBreadCrumbs={props.setBreadCrumbs}
        setHeader={props.setHeader}
        idpToken={props.idpToken}
      />
    </div>,
    document.getElementById(props.containerName),
  );
  serviceWorker.unregister();

  //@ts-ignore
  window.renderedDynamicTemplateFrontend = true;
};

//@ts-ignore
window.unmountDynamicTemplate = (containerId) => {
  //@ts-ignore
  ReactDOM.unmountComponentAtNode(document.getElementById(containerId));
};
