import { gql, useMutation } from "@apollo/client";
import { stripTypeNames } from "@intouchhealth/mfe-library";
import {
  useDynamicTemplateClient,
  useDynamicTemplateUploadClient,
} from "../../app/stores/dynamicTemplateStore";
import {
  ITemplateDesign,
  ITranslationData,
} from "../../app/stores/templateDesignStore";
import stripIdFields from "../../app/utils/stripIdFields";

export function useImportDynamicTemplateMutation() {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const IMPORT_DYNAMIC_TEMPLATE = gql`
    mutation importDynamicTemplate($input: ImportDynamicTemplateInput!) {
      importDynamicTemplate(input: $input) {
        errors {
          __typename
          ... on IBaseError {
            message
          }
        }
        template {
          templateId
        }
      }
    }
  `;

  return useMutation(IMPORT_DYNAMIC_TEMPLATE, {
    client: dynamicTemplateClient,
  });
}

export type AdminTemplateType = {
  templateId: string | undefined;
  organizationId: string;
  practiceId: string;
  name: string;
  type: string;
  endAction: string;
  defaultLanguageCode: string;
  translations: ITranslationData[];
  flow: any;
};

export const ConvertToAdminTemplateType = (
  templateConfig: ITemplateDesign,
  flow: any,
) => {
  return stripIdFields(
    stripTypeNames({
      templateId: templateConfig.templateId,
      organizationId: templateConfig.organizationId,
      practiceId: templateConfig.practiceId,
      name: templateConfig.name,
      tag: templateConfig.tag,
      type: templateConfig.type,
      endAction: templateConfig.endAction,
      defaultLanguageCode: templateConfig.defaultLanguageCode,
      translations: templateConfig.translations,
      flow: flow,
    } as AdminTemplateType),
  );
};

export function useAddDynamicTemplateMutation() {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const ADD_DYNAMIC_TEMPLATE = gql`
    mutation addDynamicTemplate($input: AddDynamicTemplateInput!) {
      addDynamicTemplate(input: $input) {
        errors {
          __typename
          ... on IBaseError {
            message
          }
        }
      }
    }
  `;

  return useMutation(ADD_DYNAMIC_TEMPLATE, {
    client: dynamicTemplateClient,
  });
}

export function useUpdateDynamicTemplateMutation() {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const UPDATE_DYNAMIC_TEMPLATE = gql`
    mutation updateDynamicTemplate($input: UpdateDynamicTemplateInput!) {
      updateDynamicTemplate(input: $input) {
        errors {
          __typename
          ... on IBaseError {
            message
          }
        }
      }
    }
  `;

  return useMutation(UPDATE_DYNAMIC_TEMPLATE, {
    client: dynamicTemplateClient,
  });
}

export function useDeleteDynamicTemplateMutation() {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const DELETE_DYNAMIC_TEMPLATE = gql`
    mutation deleteDynamicTemplate($input: DeleteDynamicTemplateInput!) {
      deleteDynamicTemplate(input: $input) {
        errors {
          __typename
          ... on IBaseError {
            message
          }
        }
      }
    }
  `;

  return useMutation(DELETE_DYNAMIC_TEMPLATE, {
    client: dynamicTemplateClient,
  });
}

export function useUploadImageMutation() {
  const dynamicTemplateClient = useDynamicTemplateUploadClient();
  const MUTATION = gql`
    mutation uploadDynamicTemplateImage($input: UploadImageInput!) {
      uploadDynamicTemplateImage(input: $input) {
        image {
          key
          url
        }
        errors {
          __typename
        }
      }
    }
  `;

  return useMutation(MUTATION, { client: dynamicTemplateClient });
}

export function useDeleteImageMutation() {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const MUTATION = gql`
    mutation deleteDynamicTemplateImage($input: DeleteImageInput!) {
      deleteDynamicTemplateImage(input: $input) {
        errors {
          __typename
          ... on IBaseError {
            message
          }
        }
      }
    }
  `;

  return useMutation(MUTATION, { client: dynamicTemplateClient });
}
