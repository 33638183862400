import { Dimmer, LoaderIcon } from "@intouchhealth/cig-components";
import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { Params, useParams } from "react-router-dom";
import { ErrorContainer } from "@intouchhealth/mfe-library";
import { RootStoreContext } from "../../app/stores/rootStore";
import { useGetTemplateForAdminByIdQuery } from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";
import { UpdateTemplateProvider } from "./UpdateTemplateProvider";
import { DesignTemplateForm } from "./DesignTemplateForm";
import { ITemplateDesign } from "../../app/stores/templateDesignStore";

const UpdateTemplateLayout: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);
  const params = useParams<Params<string>>();
  const templateId = params.templateId ?? "";
  const [isUpdated, setIsUpdated] = useState<boolean>(false);
  const [isActive, setIsActive] = useState(true);
  const [updatedTemplate, setUpdatedTemplate] = useState<ITemplateDesign>();
  const { loading, error, data, refetch } =
    useGetTemplateForAdminByIdQuery(templateId);
  useEffect(() => {
    if (!loading) {
      refetch();
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!loading && !error && data?.dynamicTemplateForAdminById) {
      setIsActive(data?.dynamicTemplateForAdminById?.isActive);
    }
  }, [loading, error, data]);

  useEffect(() => {
    if (updatedTemplate) {
      setIsUpdated(true);
    }
    // eslint-disable-next-line
  }, [updatedTemplate]);

  if (loading) {
    return (
      <Dimmer
        header="Loading app..."
        icon={<LoaderIcon iconSize="xxl" sizing="relative" />}
      />
    );
  }

  if (error || !data?.dynamicTemplateForAdminById || !isActive) {
    if (error) {
      console.log(
        "Error Fetching in UpdateTemplate on templateId: " + templateId,
      );
      console.log(error);
    } else {
      console.log("Template not found for ID: " + templateId);
    }

    return (
      <ErrorContainer>
        <h4>{TemplateFormStrings.FetchingErrorMessage}</h4>
        {TemplateFormStrings.SeeConsoleLogMessage}
      </ErrorContainer>
    );
  }

  return (
    <>
      <DesignTemplateForm
        dynamicTemplate={data.dynamicTemplateForAdminById}
        mode={"UPDATE"}
        setUpdatedTemplate={setUpdatedTemplate}
      />
      <UpdateTemplateProvider
        dynamicTemplate={updatedTemplate}
        updated={isUpdated}
        setUpdated={setIsUpdated}
        onUpdated={() => {
          commonStore.history.push(`${commonStore.rootPath}?notify=updated`);
        }}
      />
    </>
  );
};

const UpdateTemplateForm: React.FC = () => {
  const { commonStore } = useContext(RootStoreContext);
  const queryParams = new URLSearchParams(window.location.search);
  const mode = queryParams.get("mode");

  useEffect(() => {
    commonStore.setBreadCrumbs([
      {
        text: TemplateFormStrings.DynamicTemplatesBreadCrumb,
        link: "./dynamic-template",
        icon: undefined,
      },
    ]);
    commonStore.setHeader(
      mode === "create"
        ? TemplateFormStrings.PageHeaderCreate
        : TemplateFormStrings.PageHeaderUpdate,
    );
  });

  return <UpdateTemplateLayout />;
};

export default observer(UpdateTemplateForm);
