import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { RootStoreContext } from "../../app/stores/rootStore";
import { IImageInfo } from "./ImageManagerDialog";
import { useUploadImageMutation } from "./TemplateFormMutations";

interface AddS3ImageProviderProps {
  file: File | undefined;
  updated: boolean;
  setUpdated: Dispatch<SetStateAction<boolean>>;
  onAdded: (data: IImageInfo) => void;
  onFailed: () => void;
}

export const AddS3ImageProvider: React.FC<AddS3ImageProviderProps> = ({
  file,
  updated,
  setUpdated,
  onAdded,
  onFailed,
}) => {
  const [uploadImageMutation, { data, loading, error, called, reset }] =
    useUploadImageMutation();
  const { commonStore } = useContext(RootStoreContext);

  useEffect(() => {
    if (updated && !called) {
      if (!file) {
        return;
      }

      const input = { file, practiceId: commonStore.practiceId };

      // Make call to update the image mutation.
      uploadImageMutation({
        variables: { input },
      });

      setUpdated(false);
    }
    // eslint-disable-next-line
  }, [updated, called]);

  useEffect(() => {
    if (called && (!loading || error)) {
      if (error || data?.uploadDynamicTemplateImage?.errors.length) {
        reset();
        onFailed();
      } else {
        reset();
        onAdded({
          key: data?.uploadDynamicTemplateImage?.image?.key,
          url: data?.uploadDynamicTemplateImage?.image?.url,
        });
      }
    }
    // eslint-disable-next-line
  }, [data, loading, error, called, reset]);

  return <></>;
};
