import { gql, useQuery } from "@apollo/client";
import { useDynamicTemplateClient } from "../../app/stores/dynamicTemplateStore";

interface CreatingUserType {
  userName: string;
}

export interface DynamicTemplateType {
  templateId: string;
  name: string;
  createdAt: string;
  creatingUser: CreatingUserType;
}

export function useDynamicTemplatesQuery(practiceId: string) {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const input = {
    input: {
      practiceIds: [practiceId],
    },
  };

  const GET_DYNAMICTEMPLATES_BY_PRACTICE = gql`
    query dynamicTemplatesByPracticeIds(
      $input: DynamicTemplatesByPracticeIdsInput!
    ) {
      dynamicTemplatesByPracticeIds(input: $input) {
        templateId
        name
        createdAt
        creatingUser {
          userName
        }
      }
    }
  `;

  return useQuery<{ dynamicTemplatesByPracticeIds: DynamicTemplateType[] }>(
    GET_DYNAMICTEMPLATES_BY_PRACTICE,
    {
      variables: input,
      client: dynamicTemplateClient,
    },
  );
}
