import { FC, useContext } from "react";
import {
  FlexRowCenteredStyle,
  MouseEventPosition,
  PanelContainer,
  VerticalSpacer,
} from "@intouchhealth/mfe-library";
import { FlowNavigatorContext } from "../../flownavigator/FlowNavigator";
import { useFlowPageMenu } from "../../flowmenus/FlowPageMenu";
import { BranchPageHeading } from "../../flowworkspace/BranchPageHeading";
import { FlowContextMenuButton } from "../../flowworkspace/FlowContextMenuButton";
import { PharmacyComplexPageElementStrings } from "./PharmacyComplexPageElementStrings";
import styled from "styled-components";

export const PharmacyComplexPageElement: FC<{ pageId: string }> = ({
  pageId,
}) => {
  const optionMenu = useFlowPageMenu("workspace");
  const context = useContext(FlowNavigatorContext);

  return (
    <>
      <VerticalSpacer />
      <PanelContainer>
        <BranchPageHeading pageId={pageId} />
        <InputElementLayoutContainer
          style={{
            height: "10px",
          }}
        >
          <FlexRowCenteredStyle>
            <TitleContainer>
              {PharmacyComplexPageElementStrings.PharmacyPageTitle}
            </TitleContainer>
            <FlowContextMenuButton
              menuClickHandler={(event) => {
                optionMenu.setMenuConfig({
                  optionMenuHandler: (menuChoice) =>
                    context.pageOptionMenuHandler(pageId, menuChoice),
                  position: MouseEventPosition(event),
                });
                optionMenu.setIsOpen(true);
              }}
            />
          </FlexRowCenteredStyle>
        </InputElementLayoutContainer>
        <VerticalSpacer />
        <p style={{ whiteSpace: "pre-wrap" }}>
          {PharmacyComplexPageElementStrings.PharmacyPageDescription}
        </p>
      </PanelContainer>
      <optionMenu.PopupMenu />
    </>
  );
};

const InputElementLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const TitleContainer = styled.div`
  width: 100%;
  color: #1976d2;
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
