import { mui } from "@intouchhealth/cig-components";
import { observer } from "mobx-react-lite";
import { createContext, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  PanelContainer,
  RequiredInputIndicator,
} from "@intouchhealth/mfe-library";
import { DynamicTemplateType } from "./TemplateFormQueries";
import { TemplateFormStrings } from "./TemplateFormStrings";

const { TextareaAutosize } = mui;

export const FlowSectionContext = createContext({
  isValidated: true,
  updateFields: (_dynamicTemplate: DynamicTemplateType) => {},
});

const IsJsonString = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

const hasValue = (field: string) => {
  return field && field.length > 0 ? true : false;
};

const TemplateFlowInput: React.FC = () => {
  const [jsonCode, setJsonCode] = useState<string>("");
  const [isJsonValid, setIsJsonValid] = useState<boolean>(false);
  // const classes = useStyles();

  const validateJson = (json: string) => hasValue(json) && IsJsonString(json);

  // Connect state to the components context
  const flowContext = useContext(FlowSectionContext);
  flowContext.isValidated = validateJson(jsonCode);

  // Handling for update of service line fields
  flowContext.updateFields = (dynamicTemplate: DynamicTemplateType) => {
    dynamicTemplate.flowJson = jsonCode;
  };

  const handleChange = (event: any) => {
    setJsonCode(event.target.value);
  };

  const handleBlur = (event: any) => {
    if (hasValue(event.target.value) && validateJson(event.target.value)) {
      const jsonObj = JSON.parse(event.target.value);
      setJsonCode(JSON.stringify(jsonObj, undefined, 4));
    }
  };

  useEffect(() => {
    setIsJsonValid(validateJson(jsonCode));
  }, [jsonCode]);

  return (
    <PanelContainer title="Flow">
      <RequiredInputIndicator>
        <TextAreaContainer isValid={isJsonValid}>
          <span>{TemplateFormStrings.FlowJsonCodeLabel}</span>
          <TextareaLayout>
            <TextareaAutosize
              placeholder=""
              minRows={25}
              value={jsonCode}
              onChange={handleChange}
              onBlur={handleBlur}
              spellCheck={false}
            />
          </TextareaLayout>
          {isJsonValid ? (
            <HelpTextLabel>
              {TemplateFormStrings.FlowJsonCodeHelpText}
            </HelpTextLabel>
          ) : (
            <InvalidJsonMessage>
              {TemplateFormStrings.FlowJsonInvalidJsonText}
            </InvalidJsonMessage>
          )}
        </TextAreaContainer>
      </RequiredInputIndicator>
    </PanelContainer>
  );
};

const FlowSection: React.FC = () => {
  return <TemplateFlowInput />;
};

export default observer(FlowSection);

const TextAreaContainer = styled.div`
  width: 100%;
  & span {
    font-size: 12px;
    font-family: inherit;
  }
  & textarea {
    border: ${({ isValid }: { isValid: boolean }) =>
      isValid ? "2px solid #c4c4c4" : "2px solid #CB220E"};
    resize: none;
  }
  textarea:focus {
    outline: 0px;
    border: ${({ isValid }: { isValid: boolean }) =>
      isValid ? "2px solid #6dc0ff" : "2px solid #CB220E"};
  }
`;

const InvalidJsonMessage = styled.span`
  color: #cb220e;
`;

const HelpTextLabel = styled.span`
  color: #ada8a4;
`;

const TextareaLayout = styled.div`
  textarea {
    width: 100%;
    border-radius: 8px;
    border-color: #1976d2;
    padding: 10px;
    font: "12px/normal 'Monaco', 'Menlo', 'Ubuntu Mono', 'Consolas', 'source-code-pro', monospace";
  }
`;
