import { PureInput } from "@intouchhealth/cig-components";
import { useFormDialog } from "@intouchhealth/mfe-library";
import { useContext, useState } from "react";
import {
  getPage,
  IAnswer,
  IPageDesign,
} from "../../app/stores/templateDesignStore";
import { FlowWorkspaceStrings } from "../flowworkspace/FlowWorkspaceStrings";
import { TemplateFormStrings } from "../templatesform/TemplateFormStrings";
import { FlowNavigatorContext } from "./FlowNavigator";

export const useReferenceCategoryHandler = () => {
  const context = useContext(FlowNavigatorContext);
  const [page, setPage] = useState<IPageDesign | undefined>(undefined);
  const [answer, setAnswer] = useState<IAnswer | undefined>(undefined);
  const { ...form } = useFormDialog(
    TemplateFormStrings.ReferenceCategoryDialogTitle,
    TemplateFormStrings.OkButtonLabel,
    TemplateFormStrings.CancelButtonLabel,
  );

  const ReferenceCategoryDialog: React.VFC = () => {
    const [referenceCategory, setReferenceCategory] = useState<string>(
      (answer ?? page)?.referenceCategory ?? "",
    );

    const handleAccepted = () => {
      if (page) {
        (answer ?? page).referenceCategory = referenceCategory;
      }
      form.setIsOpen(false);
    };

    const handleCancelled = () => {
      form.setIsOpen(false);
    };

    context.referenceCategoryHandler = (
      pageId: string | undefined = undefined,
      answer: IAnswer | undefined = undefined,
    ) => {
      let page;

      if (pageId) {
        page = getPage(context.section(), pageId);
      }

      setPage(page);
      setAnswer(answer);

      form.setIsOpen(true);
    };

    return (
      <form.FormDialog
        position="appcontainer"
        top={40}
        width={"75%"}
        onAccepted={handleAccepted}
        onCancelled={handleCancelled}
        acceptDisabled={false}
      >
        <PureInput
          label={FlowWorkspaceStrings.ReferenceCategoryLabel}
          value={referenceCategory}
          onChange={(value) => {
            setReferenceCategory(value);
          }}
          className="sls-input"
          infoMessage={FlowWorkspaceStrings.ReferenceCategoryHelpText}
        />
      </form.FormDialog>
    );
  };

  return {
    ReferenceCategoryDialog,
  };
};
