import {
  MicroFrontendConfig,
  MfeEmbeddedHostContainer,
} from "@intouchhealth/mfe-library";
import { useContext, createContext, useState, useCallback } from "react";
import styled from "styled-components";
import { RootStoreContext } from "../../app/stores/rootStore";
import { DesignerTodoContext } from "../templatesform/DesignTemplateForm";
import { TemplatePreviewStrings } from "./TemplatePreviewStrings";

export interface ITemplatePreviewContainerProps {
  templateFlowJson: Object;
}

export const TemplatePreviewContext = createContext({
  restartPreview: () => {},
});

const getPreviewLastPage = () =>
  JSON.parse(`
  {
    "id": "00000000-0000-0000-0000-000000000000",
    "type": "TEXT",
    "text": "${TemplatePreviewStrings.PreviewLastPageText}",
    "continueText": "${TemplatePreviewStrings.PreviewLastPageContinueButtonText}",
    "title": "${TemplatePreviewStrings.PreviewLastPageTitle}"
  }
`);

const templateJsonWrapper = (sections: string) => `
{
  "templateId": "00000000-0000-0000-0000-000000000001",
  "version": "1",
  "name": "PREVIEW",
  "type": "TESTING",
  "endAction": "UNKNOWN_ACTION",
  "flow":
  {
    "sections": ${sections}
  }
}
`;

export function TemplatePreviewContainer(
  props: ITemplatePreviewContainerProps,
) {
  const rootStore = useContext(RootStoreContext);
  const previewContext = useContext(TemplatePreviewContext);
  const [isHidden, setIsHidden] = useState(false);
  const todoContext = useContext(DesignerTodoContext);

  const flowJson = JSON.parse(JSON.stringify(props.templateFlowJson));
  if (flowJson && flowJson.length > 0 && flowJson[0]?.pages) {
    flowJson[0].pages.push(getPreviewLastPage());
  }

  const onLoadFailed = useCallback(() => {
    //@ts-ignore
    if (!window.renderTemplatePreview) {
      todoContext.setMessageContent(
        TemplatePreviewStrings.PreviewNotAvailableText,
      );
      todoContext.setIsOpen(true);
      setIsHidden(true);
    }
    // eslint-disable-next-line
  }, []);

  const templatePreviewServiceConfig = {
    name: "TemplatePreview",
    host: process.env.REACT_APP_TEMPLATEPREVIEW_PUBLIC_URL,
    renderFunction: "renderTemplatePreview",
    unmountFunction: "unmountTemplatePreview",
    onLoadFailed,
  } as MicroFrontendConfig;

  const renderParameters = {
    browserHistory: rootStore.commonStore.history,
    organizationId: rootStore.commonStore.organizationId,
    practiceId: rootStore.commonStore.practiceId,
    setBreadCrumbs: rootStore.commonStore.setBreadCrumbs,
    setHeader: rootStore.commonStore.setHeader,
    idpToken: rootStore.commonStore.idpToken,
    templateDetails: {
      templateJson: templateJsonWrapper(JSON.stringify(flowJson)),
    },
    host: templatePreviewServiceConfig.host,
  };

  previewContext.restartPreview = () => {
    setIsHidden(true);
    setTimeout(() => setIsHidden(false));
  };

  return (
    <Wrapper>
      {!isHidden && (
        <MfeEmbeddedHostContainer
          hidden={false}
          config={templatePreviewServiceConfig}
          renderParameters={renderParameters}
        />
      )}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: calc(100% + 32px);
  margin-left: -16px;
  margin-bottom: -16px;
  max-height: calc(100vh - 126px);
  overflow-y: auto;
  overflow-x: clip;
`;
