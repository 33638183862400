import { useStorageClient } from "./useStorageClient";
import { useFileUploadClient } from "./useFileUploadClient";

export const useDynamicTemplateClient = () => {
  const client = useStorageClient(
    process.env.REACT_APP_DYNAMICTEMPLATE_API_URL,
  );
  return client;
};

export const useServiceLineClient = () => {
  const client = useStorageClient(process.env.REACT_APP_SERVICELINE_API_URL);
  return client;
};

export const useDynamicTemplateUploadClient = () => {
  const client = useFileUploadClient(
    process.env.REACT_APP_DYNAMICTEMPLATE_API_URL,
  );
  return client;
};
