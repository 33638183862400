import { useEffect, useState } from "react";
import {
  isAsyncMessageTemplateType,
  updateTranslationStatistics,
  ITemplateDesign,
  translationChangedNotification,
  ConvertToTranslationData,
} from "../../app/stores/templateDesignStore";
import { DynamicTemplateType } from "./TemplateFormQueries";
import { Settings as GearIcon } from "@mui/icons-material";
import {
  IUpdatedTemplateDetails,
  useTemplateDetailsDialog,
} from "./TemplateDetailsDialog";
import { useFirstMessageDialog } from "./FirstMessageDialog";
import {
  useTranslationsManager,
  OutlineStyleButton,
  StyledTooltip,
} from "@intouchhealth/mfe-library";
import { TranslationOptions } from "../../app/stores/translationOptionsStore";
import { FlowNavigatorStrings } from "../../features/flownavigator/FlowNavigatorStrings";
import { FlowWorkspaceStrings } from "../../features/flowworkspace/FlowWorkspaceStrings";
import { AppIcons } from "../../app/icons/AppIcons";
import styled from "styled-components";

const translationEnabled =
  process.env.REACT_APP_FEATURE_TOGGLES?.includes("GAV3");

export const DesignerHeadingBar: React.FC<{
  formInitialized: boolean;
  isCreate: boolean;
  template: ITemplateDesign | undefined;
  dynamicTemplate: DynamicTemplateType;
  onDetailsUpdated: (details: IUpdatedTemplateDetails) => void;
  onFirstMessageSet: (id: string | undefined) => void;
  openFirstMessage: boolean;
  setOpenFirstMessage: (value: boolean) => void;
  children: any;
}> = ({
  formInitialized,
  isCreate,
  template,
  dynamicTemplate,
  onDetailsUpdated,
  onFirstMessageSet,
  openFirstMessage,
  setOpenFirstMessage,
  children,
}) => {
  const [templateType, setTemplateType] = useState(dynamicTemplate.type);

  const handleDetailsUpdated = (details: IUpdatedTemplateDetails) => {
    setTemplateType(details.type);
    onDetailsUpdated(details);
  };

  const details = useTemplateDetailsDialog(
    dynamicTemplate,
    isCreate,
    handleDetailsUpdated,
  );

  const firstMessage = useFirstMessageDialog(onFirstMessageSet);

  const { TranslationManagerControl, activeLanguage, refreshDisplay } =
    useTranslationsManager(
      TranslationOptions,
      dynamicTemplate.defaultLanguageCode,
    );

  const updateStatistics = () => {
    if (translationEnabled) {
      updateTranslationStatistics(template, TranslationOptions);
      if (template) {
        template.translations = ConvertToTranslationData(TranslationOptions);
      }
      refreshDisplay();
    }
  };
  translationChangedNotification.subscribe(
    "DesignerHeadingBar",
    updateStatistics,
  );

  const settingsClickHandler = () => {
    details.setIsOpen(true);
  };

  const firstMessageClickHandler = () => {
    firstMessage.openDialog();
  };

  useEffect(() => {
    if (formInitialized && isCreate) {
      details.setIsOpen(true);
    }
    // eslint-disable-next-line
  }, [formInitialized, isCreate]);

  useEffect(() => {
    if (openFirstMessage) {
      firstMessage.openDialog(true);
      setOpenFirstMessage(false);
    }
    // eslint-disable-next-line
  }, [openFirstMessage]);

  useEffect(() => {
    updateStatistics();
    // eslint-disable-next-line
  }, [activeLanguage]);

  useEffect(() => {
    if (template && translationEnabled) {
      TranslationOptions.forEach((o) => {
        var activeTranslation = template.translations?.find(
          (t) => t.languageCode === o.languageCode,
        );
        if (activeTranslation) {
          o.status = "ACTIVE";
          o.percentComplete = Math.floor(
            activeTranslation.translationPercentage,
          );
        } else if (template.defaultLanguageCode === o.languageCode) {
          o.status = "DEFAULT";
          o.percentComplete = 0;
        } else {
          o.status = "INACTIVE";
          o.percentComplete = 0;
        }
      });
    }
    // eslint-disable-next-line
  }, [template]);

  return (
    <>
      <PageBar>
        <ButtonAreaContainer>
          <StyledTooltip title={FlowNavigatorStrings.TemplateSettingsLabel}>
            <ToolbarButtonStyle>
              <OutlineStyleButton
                icon={<GearIcon />}
                size="xs"
                onClick={settingsClickHandler}
              />
            </ToolbarButtonStyle>
          </StyledTooltip>
          {isAsyncMessageTemplateType(templateType) ? (
            <>
              <StyledTooltip title={FlowWorkspaceStrings.FirstMessageLabel}>
                <ToolbarButtonStyle>
                  <OutlineStyleButton
                    icon={
                      <ChatIconStyle>
                        <AppIcons.Chat />
                      </ChatIconStyle>
                    }
                    size="xs"
                    onClick={firstMessageClickHandler}
                  />
                </ToolbarButtonStyle>
              </StyledTooltip>
            </>
          ) : (
            <></>
          )}
          {translationEnabled ? (
            <TranslationManagerStyle>
              <TranslationManagerControl />
            </TranslationManagerStyle>
          ) : (
            <></>
          )}
        </ButtonAreaContainer>
        {children}
      </PageBar>
      <details.TemplateDetailsDialog />
      <firstMessage.ModalDialog />
    </>
  );
};

const PageBar = styled.div`
  width: calc(100% + 30px);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap
  align-items: center;
  height: 42px;
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  background: white;
  border-bottom: solid 2px #f5f5f5;
`;

const ButtonAreaContainer = styled.div`
  width: 100%;
  min-width: 400px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
`;

const ToolbarButtonStyle = styled.div`
  margin-right: 8px;
  button {
    border: 2px solid lightgrey;
  }
  button:active,
  button:focus,
  button:hover {
    border: 2px solid #1976d2;
  }
  svg {
    fill: #717171;
  }
`;

const ChatIconStyle = styled.div`
  svg {
    height: 20px;
    width: 20px;
    margin-left: -7px;
    margin-top: 2px;
  }
`;

const TranslationManagerStyle = styled.div`
  padding-right: 24px;
`;
