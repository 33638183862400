import { FC, useContext, useEffect, useState } from "react";
import { AddNewIcon, Button } from "@intouchhealth/cig-components";
import { SubdirectoryArrowRight } from "@mui/icons-material";
import {
  FlexRowCenteredStyle,
  LinkStyleButton,
  MouseEventPosition,
  uniqueName,
  useConfirmDialog,
  useMessageDialog,
  useTranslatableTextInput,
  TranslationManagerContext,
} from "@intouchhealth/mfe-library";
import {
  IAnswer,
  getDefaultAnswerText,
  setDefaultAnswerText,
  newTemplateAnswer,
  IPageDesign,
  newTemplatePage,
  getAttribute,
  getComplexPageTitle,
  getTranslatedAnswerText,
  setTranslatedAnswerText,
  translationChangedNotification,
} from "../../../app/stores/templateDesignStore";
import { AnswerComponentStrings } from "./AnswersComponentStrings";
import styled from "styled-components";
import { FlowContextMenuButton } from "../../flowworkspace/FlowContextMenuButton";
import { useAnswerComponentMenu } from "./AnswersComponentMenu";
import { FlowMenuControlChoices } from "../../flowmenus/FlowMenuControl";
import { FlowNavigatorContext } from "../../flownavigator/FlowNavigator";

export const useAnswersComponent = (
  answers: IAnswer[] | undefined,
  numberAnswersRequird: number,
  allowBranching: boolean = false,
) => {
  const context = useContext(FlowNavigatorContext);
  const { languageChangeNotification, defaultLanguage } = useContext(
    TranslationManagerContext,
  );
  const [selectedAnswerId, setSelectedAnswerId] = useState<string>();
  const [deleteAnswerIndex, setDeleteAnswerIndex] = useState<
    number | undefined
  >(undefined);
  const [removeBranchIndex, setRemoveBranchIndex] = useState<
    number | undefined
  >(undefined);
  const { ...optionMenu } = useAnswerComponentMenu(allowBranching);
  const { ...moveNotAllowed } = useMessageDialog();
  const { ...confirmation } = useConfirmDialog();

  const hasMinimumNumberAnswers = () => {
    return (answers?.length ?? 0) >= numberAnswersRequird;
  };

  const hasValidAnswers = () => {
    return (
      answers !== undefined &&
      answers.findIndex((a) => (getDefaultAnswerText(a)?.length ?? 0) === 0) ===
        -1
    );
  };

  const answersValidated = () => {
    return (
      answers !== undefined && hasMinimumNumberAnswers() && hasValidAnswers()
    );
  };

  const addAnswer = () => {
    if (answers) {
      let newAnswer = newTemplateAnswer();
      answers.push(newAnswer);
      setSelectedAnswerId(newAnswer.id);
    }
  };

  const deleteAnswer = (index: number) => {
    if (answers) {
      if (index === 0) {
        addAnswer();
      }
      answers = answers.splice(index, 1);
      context.refreshNavElements();
    }
  };

  const removeBranch = (index: number) => {
    if (
      answers &&
      confirmation.userConfirmed &&
      answers[index].pages &&
      answers[index].pages.length > 0
    ) {
      answers[index].pages.splice(0, answers[index].pages.length);
      context.refreshNavElements();
    }
  };

  useEffect(() => {
    translationChangedNotification.publish(null);
  }, []);

  useEffect(() => {
    if (confirmation.userConfirmed) {
      if (removeBranchIndex !== undefined) {
        removeBranch(removeBranchIndex);
        setRemoveBranchIndex(undefined);
      } else if (deleteAnswerIndex !== undefined) {
        deleteAnswer(deleteAnswerIndex);
        setDeleteAnswerIndex(undefined);
      }
    }
    // eslint-disable-next-line
  }, [confirmation.userConfirmed]);

  const handleMenuChoice = (
    answerId: string,
    menuChoice: FlowMenuControlChoices,
  ) => {
    if (answers) {
      let curAnswerIndex = answers.findIndex((a) => a.id === answerId);
      if (curAnswerIndex >= 0) {
        switch (menuChoice) {
          case "refCategory":
            context.referenceCategoryHandler(
              context.selectedPageId,
              answers[curAnswerIndex],
            );
            break;
          case "metaData":
            context.metaDataHandler(answers[curAnswerIndex]);
            break;
          case "addBranch":
            if (
              !answers[curAnswerIndex].pages ||
              answers[curAnswerIndex].pages.length === 0
            ) {
              var newBranchPage = newTemplatePage();
              answers[curAnswerIndex].pages = [newBranchPage] as IPageDesign[];
              context.selectPageHandler(newBranchPage.id);
            }
            break;

          case "removeBranch":
            setRemoveBranchIndex(curAnswerIndex);
            confirmation.setIsOpen(true);
            break;

          case "deleteItem":
            if (allowBranching && answers[curAnswerIndex].pages?.length > 0) {
              setDeleteAnswerIndex(curAnswerIndex);
              confirmation.setIsOpen(true);
            } else {
              deleteAnswer(curAnswerIndex);
            }
            break;

          case "moveItemUp":
          case "moveItemDown":
            let newAnswerIndex =
              menuChoice === "moveItemUp"
                ? curAnswerIndex - 1
                : curAnswerIndex + 1;
            if (newAnswerIndex >= 0 && newAnswerIndex < answers.length) {
              let swapAnswer = answers[newAnswerIndex];
              answers[newAnswerIndex] = answers[curAnswerIndex];
              answers[curAnswerIndex] = swapAnswer;
            } else {
              moveNotAllowed.setIsOpen(true);
            }
            break;
        }
      }
    }
  };

  const BranchLink: FC<{ page: IPageDesign; isFirstPage: boolean }> = ({
    page,
    isFirstPage,
  }) => {
    var pageTitle =
      getComplexPageTitle(page) ||
      getAttribute(page, "title", undefined, "").defaultText();

    return (
      <FlexRowCenteredStyle>
        {isFirstPage ? (
          <SubdirectoryArrowRight
            style={{ height: "25px", width: "20px", paddingBottom: "5px" }}
          />
        ) : (
          <span
            style={{
              height: "25px",
              marginRight: "20px",
              paddingBottom: "5px",
            }}
          />
        )}
        <BranchLinkButtonStyle>
          <LinkStyleButton
            label={pageTitle}
            onButtonClick={() => {
              context.selectPageHandler(page.id);
            }}
          />
        </BranchLinkButtonStyle>
      </FlexRowCenteredStyle>
    );
  };

  const BranchLinks: FC<{ answer: IAnswer }> = ({ answer }) => {
    return (
      <>
        {allowBranching && answer.pages?.length > 0 ? (
          <div
            style={{
              marginTop: "-5px",
              marginLeft: "10px",
              marginBottom: "10px",
            }}
          >
            {answer.pages.map((page, index) => (
              <BranchLink
                key={uniqueName("brlink")}
                page={page}
                isFirstPage={index === 0}
              />
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const MultiSelectAnswer: FC<{
    answer: IAnswer;
    focusId: string | undefined;
  }> = ({ answer, focusId }) => {
    const { TranslatableTextInput, defaultValueRef } = useTranslatableTextInput(
      answer.id,
    );

    const isAnswerTextValid = () => {
      return (getDefaultAnswerText(answer)?.length ?? 0) > 0;
    };

    useEffect(() => {
      if (focusId === answer.id) {
        defaultValueRef.current?.focus();
      }
      // eslint-disable-next-line
    }, [answer, focusId]);

    return (
      <>
        <FlexRowCenteredStyle>
          <TranslatableTextInput
            isTranslatedRequired={() => false}
            helpText={""}
            requiredHelpText={AnswerComponentStrings.AnswerRequiredText}
            initializeDefaultValue={() => {
              return getDefaultAnswerText(answer);
            }}
            onDefaultChanged={(value) => {
              setDefaultAnswerText(answer, value);
            }}
            initializeTranslatedValue={(languageCode) => {
              return getTranslatedAnswerText(answer, languageCode);
            }}
            onTranslatedChanged={(value, languageCode) => {
              setTranslatedAnswerText(answer, languageCode, value);
            }}
            isDefaultRequired={() => false}
            isTranslatedValid={() => true}
            isDefaultValid={isAnswerTextValid}
          >
            <span style={{ marginTop: "5px" }}>
              <FlowContextMenuButton
                menuClickHandler={(event) => {
                  optionMenu.setHasBranch(answer.pages?.length > 0);
                  optionMenu.setMenuConfig({
                    optionMenuHandler: (menuChoice) =>
                      handleMenuChoice(answer.id, menuChoice),
                    position: MouseEventPosition(event),
                  });
                  optionMenu.setIsOpen(true);
                }}
              />
            </span>
          </TranslatableTextInput>
        </FlexRowCenteredStyle>
        <BranchLinks answer={answer} />
        <optionMenu.PopupMenu />
      </>
    );
  };

  const MultiSelectAnswers: FC<{ elementId: string }> = ({ elementId }) => {
    const context = useContext(TranslationManagerContext);
    const [defaultTitle, setDefaultTitle] = useState(
      AnswerComponentStrings.AnswersListTitle,
    );
    const [translatedTitle, setTranslatedTitle] = useState("");
    const [activeLanguage, setActiveLanguage] = useState<string>(
      context.activeLanguage,
    );
    const handleLanguageChange = (activeLanguage: string) => {
      if (activeLanguage.length > 0) {
        setDefaultTitle(
          AnswerComponentStrings.AnswersListTitle +
            " (" +
            defaultLanguage +
            ")",
        );
        setTranslatedTitle(
          AnswerComponentStrings.AnswersListTitle + " (" + activeLanguage + ")",
        );
      } else {
        setDefaultTitle(AnswerComponentStrings.AnswersListTitle);
        setTranslatedTitle("");
      }
    };
    languageChangeNotification.subscribe(
      "answerNotify-" + elementId,
      setActiveLanguage,
    );

    useEffect(() => {
      handleLanguageChange(activeLanguage);
    }, [activeLanguage]);

    return (
      <>
        <AnswersTitleContainer>
          <AnswersTitleTextContainer
            width={translatedTitle ? "33%" : "100%"}
            marginRight={translatedTitle ? "20px" : "0px"}
          >
            {defaultTitle}
            <AnswerRequiredStyle>*</AnswerRequiredStyle>
          </AnswersTitleTextContainer>
          {translatedTitle ? (
            <AnswerTranslatedTitleStyle>
              {translatedTitle}
            </AnswerTranslatedTitleStyle>
          ) : (
            <></>
          )}
        </AnswersTitleContainer>
        {answers?.map((answer, index) => {
          return (
            <MultiSelectAnswer
              key={`${uniqueName("answer")}-${index}`}
              answer={answer}
              focusId={selectedAnswerId}
            ></MultiSelectAnswer>
          );
        })}
        <AddAnswerButtonStyle>
          <Button
            size="xs"
            textAlign="left"
            iconPosition="right"
            icon={<AddNewIcon />}
            appearance="subtle"
            themeName="whitelabel"
            onClick={addAnswer}
          >
            {AnswerComponentStrings.AddAnswerButton}
          </Button>
        </AddAnswerButtonStyle>
        <AnswersCountErrorTextStyle hidden={hasMinimumNumberAnswers()}>
          {AnswerComponentStrings.MinimumAnswersCountValidationErrorMessage(
            numberAnswersRequird,
          )}
        </AnswersCountErrorTextStyle>
        <moveNotAllowed.ModalDialog
          type="warning"
          position="appcontainer"
          top={"25%"}
          title={AnswerComponentStrings.MoveAnswerNotAllowedDialogTile}
          closeButtonText={
            AnswerComponentStrings.MoveAnswerNotAllowedCloseButton
          }
        >
          {AnswerComponentStrings.MoveAnswerNotAllowedDialogMessage}
        </moveNotAllowed.ModalDialog>
        <confirmation.ModalDialog
          type="warning"
          isDelete={true}
          position="appcontainer"
          top={"25%"}
          title={
            deleteAnswerIndex !== undefined
              ? AnswerComponentStrings.DeleteAnswerConfirmDialogTile
              : AnswerComponentStrings.RemoveBranchConfirmDialogTile
          }
          confirmButtonText={
            deleteAnswerIndex !== undefined
              ? AnswerComponentStrings.DeleteAnswerConfirmationButton
              : AnswerComponentStrings.RemoveBranchConfirmationButton
          }
          cancelButtonText={
            deleteAnswerIndex !== undefined
              ? AnswerComponentStrings.DeleteAnswerCancelConfirmationButton
              : AnswerComponentStrings.RemoveBranchCancelConfirmationButton
          }
        >
          <div>
            {deleteAnswerIndex !== undefined
              ? AnswerComponentStrings.DeleteAnswerConfirmDialogDeleteMessage
              : AnswerComponentStrings.RemoveBranchConfirmDialogDeleteMessage}
          </div>
        </confirmation.ModalDialog>
      </>
    );
  };

  return {
    MultiSelectAnswers,
    hasMinimumNumberAnswers,
    hasValidAnswers,
    answersValidated,
  };
};

const AnswersTitleContainer = styled.div`
  margin-top: 10px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const AnswersTitleTextContainer = styled.div<{
  width: string;
  marginRight: string;
}>`
  width: ${({ width }: { width: string }) => width};
  margin-right: ${({ marginRight }: { marginRight: string }) => marginRight};
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
`;

const AnswerTranslatedTitleStyle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;
const AnswerRequiredStyle = styled.div`
  color: #cb220e;
  font-size: 30px;
  margin-bottom: -20px;
  margin-right: 6px;
`;

const AnswersCountErrorTextStyle = styled.div`
  margin-top: -3px;
  color: #cb220e;
`;

const AddAnswerButtonStyle = styled.div`
  margin-top: 0px;
  margin-left: -5px;
  margin-bottom: 0px;
  & span {
    margin-left: -6px;
    margin-right: 3px !important;
  }
  & button {
    white-space: nowrap;
    padding-right: 0px;
    margin-right: -18px !important;
    border: solid 1px transparent;
    :hover {
      color: #1986e2;
      border: dotted 1px gray;
    }
  }
`;

const BranchLinkButtonStyle = styled.div`
  color: fieldtext;
`;
