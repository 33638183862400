import { FC, useContext, useEffect, useRef, useState } from "react";
import { FlowWorkspaceStrings } from "./FlowWorkspaceStrings";
import { Checkbox, mui } from "@intouchhealth/cig-components";
import {
  PanelContainer,
  MouseEventPosition,
  VerticalSpacer,
} from "@intouchhealth/mfe-library";
import { getTemplateElementTypeInfo } from "../../app/stores/elementTypesStore";
import { TemplateItemTypes } from "../../app/stores/TemplateItemTypes";
import {
  elementHasAsyncFirstMessage,
  getAttribute,
  IPageDesign,
} from "../../app/stores/templateDesignStore";
import { useFlowElementMenu } from "../flowmenus/FlowElementMenu";
import { FlowNavigatorContext } from "../flownavigator/FlowNavigator";
import { FlowContextMenuButton } from "./FlowContextMenuButton";
import { AppIcons } from "../../app/icons/AppIcons";
import styled from "styled-components";
import { FlowWorkspaceContext } from "./FlowWorkspace";

export const FlowElementPanel: FC<{
  page: IPageDesign;
  elementType: TemplateItemTypes | undefined;
  setElementType: (type: TemplateItemTypes | undefined) => void;
  elementId: string;
  hideRequired?: boolean;
  requiredDefault?: boolean;
  children?: React.ReactNode;
}> = ({
  page,
  elementType,
  elementId,
  hideRequired,
  requiredDefault = false,
  children,
}) => {
  const { Tooltip } = mui;
  const context = useContext(FlowNavigatorContext);
  const workspace = useContext(FlowWorkspaceContext);
  const elementSelection = getTemplateElementTypeInfo(elementType);
  const pageElement = page.subpages?.find((e) => e.id === elementId);
  const elementRef = useRef<HTMLDivElement>(null);

  const defaultAllowSkipValue = requiredDefault ? false : true;
  const allowSkipAttribute = hideRequired
    ? undefined
    : getAttribute(pageElement, "allowSkip", defaultAllowSkipValue.toString());
  const [isRequired, setIsRequired] = useState<boolean>(
    allowSkipAttribute?.value === "false" ?? defaultAllowSkipValue,
  );

  const isAsyncFirstMessage = () => {
    return elementHasAsyncFirstMessage(pageElement);
  };

  const optionMenu = useFlowElementMenu(elementType);

  useEffect(() => {
    const domElement = elementRef.current;

    if (
      pageElement &&
      domElement &&
      workspace.focusElementId === pageElement.id
    ) {
      // Focus the first textbox on the element.
      const textboxDomElement = domElement?.querySelector(
        "input[type=text],.public-DraftEditor-content",
      ) as HTMLElement;
      if (textboxDomElement) {
        textboxDomElement.focus({ preventScroll: true });
      }
    }
  });

  useEffect(() => {
    const domElement = elementRef.current;

    if (pageElement && domElement) {
      if (workspace.scrollElementId === pageElement.id) {
        const containerRef = workspace.getWorkspaceScrollRef();

        if (domElement && containerRef) {
          const offset = domElement.offsetTop - containerRef?.offsetTop;
          const scrollPos =
            offset -
            containerRef.offsetHeight / 2 +
            domElement.offsetHeight / 2;

          workspace.setWorkspaceScrollY(scrollPos);
        }
      }
    }
    // eslint-disable-next-line
  }, [elementRef.current, workspace.scrollElementId]);

  return (
    <div ref={elementRef}>
      <VerticalSpacer />
      <PanelContainer>
        <InputElementLayoutContainer
          style={{
            height: "10px",
          }}
        >
          <TitleGroupCountainer>
            <TitleContainer>{elementSelection?.name}</TitleContainer>
            {isAsyncFirstMessage() ? (
              <Tooltip
                title={FlowWorkspaceStrings.FirstMessageHelpText}
                enterDelay={750}
                placement={"right"}
              >
                <FirstMessageBadgeContainer>
                  <AppIcons.Chat />
                  <div style={{ marginLeft: "8px" }}>
                    {FlowWorkspaceStrings.FirstMessageLabel}
                  </div>
                </FirstMessageBadgeContainer>
              </Tooltip>
            ) : (
              <></>
            )}
          </TitleGroupCountainer>
          <RightSideControls>
            <div
              hidden={hideRequired ?? false}
              style={{
                marginLeft: "20px",
                marginRight: "5px",
                whiteSpace: "nowrap",
              }}
            >
              <Checkbox
                label={FlowWorkspaceStrings.RequiredElementCheckboxLabel}
                checked={isRequired || isAsyncFirstMessage()}
                onCheck={() => {
                  var checked = !isRequired;
                  if (allowSkipAttribute) {
                    allowSkipAttribute.value =
                      checked === true ? "false" : "true";
                  }
                  setIsRequired(checked);
                  if (elementType === "CLOSE-ENDED:BUTTON") {
                    context.refreshNavElements();
                  }
                }}
                disabled={isAsyncFirstMessage()}
              />
            </div>
            <FlowContextMenuButton
              menuClickHandler={(event) => {
                optionMenu.setMenuConfig({
                  optionMenuHandler: (menuChoice) =>
                    context.elementOptionMenuHandler(
                      page.id,
                      elementId,
                      menuChoice,
                    ),
                  position: MouseEventPosition(event),
                });
                optionMenu.setIsOpen(true);
              }}
            />
          </RightSideControls>
        </InputElementLayoutContainer>
        <VerticalSpacer />
        {children}
      </PanelContainer>
      <optionMenu.PopupMenu />
    </div>
  );
};

const InputElementLayoutContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
`;

const RightSideControls = styled.div`
  width: 35%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
`;

const TitleContainer = styled.div`
  margin-right: 8px;
  color: #1976d2;
  font-size: medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TitleGroupCountainer = styled.div`
  width: 65%;
  display: flex;
  flex-direction: row;
`;

const FirstMessageBadgeContainer = styled.div`
  color: #ffffff;
  background: #005c95;
  padding-left: 4px;
  padding-right: 8px;
  padding-bottom: 2px;
  border-radius: 8px;
  margin-top: -5px;
  height: 27px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  svg {
    height: 20px;
    margin-top: 3px;
  }
`;
