import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import { cloneDeep } from "@apollo/client/utilities";
import { DetailsSectionContext } from "./DetailsSection";
import { ConvertToDynamicTemplateType } from "./TemplateFormQueries";
import {
  ConvertToAdminTemplateType,
  useUpdateDynamicTemplateMutation,
} from "./TemplateFormMutations";
import TemplateFormError, { useTemplateError } from "./TemplateFormError";
import {
  ITemplateDesign,
  prepareOutboundTemplate,
} from "../../app/stores/templateDesignStore";

interface UpdateTemplateProviderProps {
  dynamicTemplate: ITemplateDesign | undefined;
  updated: boolean;
  setUpdated: Dispatch<SetStateAction<boolean>>;
  onUpdated: () => void;
}

export const UpdateTemplateProvider: React.FC<UpdateTemplateProviderProps> = ({
  dynamicTemplate,
  updated,
  setUpdated,
  onUpdated,
}) => {
  const [
    updateDynamicTemplateDetailsMutation,
    { data, loading, error, called, reset },
  ] = useUpdateDynamicTemplateMutation();
  const { ...formError } = useTemplateError();
  const detailsContext = useContext(DetailsSectionContext);

  useEffect(() => {
    if (updated && !called) {
      // Make sure all cient side validations passed before proceeding with save otherwise open the alert and return.
      if (detailsContext.initialized && !detailsContext.isValidated) {
        setUpdated(false);
        return;
      }

      //Build the updated dynamic template and populate with changes from form sections
      if (!dynamicTemplate) {
        return;
      }
      var templateConfig = ConvertToDynamicTemplateType(dynamicTemplate);
      if (detailsContext.initialized) {
        detailsContext.updateFields(templateConfig);
      }

      var outboundTemplate = cloneDeep(dynamicTemplate);
      prepareOutboundTemplate(outboundTemplate);

      // Make call to update the dynamic template
      updateDynamicTemplateDetailsMutation({
        variables: {
          input: ConvertToAdminTemplateType(
            templateConfig,
            outboundTemplate.flow,
          ),
        },
      });

      setUpdated(false);
    }
    // eslint-disable-next-line
  }, [updated, called]);

  useEffect(() => {
    if (called && (!loading || error)) {
      if (formError.hasErrors(error, data?.updateDynamicTemplate?.errors)) {
        reset();
      } else {
        reset();
        onUpdated();
      }
    }
    // eslint-disable-next-line
  }, [data, loading, error, called, reset]);

  return (
    <TemplateFormError
      isErrorAlertOpen={formError.isErrorAlertOpen}
      setIsErrorAlertOpen={formError.setIsErrorAlertOpen}
      alertMessages={formError.alertMessages}
    ></TemplateFormError>
  );
};
