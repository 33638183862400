import { gql, useLazyQuery, useQuery } from "@apollo/client";
import {
  useDynamicTemplateClient,
  useServiceLineClient,
} from "../../app/stores/dynamicTemplateStore";
import {
  ITag,
  ITemplateDesign,
  ITranslationData,
} from "../../app/stores/templateDesignStore";

export interface TemplateType {
  name: string;
  type: string;
}

export interface EndActionType {
  name: string;
  endAction: string;
}

export interface DynamicTemplateByTag {
  tag: ITag | null;
  templateId: string;
}

export interface IUploadedImage {
  key: string;
  url: string;
  isUsedInTemplate: boolean;
}

export interface ServiceLineForDynamicTemplate {
  serviceLineId: string;
  displayName: string;
}

const browserCachePolicy = {
  returnPartialData: true,
  fetchPolicy: "cache-and-network",
  nextFetchPolicy: "cache-and-network",
  initialFetchPolicy: "cache-and-network",
  refetchWritePolicy: "overwrite",
} as Object;

export function useGetTemplateTypesQuery() {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const GET_TEMPLATE_TYPES = gql`
    query dynamicTemplateTypes {
      dynamicTemplateTypes {
        name
        type
      }
    }
  `;

  return useQuery<{ dynamicTemplateTypes: TemplateType[] }>(
    GET_TEMPLATE_TYPES,
    {
      client: dynamicTemplateClient,
    },
  );
}

export function useGetEndActionsQuery(templateType: string) {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const GET_ENDACTIONS_BY_TYPE = gql`
    query dynamicTemplateEndActionsByTemplateType($templateType: String!) {
      dynamicTemplateEndActionsByTemplateType(templateType: $templateType) {
        name
        endAction
      }
    }
  `;

  return useQuery<{ dynamicTemplateEndActionsByTemplateType: EndActionType[] }>(
    GET_ENDACTIONS_BY_TYPE,
    {
      variables: { templateType },
      client: dynamicTemplateClient,
    },
  );
}

export function useGetTemplateByTagQuery(templateTag: string) {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const GET_TEMPLATE_BY_TAG_QUERY = gql`
    query dynamicTemplateByTag($templateTag: String!) {
      dynamicTemplateByTag(templateTag: $templateTag) {
        templateId
        tag
      }
    }
  `;

  return useLazyQuery<{ dynamicTemplateByTag: DynamicTemplateByTag }>(
    GET_TEMPLATE_BY_TAG_QUERY,
    {
      variables: { templateTag },
      client: dynamicTemplateClient,
      ...browserCachePolicy,
    },
  );
}

export function useGetServiceLinesQuery(input: {
  dynamicTemplateIds: string[];
}) {
  const serviceLineClient = useServiceLineClient();
  const GET_SERVICELINES_FOR_DYNAMIC_TEMPLATE = gql`
    query ServiceLinesByDynamicTemplateIds(
      $input: ServiceLinesByDynamicTemplateIdsInput!
    ) {
      serviceLinesByDynamicTemplateIds(input: $input) {
        serviceLineId
        displayName
      }
    }
  `;

  return useLazyQuery<{
    servicesLinesForDynamicTemplate: ServiceLineForDynamicTemplate[];
  }>(GET_SERVICELINES_FOR_DYNAMIC_TEMPLATE, {
    variables: { input },
    client: serviceLineClient,
    ...browserCachePolicy,
  });
}

export function useGetUploadedImagesQuery(practiceId: string) {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const GET_UPLOADED_IMAGES = gql`
    query dynamicTemplateImageFiles($practiceId: String!) {
      dynamicTemplateImageFiles(practiceId: $practiceId) {
        key
        url
        isUsedInTemplate
      }
    }
  `;

  return useQuery<{ dynamicTemplateImageFiles: IUploadedImage[] }>(
    GET_UPLOADED_IMAGES,
    {
      variables: { practiceId },
      client: dynamicTemplateClient,
    },
  );
}

export function useGetGeneratedFlowQuery(
  flow: Object | undefined,
  languageCode: string,
) {
  let input;
  if (!flow) {
    input = {
      languageCode: "default",
      template: {
        organizationId: "00000000-0000-0000-0000-000000000000",
        practiceId: "00000000-0000-0000-0000-000000000000",
        name: "basic",
        type: "SHARED_SURVEY_POSTEXAM",
        endAction: "DASHBOARD",
        defaultLanguageCode: "en",
        translations: [],
        flow: {
          sections: [
            {
              name: "",
              pages: [],
            },
          ],
        },
      },
    };
  } else {
    input = {
      languageCode,
      template: flow,
    };
  }

  const dynamicTemplateClient = useDynamicTemplateClient();
  const GET_GENERATED_FLOW = gql`
    query GeneratedFlow($input: GenerateFlowInput!) {
      generateFlow(input: $input) {
        flow
      }
    }
  `;

  return useQuery<{ generateFlow: any }>(GET_GENERATED_FLOW, {
    variables: { input },
    client: dynamicTemplateClient,
  });
}

export interface DynamicTemplateType {
  templateId: string;
  organizationId: string;
  practiceId: string;
  name: string;
  tag: ITag | null;
  type: string;
  endAction: string;
  defaultLanguageCode: string;
  translations: ITranslationData[];
  flowJson: string | undefined;
  flow: any | undefined;
}

export const ConvertToDynamicTemplateType = (
  templateDesign: ITemplateDesign,
) => {
  return {
    templateId: templateDesign.templateId,
    organizationId: templateDesign.organizationId,
    practiceId: templateDesign.practiceId,
    name: templateDesign.name,
    tag: templateDesign.tag,
    type: templateDesign.type,
    endAction: templateDesign.endAction,
    defaultLanguageCode: templateDesign.defaultLanguageCode,
    translations: templateDesign.translations,
  } as DynamicTemplateType;
};

export function useGetTemplateForAdminByIdQuery(templateId: string) {
  const dynamicTemplateClient = useDynamicTemplateClient();
  const input = {
    input: {
      templateId: templateId,
    },
  };
  const GET_TEMPLATE_BY_ID = gql`
    query dynamicTemplateForAdminById(
      $input: DynamicTemplateForAdminByIdInput!
    ) {
      dynamicTemplateForAdminById(input: $input) {
        templateId
        organizationId
        practiceId
        name
        type
        endAction
        defaultLanguageCode
        translations
        flow
        defaultLanguageCode
        tag
        isActive
      }
    }
  `;

  return useQuery(GET_TEMPLATE_BY_ID, {
    variables: input,
    client: dynamicTemplateClient,
  });
}
