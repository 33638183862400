import { FC, useEffect } from "react";
import { useTranslatableTextInput } from "@intouchhealth/mfe-library";
import {
  IElementDesign,
  IPageDesign,
  getAttribute,
  translationChangedNotification,
} from "../../../app/stores/templateDesignStore";
import { PromptInputComponentStrings } from "./PromptInputComponentStrings";

export const usePromptInputComponent = (
  page: IPageDesign,
  element: IElementDesign,
  allowOptional: boolean = false, // if true then prompt is only required if no other elements on page
) => {
  const promptAttribute = getAttribute(element, "prompt", undefined, "");

  const isPromptRequired = () => {
    return allowOptional !== true || page?.subpages?.length < 2;
  };

  const isPromptValid = () => {
    return (
      (allowOptional === true && page?.subpages?.length < 2) ||
      promptAttribute.defaultText().length > 0
    );
  };

  const PromptInput: FC<{ children?: any }> = ({ children }) => {
    const { TranslatableTextInput } = useTranslatableTextInput(
      "Prompt-" + element.id,
    );

    useEffect(() => {
      translationChangedNotification.publish(null);
    }, []);

    return (
      <>
        <TranslatableTextInput
          label={PromptInputComponentStrings.PropmtLabel}
          initializeDefaultValue={() => {
            return promptAttribute.defaultText();
          }}
          onDefaultChanged={(value) => {
            promptAttribute.setDefaultText(value);
          }}
          initializeTranslatedValue={(languageCode) => {
            return promptAttribute.getTranslationText(languageCode);
          }}
          onTranslatedChanged={(value, languageCode) => {
            promptAttribute.setTranslationText(languageCode, value);
          }}
          isDefaultValid={() => isPromptValid()}
          isDefaultRequired={() => isPromptRequired()}
          isTranslatedValid={() => true}
          isTranslatedRequired={() => false}
          helpText={PromptInputComponentStrings.PromptHelpText}
          requiredHelpText={PromptInputComponentStrings.PromptRequiredText}
        >
          {children}
        </TranslatableTextInput>
      </>
    );
  };

  return {
    PromptInput,
    isPromptRequired,
    isPromptValid,
  };
};
